import { useRef, useEffect, useState, useCallback, } from 'react';

import { useGameContext } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { drawPlainMarkerStroke } from './brushes/plain';
import { drawDashedStroke } from './brushes/dashed';
import { drawSprayStroke } from './brushes/spray';
import { drawFeatherStroke } from './brushes/feather';
import { filterClosePoints } from './brushes/helpers/points';

export const useSoftDraw = ({

  wipeTempCanvas,

  saveHistory,
  getEventPos,
  redrawCanvas,

  touchZoomStart,
  touchZoomMove,
  
}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    activeUserIdRef,
    gameInfoRef,

    isAppleDevise,
    userSetsRef,
  } = gameContext;

  const {
    tempContextRef,
    contextRef,

    zoomFactorRef,
    isDrawingRef,

    isRenderingStrokesRef,

  } = canvasContext;

  const {
    colorRef,
    visibleColorFun,
    actualSoftnessFun,
    brushSetsFun,
    brushLinkFun,

    lineWidthRef,
    showPalette, setShowPalette,

    softnessRef,

    brushTypeRef,
    effectTypeRef,
    activeToolRef,

    lastActionTimeRef,

  } = brushContext;

  const {
    userStrokesRef,
    setRedrawer,
  } = HistoryContext;

  const lastPointRef = useRef([]);
  const currentStrokeRef = useRef([]);

  function applyBlurAtTouch({nativeEvent}) {
    
    const {x, y} = getEventPos(nativeEvent);
    const radius = lineWidthRef.current; // Радиус размытия
    const intensity = 10; // Максимальная интенсивность размытия
    const levels = 5; // Количество уровней размытия

    const context = contextRef.current;
    context.save();

    // Сохраняем исходное изображение для последующего восстановления
    const originalData = context.getImageData(x - radius - intensity, y - radius - intensity, (radius + intensity) * 2, (radius + intensity) * 2);

    for (let i = 0; i < levels; i++) {
        const currentRadius = radius - (i * radius / levels);
        const currentIntensity = intensity * ((i + 1) / levels);

        // Временный канвас для применения эффекта блюра
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = (radius + intensity) * 2;
        tempCanvas.height = (radius + intensity) * 2;

        // Вставляем исходное изображение
        tempCtx.putImageData(originalData, 0, 0);
        tempCtx.filter = `blur(${currentIntensity}px)`;

        // Рисуем размытую версию изображения
        tempCtx.drawImage(tempCanvas, 0, 0);

        // Вырезаем круглую область
        tempCtx.globalCompositeOperation = 'destination-in';
        tempCtx.beginPath();
        tempCtx.arc(radius + intensity, radius + intensity, currentRadius, 0, Math.PI * 2);
        tempCtx.fillStyle = '#FFF';
        tempCtx.fill();

        // Возвращаем размытую область на основной канвас
        context.drawImage(tempCanvas, x - radius - intensity, y - radius - intensity);
    }

    context.restore();
  }

  const addStroke = (currentStroke) => {

    if (isRenderingStrokesRef.current) { return; }

    let points = [...currentStroke].map(point=>{
      if (Array.isArray(point)) {return point}
      else {return [point.x, point.y]}
    });


    const sets = brushSetsFun();
    const defaultBrushLink = brushLinkFun();

    const commonSets = brushSetsFun('common');

    const smooth = (!sets.speedDependence && !sets.spreading);
    if (smooth) {
      points = filterClosePoints(points, Math.round(commonSets.smoothing / zoomFactorRef.current))
    }

    const effect = activeToolRef.current === 'effect' ? effectTypeRef.current : null;

    const newStroke = {
        color: colorRef.current, 
        lineWidth: lineWidthRef.current, 
        softness: softnessRef.current, 
        points,
        time: Date.now(), // Добавляем метку времени к штриху
        type: 'stroke', // Указываем тип записи как штрих
        userId: activeUserIdRef.current,
        tool: activeToolRef.current,
        brush: defaultBrushLink,
        effect,
        sets,
    };

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    myStrokes.push(newStroke);
    myStrokes = myStrokes.filter(stroke=> !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory();

  };

  const startDrawing = ({ nativeEvent }) => {

    if (Date.now() - lastActionTimeRef.current < 100) {return;}

    isDrawingRef.current = true;
    if (gameInfoRef.current?.mode === 'line') { redrawCanvas(); }

    tempContextRef.current.save();

    const defaultBrushLink = brushLinkFun();

    if (['pencil'].includes(defaultBrushLink)) {tempContextRef.current.globalAlpha = 0.7;}
    if (['watercolor'].includes(defaultBrushLink)) {tempContextRef.current.globalAlpha = 0.5;}

    const point = getEventPos(nativeEvent);
    lastPointRef.current = point;
    currentStrokeRef.current = [point];


  };

  const draw = async ({ nativeEvent }) => {
    if (!isDrawingRef.current) return;

    wipeTempCanvas();
    const point = getEventPos(nativeEvent)

    const sets = brushSetsFun();
    const defaultBrushLink = brushLinkFun();
    const softness = actualSoftnessFun();

    lastPointRef.current = point;
    currentStrokeRef.current.push(point);

    const commonSets = brushSetsFun('common');

    let points = currentStrokeRef.current.map(point=>[point.x, point.y]);
    if (!sets?.speedDependence) {
      points = filterClosePoints(points, Math.round(commonSets.smoothing / zoomFactorRef.current))
    }

    if (['feather'].includes(defaultBrushLink)) {

      drawFeatherStroke({
        color: colorRef.current,
        lineWidth: lineWidthRef.current,
        points,
        sets,
      }, tempContextRef.current);


    } else if (['dashed'].includes(defaultBrushLink)) {

      drawDashedStroke({
        color: colorRef.current,
        lineWidth: lineWidthRef.current,
        points,
        sets,
      }, tempContextRef.current);


    } else if (defaultBrushLink === 'spray' || softness > 0) {

      await drawSprayStroke({
        color: visibleColorFun(),
        lineWidth: lineWidthRef.current,
        points,
        softness,
        sets,
      }, null, tempContextRef.current, isAppleDevise())

    } else {

      drawPlainMarkerStroke({
        color: visibleColorFun(),
        lineWidth: lineWidthRef.current,
        points,
        sets,
      }, tempContextRef.current)

    }

  };

  const finishDrawing = ({ nativeEvent }) => {
    if (!isDrawingRef.current) return;

    isDrawingRef.current = false;
    addStroke(currentStrokeRef.current);

    currentStrokeRef.current = [];
    tempContextRef.current.restore();

    wipeTempCanvas();



  };
   

  const startDrawingTouch = (event) => {

    if (Date.now() - lastActionTimeRef.current < 300) {return;}
    event.preventDefault(); 

    if (event.touches.length > 1) {
      if (currentStrokeRef.current.length < 5) {
        currentStrokeRef.current = [];
        wipeTempCanvas();
      } else {
        finishDrawingTouch();
      }
      return touchZoomStart(event);
    }

    isDrawingRef.current = true;
    if (gameInfoRef.current?.mode === 'line') { redrawCanvas(); }

    tempContextRef.current.save();

    const defaultBrushLink = brushLinkFun();

    if (['pencil'].includes(defaultBrushLink)) {tempContextRef.current.globalAlpha = 0.7;}
    if (['watercolor'].includes(defaultBrushLink)) {tempContextRef.current.globalAlpha = 0.5;}

    const touch = event.touches[0]; // Берем первое касание
    const touchPos = getEventPos(touch);
    lastPointRef.current = touchPos;
    currentStrokeRef.current = [touchPos];


  };

  const drawTouch = async (event) => {

    event.preventDefault();
    if (event.touches.length === 2) { return touchZoomMove(event); }

    if (!isDrawingRef.current) return;

    const touch = event.touches[0];
    const touchPos = getEventPos(touch);

    lastPointRef.current = touchPos;
    currentStrokeRef.current.push(touchPos);

    wipeTempCanvas();

    const sets = brushSetsFun();
    const defaultBrushLink = brushLinkFun();
    const softness = actualSoftnessFun();

    const commonSets = brushSetsFun('common');

    let points = currentStrokeRef.current.map(point=>[point.x, point.y]);

    if (!sets?.speedDependence) {
      points = filterClosePoints(points, Math.round(commonSets.smoothing / zoomFactorRef.current))
    }

    if (['feather'].includes(defaultBrushLink)) {
      
      drawFeatherStroke({
        color: colorRef.current,
        lineWidth: lineWidthRef.current,
        points,
        sets,
      }, tempContextRef.current);

    } else if (['dashed'].includes(defaultBrushLink)) {

      drawDashedStroke({
        color: colorRef.current,
        lineWidth: lineWidthRef.current,
        points,
        sets,
      }, tempContextRef.current);


    } else if (defaultBrushLink === 'spray' || softness > 0) {

      await drawSprayStroke({
        color: visibleColorFun(),
        lineWidth: lineWidthRef.current,
        points,
        softness,
        sets,
      }, null, tempContextRef.current, isAppleDevise())

    } else {

      drawPlainMarkerStroke({
        color: visibleColorFun(),
        lineWidth: lineWidthRef.current,
        points,
        sets,
      }, tempContextRef.current)

    }


  };

  const finishDrawingTouch = () => {
    if (!isDrawingRef.current) return;

    isDrawingRef.current = false;
    if (currentStrokeRef.current.length > 1) {
      addStroke(currentStrokeRef.current);
    }
    currentStrokeRef.current = [];
    tempContextRef.current.restore();

    wipeTempCanvas();

  };
  

  const fill = ({nativeEvent}) => {

    if (isRenderingStrokesRef.current) { return; }

    if (showPalette) { 
      lastActionTimeRef.current = Date.now();
      setShowPalette(false); return;
    }
    if (Date.now() - lastActionTimeRef.current < 100) {return;}
    lastActionTimeRef.current = Date.now();

    const { x, y } = getEventPos(nativeEvent);

    let fillStroke;
    if (ifBackgroundFill()) {
      
      fillStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'background',
        color: colorRef.current,
      }

    } else {
      fillStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'fill',
        x: x,
        y: y,
        color: colorRef.current,
      };
    }

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];

    myStrokes.push(fillStroke);
    myStrokes = myStrokes.filter(stroke=> !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory ()

  }

  function ifBackgroundFill () {

    const allStrokes = Object.values(userStrokesRef.current).flat();
    const combinedStrokes = allStrokes.filter(stroke => !stroke.cancelled).sort((a, b) => a.time - b.time);
    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');

    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);
    const hasUsualStrokes = strokesToRender.find(stroke=>stroke.type !== 'background');
    return !hasUsualStrokes;
    
  }

  return {

    applyBlurAtTouch,
    addStroke,

    startDrawing,
    draw,
    finishDrawing,

    startDrawingTouch,
    drawTouch,
    finishDrawingTouch,

    fill,
  }
};

