import React from 'react';
// import { ReactSVG } from "react-svg";
import "./CircleSwitch.css";

function CircleSwitch({
    id,
    className,
    text,

    icon,
    available = true,

    onClick,
    isActive = false,
    cursor = 'pointer',
    color,
    svgStyle = {},

    path,
    menu = false,
}: any) {

    svgStyle = Object.assign({
      height: "24px", 
      width: "24px",
      color,
      // fill: isActive ? color : 'initial', // Управление заливкой цветом
    }, svgStyle);

    let buttonStyle = available ? 'circle-switch' : 'circle-switch-n-a'
    let menuIncidatorIcon = available ? 'circle-switch-menu-indicator' : 'circle-switch-menu-indicator-n-a'

    return (
        <div
            id={id}
            className={
              `${className || ""} ${buttonStyle} ${isActive ? "active" : ""}`
            }
            onClick={onClick}
            style={{cursor}}
        >
          {menu && <div className={`${menuIncidatorIcon} ${isActive ? "active" : ""}`} />}

            { 
              icon ? (
                (
                  <span
                    className="svg"
                    style={svgStyle}
                  >{React.cloneElement(icon, { style: svgStyle })}</span>

                  // <ReactSVG
                  // className="svg"
                  // src={path}
                  // style={svgStyle}
                ) 
              ) : (
                <div className="svg empty-circle" style={{ height: "25px", width: "25px", borderRadius: "50%" }}></div>
              )
            }
            {/* <span>{text}</span> */}
        </div>
    );
}

export default CircleSwitch;
