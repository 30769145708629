import React from 'react';
import { 
  FaSlack, FaStarHalfAlt, FaTint, FaBrush, FaSprayCan, FaPencilAlt, FaFeatherAlt, FaPaintBrush,
  FaPaw,

  FaCog,
 } from 'react-icons/fa';
import { 
  FaMarker,
  FaBroomBall,
 } from 'react-icons/fa6';
import { 
  TbLineDashed, TbBrush
 } from "react-icons/tb";

import './BrushMenu.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';


const brushIcons = {
  'plain': FaMarker,
  'spray': FaSprayCan,
  'bristle': FaPaintBrush,
  'feather': FaFeatherAlt,
  'pencil': FaPencilAlt,
  'oil': FaBrush,
  'rembrandt': FaBroomBall,
  'watercolor': TbBrush,
  'dashed': TbLineDashed,
  'test': FaPaw,
}

function BrushMenu({
  gameContext,
  brushContext,
}) {

  const { t } = useTranslation();

  const {
    gameInfoRef,
    openMenu, closeMenu,

    premiumFeaturesRef,
    userSetsRef,

    delayPremiumMenu,

    saveUserSets,
  } = gameContext;

  const { 
    setBrushType,
    brushTypeRef,

    brushHistoryRef,
    setTheColorFun,

    colorRef,
    setSoftness,

    lastActionTimeRef,
   } = brushContext;


  const game = gameInfoRef.current;

  const defaultBrushes = [
    'plain',
    'spray',
    'bristle',
    'feather',
    'pencil',
    'oil',
    'rembrandt',
    'watercolor',
    'dashed',
    // 'test',
  ].map(key=>{
    return {
      key,
      label: t(`brush.${key}.name`),
      icon: brushIcons[key],
    }
  });

  const savedBrushes = userSetsRef.current.savedBrushes || []
  const userBrushes = savedBrushes.map(key=>{
    const brushSettings = userSetsRef.current[key] || {};
    const defaultBrushLink = brushSettings.clone;
    return {
      key,
      label: brushSettings.label,
      icon: brushIcons[defaultBrushLink],
    }
  })

  const brushes = [
    ...defaultBrushes,
    ...userBrushes,
  ]

  const handleOverlayClick = () =>{
    if (lastActionTimeRef.current < Date.now() - 300) {
      closeMenu(); 
    }
  }

  const handleSwitchBrush = (newBrushType) => {

    if (lastActionTimeRef.current > Date.now() - 300) { return; }

    if (newBrushType !== 'plain' && 
      (!premiumFeaturesRef.current.includes('brushes') 
      || userSetsRef.current.premiumOff)
      ) {
      closeMenu(); 
      delayPremiumMenu('menu');
    } else {

      const previousBrush = brushTypeRef.current;
      
      if (previousBrush !== newBrushType) {
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
      }
      brushTypeRef.current = newBrushType;
      setBrushType(newBrushType);

      if (newBrushType === 'spray') {

      } else {
        setSoftness(0)
      }

      brushHistoryRef.current.push(newBrushType);
      closeMenu(); 
      saveUserSets({ brushType: newBrushType })

    }

  };

  const handleOpenBrushSettings = (newBrushType, e) => {
    e?.preventDefault(); e?.stopPropagation(); 
    openMenu('customizeBrush', {brush: newBrushType, layer: true});

    if (newBrushType !== 'plain' && 
      (!premiumFeaturesRef.current.includes('brushes') 
      || userSetsRef.current.premiumOff)
      ) {
      return;
    } 



    if (newBrushType === 'spray') {
      
    } else {
      setSoftness(0)
    }

    if (newBrushType !== 'common') { 
      brushTypeRef.current = newBrushType;
      setBrushType(newBrushType);
      brushHistoryRef.current.push(newBrushType);
      saveUserSets({ brushType: newBrushType })
    }


  }


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="brush-menu scroll-active touch-active">
      
        <span 
          className="brush-menu-title"
          onClick={(e) => {handleOpenBrushSettings('common', e)}}
          onContextMenu={(e) => {handleOpenBrushSettings('common', e)}}
        >
          {t('brush.title')}
          <FaCog
            style={{paddingLeft: '15px'}}
          />
        </span>
        
          {brushes.map((brush) => (
            <div key={brush.key} 
            className={`brush-option-row ${brushTypeRef.current === brush.key ? 'active' : ''}`}
            
            onClick={()=>handleSwitchBrush(brush.key)}
            onContextMenu={(e)=>{handleOpenBrushSettings(brush.key, e)}}
            
            >
              <label className="brush-label">
                <brush.icon className='brush-icon'/>
                <span> {brush.label}</span>
                {brushTypeRef.current === brush.key && (
                <FaCog
                  className="brush-settings-icon"
                  onClick={(e) => {
                    e.stopPropagation(); handleOpenBrushSettings(brush.key);
                  }}
                />
              )}
              </label>
            </div>
            
        ))}
        
      </div>
    </>
  );
}

export default BrushMenu;
