

export function filterClosePoints(points, minDistance) {
  points = points.slice(0);
  if (!minDistance) { return points; }
  if (points.length < 4) { return points; }

  points = points.slice(0);

  const lastPoint = points.pop();
  const filteredPoints = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const dx = points[i][0] - filteredPoints[filteredPoints.length - 1][0];
    const dy = points[i][1] - filteredPoints[filteredPoints.length - 1][1];
    if (dx * dx + dy * dy >= minDistance * minDistance) {
      filteredPoints.push(points[i]);
    }
  }
  filteredPoints.push(lastPoint);
  return filteredPoints;
}