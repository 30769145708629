// GrayscalePicker.js

import React, { useEffect, useState } from 'react';
import { HexAlphaColorPicker } from "react-colorful";
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

import RecentColorsPalette from './RecentColorsPalette'; // Импорт нового компонента
import SavedColorsPalette from './SavedColorsPalette'; // Импорт нового компонента

function GrayscalePicker({ 
  gameContext,
  brushContext,
  canvasContext,
 }) {

    const { t } = useTranslation();

    const {
      delayPremiumMenu,
    } = gameContext;

    const {
      clickPositionRef,
      currentCanvasRectRef,
      controlPanelOffsetRef,
    } = canvasContext;

    const {
      color, 
      colorRef, 
      setTheColorFun, 
      softness, 
      lastColorsRef,

      lineWidth, 
      visibleLineWidth, 
      setTheSizeFun,

      setShowPalette,

      brushIndicatorFrame,
    } = brushContext;
    
    const onLineWidthChange =(e) => {
      setTheSizeFun(+e.target.value)
    }

    const onClose = () =>{
      setShowPalette(false)
    }

    const timeoutClose = (timer = 500) =>{
      setTimeout(() => {
        onClose();
      }, timer);
    }

    const [tempColor, setTempColor] = useState(color);

    const handleColorChange = (newColor)=>{
      setTheColorFun(newColor);
      setTempColor(newColor);
    }


    const blurSize = `${softness * visibleLineWidth}px`; // Пример коэффициента умножения на 3

    let positionFromTop = 210;
    positionFromTop = Math.max(positionFromTop, controlPanelOffsetRef.current - 210);
    positionFromTop = Math.min(positionFromTop, window.innerHeight - 360);

    return (

      <>
        <div className='overlay-background'
          onClick={onClose}
        ></div>

        <div className='grayscale-palette' id="palette" style={{ 
            position: 'fixed', 
            left: `50%`,
            transform: `translate(-50%)`,
            top: `${positionFromTop}px`, // Move up accounting for the circle size
            zIndex: 1200,
            display: 'inline-block',
        }}>
            <div style={{ 
                width: `${visibleLineWidth}px`, 
                height: `${visibleLineWidth}px`, 
                borderRadius: '50%', 
                background: color, 
                position: 'absolute', // Absolute positioning
                left: `-32.5px`, // Move the circle to the left
                top: `${-10 - visibleLineWidth}px`, 
                transform: 'translateX(-50%)', // Vertically center the circle
                boxShadow: softness ? 0 : brushIndicatorFrame,
                filter: `blur(${blurSize})`, 
            }}></div>

            <div style={{ 
                  position: 'absolute', 
                  height: '200px', 
                  display: 'flex', 
                  justifyContent: 'left',
                  left: '0px', // Уже установлено для перемещения влево
                  top: '13%', 
            }}>

                <input
                    type="range"
                    className="widthSlider touch-active"
                    min="1"
                    max="100"
                    value={lineWidth}
                    onChange={onLineWidthChange}
                    style={{ 
                        height: '177px',
                        width: '25px',
                        position: 'absolute',
                        writingMode: 'vertical-lr',
                        direction: 'rtl',

                        cursor: 'pointer', 
                        background: 'var(--tg-theme-button-color)', 
                        outlineColor: 'palegreen', 
                        left: '-47px',
                        top: '-29px',
                    }}
                />
            </div>

            <div style={{ filter: 'grayscale(100%)' }}>  
              <HexAlphaColorPicker color={tempColor} onChange={handleColorChange}/>
            </div>
            {/* <HexAlphaColorPicker color={color} onChange={setTheColorFun} /> */}

            <div style={{ 
              position: 'absolute', 
              left: '210px', 
              top: '0%', 
              pointerEvents: 'none', 
            }}>
              <RecentColorsPalette 
                gameContext={gameContext}
                brushContext={brushContext}
                onClose={timeoutClose}
              />
            </div>
            <div style={{ 
              position: 'absolute', 
              right: '-42px', 
              top: '-10px', 
              transform: 'translateY(-100%)', 
              pointerEvents: 'none', 
            }}>
              <SavedColorsPalette 
                gameContext={gameContext}
                brushContext={brushContext}
                onClose={timeoutClose}
              />
            </div>
            
            <button className='wide-button'
              style={{
                margin: '0',
                width: '100%',
                opacity: 0.8,
                position: 'absolute',
                top: '-10px', 
                transform: 'translateY(-100%)', 
                zIndex: 1201
              }}
              onClick={()=>{delayPremiumMenu('menu')}}
            >
              {t(`feature.palette.name`)}
            </button>
        </div>
      </>
    );
}

export default GrayscalePicker;
