// ColorIndicator.js
import React from 'react';

const ColorIndicator = ({ 
  canvasContext,
  brushContext,

  position, 
  clickPosition = {x: 1000, y: 1000}, 
  maxVisibleLineWidth = 60,
  minVisibleLineWidth = 60,
 }) => {

    const {
      controlPanelOffsetRef,
    } = canvasContext;


    const {
      actualSoftnessFun,
      color, 
      visibleLineWidth,

      brushIndicatorFrame,
    } = brushContext;

    if (!position) {
        return null; // Не отображаем компонент, если позиция не задана
    }

    const indicatorSideWidth = Math.min(Math.max(visibleLineWidth, minVisibleLineWidth), maxVisibleLineWidth);
    const blurSize = `${actualSoftnessFun() * indicatorSideWidth}px`; // Пример коэффициента умножения на 3

    const maxBottomPosition = (controlPanelOffsetRef.current || 430) + 45

    return (
        <div
            style={{
                position: 'fixed',
                left: `${position.x - indicatorSideWidth/2}px`,
                top: `${Math.min(maxBottomPosition, position.y) - 110}px`,
                width: indicatorSideWidth,
                height: indicatorSideWidth,
                borderRadius: '50%',
                background: color,
                pointerEvents: 'none', // Исключает элемент из событий указателя
                boxShadow: actualSoftnessFun() ? 0 : brushIndicatorFrame,
                filter: `blur(${blurSize})`, 
                zIndex: 200,
            }}
        />
    );
};

export default ColorIndicator;