import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useLobbyTelegram = () => {

  const [forcer, setForcer] = useState(0);
  const forceRender = () => { setForcer(i => i + 1);};

  const defaultLanguage = 'en';
  const { i18n } = useTranslation();


  useEffect(() => {

      const script = document.createElement('script');
      script.src = "https://telegram.org/js/telegram-web-app.js";
      script.async = true;
      script.onload = () => {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        const languageCode = `${window.Telegram?.WebApp?.initDataUnsafe?.user?.language_code || defaultLanguage}`;
        i18n.changeLanguage(languageCode);
        forceRender();

        // window.Telegram.WebApp.enableClosingConfirmation()
      };
      document.body.appendChild(script);
      
  }, []); 


  return {};
};

export default useLobbyTelegram;


