// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Импорт ресурсов переводов
import translationRU from './ru.json';
import translationEN from './en.json';
import translationKK from './kk.json';
import translationUK from './uk.json';

i18n
  .use(initReactI18next) // проходит через react-i18next bridge
  .init({
    resources: {
      ru: {
        translation: translationRU
      },
      en: {
        translation: translationEN
      },
      kk: {
        translation: translationKK
      },
      uk: {
        translation: translationUK
      },
    },
    lng: "ru", // язык по умолчанию
    fallbackLng: "ru", // язык запасной
    interpolation: {
      escapeValue: false // react уже сохраняет от xss
    }
  });

export default i18n;
