// OrientationIcons.js
import React from 'react';

const roundedRect = (width, height) => (
  <rect 
    width={width} 
    height={height} 
    x={(30 - width) / 2} 
    y={(30 - height) / 2} 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    rx="4" 
    ry="4"
  />
);

export const SquareIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30">
    {roundedRect(26, 26)}
  </svg>
);

export const HorizontalIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30">
    {roundedRect(26, 17)}
  </svg>
);

export const VerticalIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30">
    {roundedRect(17, 26)}
  </svg>
);