// Spectator.js

import './Spectator.css'; // Подключаем стили

import React from 'react';
import { useTranslation } from 'react-i18next';

function Spectator({ 
  authorName, 
 }) {

  const { t } = useTranslation();
  const nowIsDrawingText = t('spectator.who_draws', { authorName })

  return (
    <div>
      <p className="spectator-text">{nowIsDrawingText}</p>
      <button 
      className="spectator-button" 
      onClick={window.Telegram?.WebApp?.close}
      >Закрыть</button>
    </div>
  );
}

export default Spectator;
