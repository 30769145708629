import * as PIXI from 'pixi.js';
import { applyPixiFilter } from '../pixi/pixiFilters'; // Будем использовать эту функцию
import chroma from 'chroma-js';

export async function fillGradient(context, points, color, gradientColor, lineWidth) {

  if (gradientColor) {
    let startPoint, endPoint;

    if (points.length === 1) {
      startPoint = points[0];
      endPoint = createPointForDirection(startPoint);
    } else {
      startPoint = points[0];
      endPoint = points[points.length - 1];
    }

    const gradientPoints = calculateGradientPoints(startPoint, endPoint, lineWidth);

    const gPoints = [{
      point: gradientPoints.start,
      color: color,
    }, {
      point: gradientPoints.end,
      color: gradientColor,
    }]

    try {

      const gradient = context.createLinearGradient(
        gradientPoints.start[0], gradientPoints.start[1],
        gradientPoints.end[0], gradientPoints.end[1]
      );
        
      gradient.addColorStop(0, color);
      gradient.addColorStop(1, gradientColor);

      context.globalCompositeOperation = 'source-in';
      context.fillStyle = gradient;
      context.fillRect(0, 0, context.canvas.width, context.canvas.height);
      
    } catch (e) {
      context.globalCompositeOperation = 'source-in';
      context.fillStyle = color;
      context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    }

  } else {
    context.globalCompositeOperation = 'source-in';
    context.fillStyle = color;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
  }

}





export async function pixiGradient({
  gradientPoints, 
  color, 
  gradientColor, 
  lineWidth,
}, context) {
  const originalCanvas = context.canvas;
  
  // Создаем временный canvas
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = originalCanvas.width;
  tempCanvas.height = originalCanvas.height;
  const tempContext = tempCanvas.getContext('2d', { alpha: true });
  tempContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);

  // Копируем содержимое оригинального canvas на временный
  tempContext.drawImage(originalCanvas, 0, 0);


  // Проверяем, что есть хотя бы две точки для градиента
  if (gradientPoints.length < 2) {
    console.error('Необходимо минимум две точки для создания градиента');
    return false;
  }

  // Сортируем точки: если последняя точка выше и левее первой, разворачиваем массив
  const firstPoint = gradientPoints[0].point;
  const lastPoint = gradientPoints[gradientPoints.length - 1].point;

  let reversed;
  if (lastPoint[0] < firstPoint[0] && lastPoint[1] < firstPoint[1]) {
    gradientPoints.reverse();
    reversed = true;
  }

  const w = originalCanvas.width;
  const h = originalCanvas.height;
  const centerX = w / 2;
  const centerY = h / 2;

  // Вычисляем угол градиента в радианах
  const dx = lastPoint[0] - firstPoint[0];
  const dy = lastPoint[1] - firstPoint[1];
  const angle = (Math.atan2(dy, dx) * 180 / Math.PI + 360) % 360;
  const theta = angle * Math.PI / 180;

  const cosTheta = Math.cos(theta);
  const sinTheta = Math.sin(theta);

  let points = [];

  // Функция для проверки и добавления точки пересечения
  function addIntersectionPoint(x, y) {
    if (x >= 0 && x <= w && y >= 0 && y <= h) {
      points.push({ x, y });
    }
  }

  // Пересечения с границами canvas
  // Левая граница (x = 0)
  if (cosTheta !== 0) {
    let t = (0 - centerX) / cosTheta;
    let y = centerY + t * sinTheta;
    addIntersectionPoint(0, y);
  }

  // Правая граница (x = w)
  if (cosTheta !== 0) {
    let t = (w - centerX) / cosTheta;
    let y = centerY + t * sinTheta;
    addIntersectionPoint(w, y);
  }

  // Верхняя граница (y = 0)
  if (sinTheta !== 0) {
    let t = (0 - centerY) / sinTheta;
    let x = centerX + t * cosTheta;
    addIntersectionPoint(x, 0);
  }

  // Нижняя граница (y = h)
  if (sinTheta !== 0) {
    let t = (h - centerY) / sinTheta;
    let x = centerX + t * cosTheta;
    addIntersectionPoint(x, h);
  }

  // Проверяем количество точек пересечения
  if (points.length < 2) {
    // Обрабатываем особые случаи, когда угол 0°, 90°, 180°, 270°
    if (Math.abs(cosTheta) < 1e-6) {
      // Вертикальная линия
      points = [
        { x: centerX, y: 0 },
        { x: centerX, y: h },
      ];
    } else if (Math.abs(sinTheta) < 1e-6) {
      // Горизонтальная линия
      points = [
        { x: 0, y: centerY },
        { x: w, y: centerY },
      ];
    } else {
      console.error('Не удалось найти пересечения градиентной линии с границами canvas');
      return false;
    }
  } else if (points.length > 2) {
    // Если больше двух точек, выбираем самые дальние
    points.sort((a, b) => a.x - b.x || a.y - b.y);
    points = [points[0], points[points.length - 1]];
  }

  const gradientStart = points[0];
  const gradientEnd = points[1];

  // Функция для вычисления offset точки на градиентной линии
  function computeOffset(x, y) {
    const dx = gradientEnd.x - gradientStart.x;
    const dy = gradientEnd.y - gradientStart.y;
    const lengthSquared = dx * dx + dy * dy;
    const t = ((x - gradientStart.x) * dx + (y - gradientStart.y) * dy) / lengthSquared;
    return t;
  }

  // Вычисляем offsets для начальной и конечной точек
  const startPointOffset = computeOffset(firstPoint[0], firstPoint[1]);
  const endPointOffset = computeOffset(lastPoint[0], lastPoint[1]);

  // Ограничиваем offsets между 0 и 1
  let startOffsetClamped = Math.min(Math.max(startPointOffset, 0), 1);
  let endOffsetClamped = Math.min(Math.max(endPointOffset, 0), 1);

  let stops = [
    { offset: startOffsetClamped, color: color, alpha: chroma(color).alpha() },
    { offset: endOffsetClamped, color: gradientColor, alpha: chroma(gradientColor).alpha() },
  ];

  if (reversed) {
    stops.push({ offset: 0, color: color, alpha: chroma(color).alpha() })
    stops.unshift({ offset: 1, color: gradientColor, alpha: chroma(gradientColor).alpha() })
  } else {
    stops.unshift({ offset: 0, color: color, alpha: chroma(color).alpha() })
    stops.push({ offset: 1, color: gradientColor, alpha: chroma(gradientColor).alpha() })
  }

  // Применяем градиент к временному canvas
  const success = await applyPixiFilter(tempCanvas, 'gradient', {
    stops,
    angle: angle + 90,
    alpha: 1
  });

  if (!success) {
    console.error('Failed to apply gradient filter');
    return false;
  }

  context.globalCompositeOperation = 'source-in';
  context.drawImage(tempCanvas, 0, 0);

  // Восстанавливаем оригинальный режим композиции
  context.globalCompositeOperation = 'source-over';
  return true;
}



function calculateGradientOffsets(gradientPoints, canvasWidth, canvasHeight, angle) {
  // Нормализуем координаты
  const startNorm = [
    gradientPoints.start[0] / canvasWidth,
    gradientPoints.start[1] / canvasHeight
  ];
  const endNorm = [
    gradientPoints.end[0] / canvasWidth,
    gradientPoints.end[1] / canvasHeight
  ];

  // Преобразуем угол в радианы
  const angleRad = (angle - 90) * Math.PI / 180;

  // Вычисляем вектор направления градиента
  const gradientVector = [Math.cos(angleRad), Math.sin(angleRad)];

  // Проецируем точки на линию градиента
  const startProj = startNorm[0] * gradientVector[0] + startNorm[1] * gradientVector[1];
  const endProj = endNorm[0] * gradientVector[0] + endNorm[1] * gradientVector[1];

  // Находим минимальное и максимальное значения проекций
  const minProj = Math.min(startProj, endProj);
  const maxProj = Math.max(startProj, endProj);

  // Преобразуем проекции в диапазон от 0 до 1
  const startOffset = (startProj - minProj) / (maxProj - minProj);
  const endOffset = (endProj - minProj) / (maxProj - minProj);

  return {
    startPointOffset: Math.min(startOffset, endOffset),
    endPointOffset: Math.max(startOffset, endOffset)
  };
}



export function calculateGradientPoints(startPoint, endPoint, lineWidth = 0) {
  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];
  const length = Math.sqrt(dx * dx + dy * dy);

  // Проверяем, не слишком ли близко точки друг к другу
  if (length < 0.001) {
    // Если точки слишком близко, создаем новую конечную точку
    endPoint = createPointForDirection(startPoint);
    return calculateGradientPoints(startPoint, endPoint, lineWidth);
  }

  const unitX = dx / length;
  const unitY = dy / length;

  const halfLineWidth = lineWidth / 2;
  const gradientStartX = startPoint[0] - unitX * halfLineWidth;
  const gradientStartY = startPoint[1] - unitY * halfLineWidth;
  const gradientEndX = endPoint[0] + unitX * halfLineWidth;
  const gradientEndY = endPoint[1] + unitY * halfLineWidth;

  return {
    start: [gradientStartX, gradientStartY],
    end: [gradientEndX, gradientEndY]
  };
}


function createPointForDirection (point) {
  return [point[0], point[1] + 3];
}

function createPseudoRandomPoint(point) {
  // Используем сумму координат для создания псевдослучайного числа
  const pseudoRandom = Math.sin(point[0] + point[1]) * 10000;
  
  // Генерируем смещение в диапазоне [-1, 1]
  const offsetX = (pseudoRandom % 2) - 1;
  const offsetY = ((pseudoRandom * 2) % 2) - 1;
  
  // Создаем новую точку, добавляя смещение к исходной точке
  return [point[0] + offsetX, point[1] + offsetY];
}

