// loadHistory.js
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGameContext } from '../../contexts/GameContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useMountHistory = ({
  render,
}) => {

  const strokesUpdatedRef = useRef(false);

  const gameContext = useGameContext();
  const historyContext = useHistoryContext();
  const brushContext = useBrushContext();

  const { 
    gameInfo, 
    gameIsLoaded, 
    premiumIsLoaded,
    strokesAreLoaded, setStrokesAreLoaded,
    moderatorModeRef,
    socketRef,
    socketStatus,
    
    activeUserIdRef,
    userSetsRef,
   } = gameContext;
  
  const {
    userStrokesRef,
    setRedrawer,
  } = historyContext;

  const {
    setTheColorFun,
    setTheSizeFun,
    setSoftness,
    setBrushType,
    setEffectType,
    lastColorsRef,

    brushColorRef,
    eraserColorRef,
  } = brushContext;


  useEffect(() => {
    const socket = socketRef.current;

    if (socket) {

      const handleRender = async (callback) => {
        callback?.(await render());
      };

      // Обработчик чужих штрихов
      const handleStrokes = ({userId: companionUserId, strokes, length}) => {

        length = length || strokes.length;

        let companionStrokes = userStrokesRef.current[companionUserId] || []
        const updatedStrokes = companionStrokes.slice(0, length - strokes.length).concat(strokes);
        userStrokesRef.current[companionUserId] = updatedStrokes;
        setRedrawer(i => i + 1);

      };

      // Установка слушателей
      socket.on('strokes', handleStrokes);
      socket.on('render', handleRender);

      // Очистка слушателей при размонтировании или изменении статуса сокета
      return () => {
        socket.off('strokes', handleStrokes);
        socket.off('render', handleRender);
      };
    }
  }, [socketStatus]);


  // Загрузка штрихов из истории
  useEffect(() => {

    function setBrushes() {
      if (userSetsRef.current.brushType) { setBrushType(userSetsRef.current.brushType); }
      if (userSetsRef.current.effectType) { setEffectType(userSetsRef.current.effectType); }
    }
    setBrushes();

    function startBackground (localHistory, myStrokes) {

      let userBackgroundColor = userSetsRef.current.startBgColor;
      if (
        !moderatorModeRef.current
        && `${game?.user_key}` === `${activeUserIdRef.current}` 
        && userBackgroundColor
        && userBackgroundColor !== '#FFFFFFFF'
        && localHistory.length === 0 
        && myStrokes.length === 0
        ) {
          myStrokes.push({
            time: Date.now(),
            userId: activeUserIdRef.current,
            type: 'background',
            color: userBackgroundColor,
          });
          eraserColorRef.current = userBackgroundColor;
      }
  
    }

    if (strokesUpdatedRef.current) {return;}

    window.logPerformance('Started updating strokes')

    const game = gameInfo;
    if (!game || game?.error) {return;} 

    const localStrokes = userStrokesRef.current;
    const localHistory = localStrokes?.[activeUserIdRef.current] || [];

    const strokes = game.strokes || {};
    strokes[activeUserIdRef.current] = strokes[activeUserIdRef.current] || []; // Создаем историю для текущего пользователя
    const myStrokes = strokes[activeUserIdRef.current];

    startBackground (localHistory, myStrokes);

    if (localHistory.length > myStrokes.length) {
      strokes[activeUserIdRef.current] = localHistory;
    } else {

      const lastStroke = myStrokes[myStrokes.length - 1];
      if (lastStroke?.lineWidth) {setTheSizeFun(lastStroke.lineWidth);}
      if (lastStroke?.softness) {setSoftness(lastStroke.softness);}
      // if (lastStroke?.brush) {
      //   if (lastStroke.tool === 'effect') {setEffectType(lastStroke.brush);}
      //   if (lastStroke.tool === 'brush') {setBrushType(lastStroke.brush);}
      // }

      if (game.settings.color) {
        setTheColorFun(game.settings.color);
      } else if (userSetsRef.current.startColor) {
        setTheColorFun(userSetsRef.current.startColor);
      }
      if (game.settings.gradientColor) {
        setTheColorFun(game.settings.gradientColor, 'gradientColor');
      }
      if (game.settings.eraserColor) {
        eraserColorRef.current = game.settings.eraserColor
      }


      lastColorsRef.current = game.colors?.[activeUserIdRef.current] || [];

    }

    userStrokesRef.current = strokes;
    setStrokesAreLoaded(true);
    setRedrawer(i => i + 1);
    strokesUpdatedRef.current = true;

    window.logPerformance('Strokes are updated')

  
  }, [premiumIsLoaded]);

};


