
import { getTexture, brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 
import { drawPlainStroke } from './plain';

const defaultBrushSettings = brushDefaults.pencil;

export function seededRandom(seed) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export async function drawPencilStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  time,
  sets = {},
}, context) {

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  // const textureName = '123';
  const textureName = brushSettings.texture || 'basic';
  const textureScale = brushSettings.textureScale || 1;
  const pencilTexture = await getTexture('pencil', textureName);

  if (brushSettings.outline) {
    const outlineSize = Math.min(6, Math.max(2, lineWidth / 3));
    await applyPencilStroke({
      color,
      gradientColor,
      lineWidth: lineWidth + outlineSize,
      points,
      time,
      texture: pencilTexture,
      textureScale: textureScale * 1.5,
      textureOpacity: 0.5,
      brushSettings,
    }, context)
  }

  await applyPencilStroke({
    color,
    gradientColor,
    lineWidth,
    points,
    time,
    texture: pencilTexture,
    textureScale,
    brushSettings,
  }, context)


}


async function applyPencilStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  time,
  texture,
  brushSettings,

  textureScale = 1,
  textureOpacity = 1,
}, context) {
  // if (points.length < 2 || !texture) return;

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  drawPlainStroke({
    points, 
    color: 'black', 
    lineWidth,
  }, bufferCtx, brushSettings)

  // bufferCtx.lineCap = 'round';
  // bufferCtx.lineJoin = 'round';

  // // Рисуем сплошной штрих
  // bufferCtx.strokeStyle = 'black';
  // bufferCtx.lineWidth = lineWidth;

  // if (points.length === 1) {
  //   drawPoint(points, bufferCtx, lineWidth);
  // } else if (points.length === 2) {
  //   drawStraightLine (points, bufferCtx)
  // } else {
  //   drawStroke(points, bufferCtx);
  // }

  // ------------

  bufferCtx.globalCompositeOperation = 'destination-in';

  const patternCanvas = document.createElement('canvas');
  patternCanvas.width = texture.width * textureScale;
  patternCanvas.height = texture.height * textureScale;
  const patternCtx = patternCanvas.getContext('2d');
  
  patternCtx.scale(textureScale, textureScale);
  patternCtx.drawImage(texture, 0, 0);
  
  const pattern = bufferCtx.createPattern(patternCanvas, 'repeat');
  bufferCtx.fillStyle = pattern;
  bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  // Применяем цвет
  bufferCtx.globalAlpha = brushSettings.opacity * textureOpacity;
  bufferCtx.globalCompositeOperation = 'source-in';

  await fillGradient(bufferCtx, points, color, gradientColor, lineWidth)

  context.drawImage(bufferCanvas, 0, 0);
}


function drawStroke (points, bufferCtx) {

  
  bufferCtx.beginPath();
  bufferCtx.moveTo(points[0][0], points[0][1]);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + currentPoint[0]) / 2,
      (nextPoint[1] + currentPoint[1]) / 2
    ];
    bufferCtx.quadraticCurveTo(nextPoint[0], nextPoint[1], midPoint[0], midPoint[1]);
  }
  const lastPoint = points[points.length - 1]
  bufferCtx.lineTo(
    lastPoint[0], 
    lastPoint[1],
    );
  bufferCtx.stroke();
  
}

function drawStraightLine (points, bufferCtx) {

  let firstPoint = points[0];
  let secondPoint = points[1];

  // Рисуем линию между двумя точками
  bufferCtx.moveTo(
    firstPoint[0], 
    firstPoint[1],
  );
  if (Array.isArray(secondPoint)) {} else {secondPoint = [secondPoint.x, secondPoint.y]}

  bufferCtx.lineTo(
    secondPoint[0], 
    secondPoint[1],
  );
  bufferCtx.stroke();
  
}


function drawPoint (points, bufferCtx, lineWidth) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  bufferCtx.beginPath();
  bufferCtx.arc(
    point[0], 
    point[1], 
    lineWidth / 2, 
    0, 
    Math.PI * 2
    );
  bufferCtx.fillStyle = 'black';
  bufferCtx.fill();

}