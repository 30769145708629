// BoardProviders.js
import { GameProvider } from '../../contexts/GameContext';
import { DrawingProvider } from '../../contexts/DrawingContext';
import { BrushProvider } from '../../contexts/BrushContext';
import { HistoryProvider } from '../../contexts/HistoryContext';
import BoardMain from './BoardMain';


function BoardProviders() {

  return (
    <GameProvider>
      <DrawingProvider>
        <BrushProvider>
          <HistoryProvider>
            <BoardMain/>
          </HistoryProvider>
        </BrushProvider>
      </DrawingProvider>
    </GameProvider>
  );
}

export default BoardProviders;