// sparkle.js
import { brushDefaults } from './helpers/brushLoader';

const defaultBrushSettings = brushDefaults.sparkle || {};

export async function drawSparkleStroke(stroke, context, params) {
  const {
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  
  // Создаем буферный канвас
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  // Добавляем искры
  addSparkles(stroke, bufferCtx, brushSettings);
  
  // Копируем результат на основной холст
  context.drawImage(bufferCanvas, 0, 0);
}


function addSparkles(stroke, context, brushSettings) {
  const {
    points, 
    color, 
    lineWidth,
  } = stroke;

  const {
    sparkleDensity = 0.5,          // Плотность искр (0-1)
    sparkleSize = 1,             // Размер отдельной искры
    sparkleDistributionPower = 0.8, // Степень распределения искр
  } = brushSettings;

  context.fillStyle = color;
  context.globalAlpha = 1;

  const density = Math.floor(lineWidth * sparkleDensity * 2);

  // Проходим по точкам штриха с шагом, зависящим от плотности
  const step = Math.max(1, Math.round(1 / sparkleDensity));
  
  for (let i = 0; i < points.length; i += step) {
    const point = points[i];
    
    // Создаем искры вокруг каждой точки
    for (let j = 0; j < density; j++) {
      const seed1 = stroke.time + point[0] + point[1] + j + i;
      const seed2 = stroke.time + point[0] * point[1] + j - 1;

      const angle = seededRandom(seed1) * 2 * Math.PI;
      const randomValue = seededRandom(seed2);
      const radius = Math.pow(randomValue, sparkleDistributionPower) * lineWidth * 0.5;
      
      const sparkleX = point[0] + radius * Math.cos(angle);
      const sparkleY = point[1] + radius * Math.sin(angle);
      
      // Рисуем отдельную искру
      context.fillRect(
        sparkleX - sparkleSize / 2, 
        sparkleY - sparkleSize / 2, 
        sparkleSize, 
        sparkleSize
      );

    }
  }
  
  context.globalAlpha = 1;
}

// Простая функция для генерации псевдослучайных чисел
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}
