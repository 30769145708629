// WordChangeOverlay.js

import React, { useState } from 'react';
import { FaCog, FaExpand, FaDownload, FaChevronRight } from 'react-icons/fa';

import './WordChangeOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';

function WordChangeOverlay({
  gameContext,
}) {

  const { t } = useTranslation();

  const {
    drawMethodsRef,
    gameInfoRef,

    changeWord,
    activateHints,
    openLetter,

    openMenu, closeMenu,
    setPopupMessage,
    delayPremiumMenu,

    canDraw,
    userSetsRef,
    groupSetsRef,
    premiumFeaturesRef,
    gameWordFun,
    moderatorModeRef,

    hostAction,
  } = gameContext;

  const drawMethods = drawMethodsRef.current;
  const { render } = drawMethods;

  const game = gameInfoRef.current;
  const hintsEnabled = game.hint?.active;
  const hints = game.hint?.hints || [];

  const knowTheWord = canDraw && !moderatorModeRef.current;

  const [changingWord, setChangingWord] = useState(false);
  const [wordInputValue, setWordInputValue] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);




  const handleInputFocus = (e) => {

    if (!premiumFeaturesRef.current.includes('personoalWord') || userSetsRef.current.premiumOff) {
      e.target.blur(); setIsInputFocused(false); setWordInputValue('');
      // closeMenu();
      return delayPremiumMenu('menu');
    }
    if (!groupSetsRef.current.customWord) {
      e.target.blur(); setIsInputFocused(false); setWordInputValue('');
      const popupParams = {
        title: t('word.custom_is_off_title'),
        message: t('word.custom_is_off_text'),
      };
      return setPopupMessage(popupParams)
    }
    setIsInputFocused(true);

  };

  const handleInputSubmit = () => {
    const customWord = wordInputValue.trim();
    if (!customWord) {return setWordInputValue('');}
    changeWord(wordInputValue).then(word=>{
      setWordInputValue('');
    });
  };

  const activateHintsFun = ()=>{

    if (!canDraw) { return }

    const hintsNotAvailable = false;
    
    // const hintsNotAvailable = !premiumFeaturesRef.current.includes('hints') || userSetsRef.current.premiumOff;

    if (hintsNotAvailable) {
      closeMenu();
      delayPremiumMenu('menu');
    } else {
      activateHints();
    }

  }

  const handleFullScreen = () =>{

    const popupParams = {
      title: t('fullscreen.title'),
      message: t('fullscreen.text'),
      buttons: [
        { id: 'no', type: 'cancel', text: t('fullscreen.button_cancel') },
        { id: 'yes', type: 'default', text: t('fullscreen.button_confirm') },
      ],
      settings: [{ key: 'openBrowser', label: t('settings.open_browser_on_start') }],
    };

    popupParams.callback = (buttonId) => {
      if (buttonId === 'yes') { makeItFullScreen() }
    }
    setPopupMessage(popupParams)


  }


  const makeItFullScreen = () =>{

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('brw', '1');
    window.open(currentUrl.toString(), '_blank');

    setTimeout(() => {
      window.Telegram.WebApp.close();
    }, 100); 

    // () => window.Telegram.WebApp.openTelegramLink('http://t.me/CrocoDraw_Bot/open')
    // () => window.Telegram.WebApp.openLink('http://t.me/CrocoDraw_Bot/open')
    // () => window.Telegram.WebApp.openLink(window.location.href)
  }

  const showSettingsFun = ()=>{
    openMenu('settings', true);
  }

  const definition = game.definitions?.[game.word] || ' ';
  const [showFullDefinition, setShowFullDefinition] = useState(false);
  const toggleDefinition = () => {
    setShowFullDefinition(!showFullDefinition);
  }

  const renderDefinition = () => {
    if (!definition) return null;
    
    if (definition.length > 140 && !showFullDefinition) {
      return (
        <div className="definition-display">
          {definition.substr(0, 120).replace(/\s+\S*$/, "")}
          <span>... </span>
          <button className="inline-more-button" onClick={toggleDefinition}>{t('word.button_definition_more')}</button>
        </div>
      );
    } else if (definition.length > 400) {
      return (
        <div className="definition-display">
          {definition.substr(0, 400).replace(/\s+\S*$/, "")}
          <span>... </span>
        </div>
      );
    } else {
      return (
        <div className="definition-display">
          {definition}
        </div>
      );
    }
  };


  function wordAndHints () {

    return(
      <>
        {changingWord ? (
          <>
              {wordInputValue ? 
                (
                  <div className="button-row">
                   <button className="wide-button" 
                    onClick={handleInputSubmit}
                    // onMouseDown={(e) => e.preventDefault()} 
                    // onTouchStart={(e) => e.preventDefault()} 
                   >
                    <FaChevronRight style={{ marginRight: '5px', marginBottom: '3px', verticalAlign: 'middle'}} />
                    {wordInputValue}
                   </button>
                 </div>
                ) :
                (
                  <div className="button-row">
                    <button className="small-button" onClick={() => changeWord('previous')}>
                      {t('word.button_previous')}
                    </button>
                    <button className="small-button" onClick={() => changeWord('next')}>
                      {t('word.button_new')}
                    </button>
                 </div>

                )
              }

            { (
              <div className="button-row">
                <input
                  className="word-input-field input-field"
                  type="text"
                  value={wordInputValue}
                  onChange={(e) => setWordInputValue(e.target.value)}
                  onFocus={handleInputFocus}
                  onBlur={() => setIsInputFocused(false)}
                  placeholder={t('word.placeholder')}
                />
              </div>
            )}
            
            
          </>
        ) : (
          <button className="wide-button" onClick={()=>{
            setChangingWord(true)
            }}>
            {t('word.button_change_word')}
          </button>
        )}

        {!hintsEnabled ? (
          <button className="wide-button" onClick={activateHintsFun}>
            {t('word.button_hints')}
          </button>
        ) : (
          <div className="hints-container">
            {hints.map((hint, num) => (
              <button 
                key={num} 
                className={hint.opened ? "hint-active-button" : "hint-button"}
                onClick={()=>{openLetter(num)}}
                >
                {hint.letter}
              </button>
            ))}
          </div>
        )}
      </>
    )
    
  }

  const handleSaveImage = async () => {

    const popupParams = {
      title: t('save.title'),
      message: t('save.text'),
      buttons: [
        // { id: 'cancel', type: 'cancel', text: t('save.button_cancel') },
        { id: 'device', type: 'default', text: t('save.button_device') },
        { id: 'pm', type: 'default', text: t('save.button_pm') },
      ],
    };

    popupParams.callback = (buttonId) => {
      if (buttonId === 'device') { handleSaveHere() }
      if (buttonId === 'pm') {
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

        hostAction('savePng', null, (result)=>{
          window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

          if (result) {
            setPopupMessage({
              title: t('was_saved_pm.success_title'),
              message: t('was_saved_pm.success_message'),
            });
          } else {
            setPopupMessage({
              title: t('was_saved.error_title'),
              message: t('was_saved.error_message'),
            });
          }

        });
      }
    }
    setPopupMessage(popupParams)

  }

  const handleSaveHere = async () => {
    try {
      const renderResult = await render();
      
      if (renderResult.error) {
        console.error("Render error:", renderResult.error);
        setPopupMessage({
          title: t('was_saved.error_title'),
          message: t('was_saved.error_message') + JSON.stringify(renderResult.error),
        });
        return;
      }

      if (renderResult.empty) {
        setPopupMessage({
          title: t('was_saved.error_title'),
          message: t('was_saved.error_message') + 'empty',
        });
        return;
      }

      const { imagePng } = renderResult;
      let blob;

      // Check the type of imagePng and create the appropriate Blob
      if (imagePng instanceof Blob) {
        blob = imagePng;
      } else if (imagePng instanceof ArrayBuffer) {
        blob = new Blob([new Uint8Array(imagePng)], { type: 'image/png' });
      } else if (typeof imagePng === 'string' && imagePng.startsWith('data:image/png;base64,')) {
        const base64Data = imagePng.split(',')[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        blob = new Blob([byteArray], { type: 'image/png' });
      } else {
        // If it's none of the above, assume it's raw binary data
        blob = new Blob([imagePng], { type: 'image/png' });
      }
      
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `croco_${game.word}.png`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setPopupMessage({
        title: t('was_saved.success_title'),
        message: t('was_saved.success_message') + a.download,
      });
    } catch (error) {
      console.error("Save PNG error:", error);
      setPopupMessage({
        title: t('was_saved.error_title'),
        message: t('was_saved.error_message') + JSON.stringify(error),
      });
    }
  };

  return (
    <>
      <div className="overlay-background" onClick={()=>{
        setShowFullDefinition(false);
        closeMenu(); 
        }}></div>
      <div className="word-menu">
        <div className="word-display">{gameWordFun()}</div>

        {knowTheWord && renderDefinition()}
        {knowTheWord &&  gameInfoRef.current.mode !== 'sprint' && (
          wordAndHints()
        )}

        <button className="wide-button" onClick={handleFullScreen}>
          <FaExpand style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('fullscreen.button_start')}
        </button>

        <button className="wide-button" onClick={handleSaveImage}>
          <FaDownload style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle' }} />
          {t('save.button_save')}
        </button>

        <button className="gray-button" onClick={showSettingsFun}>
          <FaCog style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('settings.title')}
        </button>

      </div>
    </>
  );
}

export default WordChangeOverlay;
