
export const brushDefaults = {
  common: {
    smoothing: 10,
  },
  marker: {
  },
  spray: {
    softness: 0.3,
  },
  bristle: {
    opacity: 0.5,
  },
  feather: {
    minWidthStart: 0.01,
    taperStart: 0.5,
    minWidthEnd: 0.01,
    taperEnd: 0.5,
  },
  watercolor: {
    texture: 'waterAi',
    opacity: 0.7,
    spreading: true,
    textureScale: 1,
  },
  pencil: {
    texture: 'basic',
    opacity: 0.8,
    outline: true,
    textureScale: 1,
  },
  oil: {
    texture: 'fabric',
    opacity: 1,
    textureScale: 1,
  },
  rembrandt: {
    shadowStrength: 0.5,
    density: 0.5,
    tapering: 0.5,
  },
  dashed: {
    dashSize: 3,
    gapSize: 5,
  },
}


export const sources = {
  backup: {
    diagonal: {
      stroke: '/textures/test/diagonal-striped-brick.png',
    }
  },
  watercolor: {
    waterAi: {
      stroke: '/textures/water-ai-400.png',
      light: '/textures/water-ai-400-l.png',
    },
    naturalPaper: {
      stroke: '/textures/test/natural-paper.png',
      light: '/textures/test/natural-paper-l2.png',
    },
    paper: {
      stroke: '/textures/test/paper.png',
      light: '/textures/test/paper-l.png',
    },
    // paper1: {
    //   stroke: '/textures/test/paper-1.png',
    //   light: '/textures/test/paper-1.png',
    // },
    // paper2: {
    //   stroke: '/textures/test/paper-2.png',
    //   light: '/textures/test/paper-2.png',
    // },
  },
  pencil: {
    basic: {
      stroke: '/textures/pencil-150.png',
    },
    graphite: {
      stroke: '/textures/graphite-250.png',
    },
    // basic200: {
    //   stroke: '/textures/pencil-200.png',
    // },
  },
  oil: {
    fabric: {
      stroke: './textures/fabric.png',
    },
  },
};

const textures = {};

function loadTexture(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = src;
  });
}

export function loadAllTextures() {
  const texturePromises = [];

  for (const [brushName, brushValue] of Object.entries(sources)) {
    textures[brushName] = textures[brushName] || {};

    for (const [textureName, textureValue] of Object.entries(brushValue)) {
      textures[brushName][textureName] = textures[brushName][textureName] || {};

      for (const [type, src] of Object.entries(textureValue)) {
        if (type === 'sets') { continue; }
        const promise = loadTexture(src).then(image => {
          textures[brushName][textureName][type] = image;
        });
        texturePromises.push(promise);
      }
    }
  }

  return Promise.all(texturePromises);
}

export function getTexture(brush, base, type = 'stroke') {
  return textures[brush]?.[base]?.[type] || textures.backup.diagonal.stroke;
}

export function getAllTextures() {
  return textures;
}

loadAllTextures()