import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';
import { useCanvasSetup } from '../hooks/convy/useCanvasSetup';

// Создание контекста
const DrawingContext = createContext();

// Создание провайдера
export const DrawingProvider = ({ children }) => {

  const {
    canvasRef,
    contextRef,
    tempCanvasRef,
    tempContextRef,
    softCanvasRef,
    softContextRef,
    visibleSizeRef,
    canvasSideRef,
    canvasStyleSizeRef,

    canvasStyleDimensionsRef,
    canvasDimensionsRef,

    canvasScaleRef,

    updateCanvases,
    canvasRectRef,
  } = useCanvasSetup();

  const currentCanvasRectRef = useRef(canvasRectRef.current);
  const controlPanelOffsetRef = useRef(420);
  const controlPanelStuckRef = useRef(false);

  const isDrawingRef = useRef(false);
  const strokeTimeRef = useRef(0);
  const mouseIsPressedRef = useRef(false);
  const clickPositionRef = useRef({ x: 0, y: 0 });

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const mousePositionRef = useRef(mousePosition);

  const [drawingPosition, setDrawingPosition] = useState({ x: 0, y: 0 });

  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0; // Проверяем, есть ли поддержка тач-интерфейса
  const [mouseDetected, setMouseDetected] = useState(false); // Состояние для отслеживания событий мыши
  const touchStartLastTimeRef = useRef(0);
  const touchMoveLastTimeRef = useRef(0);
  const touchEndLastTimeRef = useRef(0);

  const [isZooming, setIsZooming] = useState(false);
  const [zoomFactor, setZoomFactor] = useState(1);
  const zoomFactorRef = useRef(zoomFactor);

  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const panOffsetRef = useRef({ x: 0, y: 0 });
  // const [zoomCenter, setZoomCenter] = useState({ x: 0, y: 0 });
  const [zoomMode, setZoomMode] = useState(false);

  const [rotation, setRotation] = useState(180);
  const rotationRef = useRef(rotation);

  const maxZoomFactorRef = useRef(20);


  // TEST
  const [eventPos, setEventPos] = useState({x: 0, y: 0});
  const [canvasPoint, setCanvasPoint] = useState({x: 0, y: 0});
  const [rzPoint, setRzPoint] = useState({x: 0, y: 0});

  const touch1Ref = useRef({ x: 0, y: 0 });
  const touch2Ref = useRef({ x: 0, y: 0 });
  const distanceRef = useRef(0);
  const zoomCenterRef = useRef({ x: 0, y: 0 });

  const isRenderingStrokesRef = useRef(false);
  const lastStrokeRenderTimeRef = useRef(0);

  useEffect(() => {
    zoomFactorRef.current = zoomFactor;
  }, [zoomFactor]);
  
  useEffect(() => {
    panOffsetRef.current = panOffset;
  }, [panOffset]);

  return (
    <DrawingContext.Provider value={{

      canvasRef,
      contextRef,
      tempCanvasRef,
      tempContextRef,

      canvasRectRef,
      currentCanvasRectRef,
      controlPanelOffsetRef,
      controlPanelStuckRef,

      softCanvasRef,
      softContextRef,
      visibleSizeRef,
      canvasStyleSizeRef,
      canvasSideRef,

      canvasStyleDimensionsRef,
      canvasDimensionsRef,

      canvasScaleRef,
      updateCanvases,
  
      isDrawingRef,
      strokeTimeRef,
      mouseIsPressedRef,
      clickPositionRef,
      mousePosition, setMousePosition,
      mousePositionRef,
      drawingPosition, setDrawingPosition,
      isTouchDevice,
      mouseDetected, setMouseDetected,
      touchStartLastTimeRef,
      touchMoveLastTimeRef,
      touchEndLastTimeRef,
  
      isZooming, setIsZooming,
      zoomFactor, setZoomFactor,
      zoomFactorRef,
      panOffset, setPanOffset,
      panOffsetRef,
      zoomCenterRef,

      zoomMode, setZoomMode,
      maxZoomFactorRef,

      isRenderingStrokesRef,
      lastStrokeRenderTimeRef,

      rotation, setRotation,
      rotationRef,

      eventPos, setEventPos,
      canvasPoint, setCanvasPoint,
      rzPoint, setRzPoint,



      touch1Ref,
      touch2Ref,
      distanceRef,


  
    }}>
      {children}
    </DrawingContext.Provider>
  );
};

export { DrawingContext };

// Хук для использования контекста
export const useDrawingContext = () => useContext(DrawingContext);
