import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

function ModeInfoOverlay({
  gameContext,
}) {

  const { t } = useTranslation();

  const {
    gameInfoRef,
    setShowModeHelp,
  } = gameContext;

  const modeHelp = gameInfoRef.current?.modeHelp;

  return (
    <>
      <div className="overlay-background" onClick={() => setShowModeHelp(false)}></div>

      <div className="premium-menu touch-active">
        <div className="premium-item">
            <div className="feature-info">
              <span className="feature-name">{t(`mode.${modeHelp}.name`)}</span>
                <p className="feature-description">
                  <Trans i18nKey={`mode.${modeHelp}.about`}>
                    {t(`mode.${modeHelp}.about`)}
                  </Trans>
                </p>
            </div>
            <button className="wide-button" onClick={() => {
              setShowModeHelp(false);
            }}>
              {t('settings.understand')}
            </button>
          </div>
      </div>
    </>
  );
}

export default ModeInfoOverlay;
