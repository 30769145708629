// PremiumMenu.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

function PremiumInfo({ 
  gameContext,
 }) {

  const { 
    userSetsRef, 
    saveUserSets, 
    delayPremiumMenu,
    showPremiumInfo, setShowPremiumInfo,
   } = gameContext;

  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState(null);

  return (
    <>
      <div className="overlay-background" onClick={() => setShowPremiumInfo(null)}></div>

      <div className="premium-menu touch-active">
        <div key={showPremiumInfo} className="premium-item">
            <div className="feature-info">
              <span className="feature-name">{t(`feature.${showPremiumInfo}.name`)}</span>
                <p className="feature-description">
                  <Trans i18nKey={`feature.${showPremiumInfo}.description`}>
                      {t(`feature.${showPremiumInfo}.description`)}
                  </Trans>
                </p>
            </div>
            <button className="wide-button" onClick={() => {
              setShowPremiumInfo(null);
              delayPremiumMenu('menu');
            }}>
              {t('settings.premium')}
            </button>
          </div>
      </div>
    </>
  );
}

export default PremiumInfo;