import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext } from '../../contexts/GameContext';
import { useDrawingContext } from '../../contexts/DrawingContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useHistory = () => {

  const { t } = useTranslation();

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const historyContext = useHistoryContext();
  const brushContext = useBrushContext();

  const {
    imageCache,

    gameInfoRef,
    socketRef,
    gameId,
    groupId,
    saveDrawing,
    activeUserIdRef,
    moderatorIdRef,
    connectionStatusRef,

    showPopupMessage,
    showTemporaryHint,

    appleHaptic,
  } = gameContext;

  const {
    canvasSideRef,
    canvasDimensionsRef,
    isRenderingStrokesRef,

    isDrawingRef,

  } = canvasContext;

  const {
    userStrokesRef,
    setRedrawer,
  } = historyContext;

  const {
    lastColorsRef,

    brushColorRef,
    eraserColorRef,
  } = brushContext;

  const undoLastAction = () => {

    if (isRenderingStrokesRef.current) { return; }

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    if (!myStrokes) { return; }

    appleHaptic('medium');

    const actualHistory = myStrokes.filter(stroke=> !stroke.cancelled);

    const lastStroke = actualHistory.pop();
    const prevStroke = actualHistory.pop();

  
    // Если такой мазок найден, удаляем его из истории
    if (lastStroke) {
      if (gameInfoRef.current.pngTime?.[lastStroke.time]) {
        confirmUndoReload(lastStroke);
        return;
      }
      if (countRenderDifficulty(lastStroke) > 10) {
        confirmUndoReload(lastStroke);
        return;
      }

      lastStroke.cancelled = Date.now();
      if (prevStroke) { delete prevStroke.hidden; }

      setRedrawer(i => i + 1);
      saveHistory ()
    }

  }

  const redoLastAction = () => {

    if (isRenderingStrokesRef.current) { return; }

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    if (!myStrokes) { return; }

    appleHaptic('medium');

    const cancelledHistory = myStrokes.filter(stroke=> stroke.cancelled)
    .sort((a, b)=> b.cancelled - a.cancelled);
    const firstStroke = cancelledHistory.shift();

    const actualHistory = myStrokes.filter(stroke=> !stroke.cancelled);
    const lastStroke = actualHistory.pop();
  
    // Если такой мазок найден, удаляем его из истории
    if (firstStroke) {
      delete firstStroke.cancelled;

      if (lastStroke?.shapeId && lastStroke.shapeId === firstStroke.shapeId) { lastStroke.hidden = true; }

      setRedrawer(i => i + 1);
      saveHistory ()
    } else {
      showTemporaryHint(t('tooltip.no_more_redo'), { force: true })
    }

  }




  const confirmUndoReload = (lastStroke) => {

    if (isRenderingStrokesRef.current) { return; }

    // window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('error')

    const popupParams = {
      type: 'destructive',
      title: t('undo_reload.title'),
      message: t('undo_reload.text'),
      buttons: [
        { id: 'cancel', type: 'cancel', text: t('undo_reload.button_cancel') },
        { id: 'confirm', type: 'destructive', text: t('undo_reload.button_confirm') },
      ]
    };

    popupParams.callback = (buttonId) => {
      if (buttonId === 'confirm') { 
        lastStroke.cancelled = Date.now();
        saveHistory ()
        setTimeout(() => { window.location.reload(); }, 200);
         }
    }
    showPopupMessage(popupParams)
  }

  const confirmClearCanvas = () => {

    if (isRenderingStrokesRef.current) { return; }

    // window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('error')

    const ClearCanvasContent = ({ text, colors, onColorClick }) => {
      return (
        <>
          <span 
            className="message-popup-text"
            dangerouslySetInnerHTML={{ __html: text || '' }}
          />
          <div style={{ 
            display: 'flex', 
            gap: '13px',
            marginBottom: '10px', 
            marginRight: '10px', 
            justifyContent: 'flex-end',
            marginLeft: 'auto', //
            }}>
            {colors.map((color, index) => (
              <div
                key={index}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: color,
                  cursor: 'pointer',
                  border: '0.5px solid #cccc'
                }}
                onClick={() => onColorClick(color)}
              />
            ))}
          </div>
        </>
      );
    };

    const colorsArr = [
      brushColorRef.current,
      '#ffffff',
      '#000000FF',
      eraserColorRef.current,
    ];
    const uniqueColorsArr = [...new Set(colorsArr)];

    const popupParams = {
      title: t('clear.title'),
      // message: t('clear.text'),
      content: <ClearCanvasContent 
        text={t('clear.text')} 
        colors={uniqueColorsArr} 
        onColorClick={(color)=>{
          clearCanvas(color);
          showPopupMessage(null);
        }} 
      />,
      buttons: [
        { id: 'cancel', type: 'cancel', text: t('clear.button_cancel') },
        { id: 'clear', type: 'destructive', text: t('clear.button_confirm') },
      ]
    };

    popupParams.callback = (buttonId, color) => {
      if (buttonId === 'clear') { clearCanvas(color); }
    }
    showPopupMessage(popupParams)

  }
  
  const clearCanvas = (color) => {

    const clearStroke = {
        type: 'clear',
        time: Date.now(),
    };

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    myStrokes.push(clearStroke);

    if (color && color !== eraserColorRef.current) {
      const backgroundStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'background',
        color,
      }
      myStrokes.push(backgroundStroke);
    } else if (eraserColorRef.current && eraserColorRef.current !== '#ffffff') {
      const backgroundStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'background',
        color: eraserColorRef.current,
      }
      myStrokes.push(backgroundStroke);
    }

    appleHaptic('light');

    setRedrawer(i => i + 1);
    saveHistory ()
  };
  


  function countRenderDifficulty (lastStroke) {

    const needToRender = prepareStrokesForCount(lastStroke);

    const strokeTypes = {
      special: 0,
      fill: 0,
    };

    for (let stroke of needToRender) {
      strokeTypes[stroke.type] = strokeTypes[stroke.type] || 0;
      strokeTypes[stroke.type] ++;

      if(['feather', 'oil', 'pencil', 'blur', 'noise', 'watercolor', 'bristle', 'rembrandt', 'test'].includes(stroke.brush)
      || stroke.effect
      ) { strokeTypes.special ++; }
    }

    const difficulty = needToRender.length / 500 + strokeTypes.fill / 2 + strokeTypes.special / 10;

    return difficulty;
    
  }

  
  function prepareStrokesForCount (lastStroke) {

    let actualUserStrokes = userStrokesRef.current;
    const allStrokes = Object.values(actualUserStrokes).flat();
    const combinedStrokes = allStrokes.filter(stroke => {
      return !stroke.cancelled && stroke.time !== lastStroke.time;
    }).sort((a, b) => a.time - b.time);

    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');
    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);

    const lastCacheIndex = strokesToRender.map(stroke => {
      const cachedData = imageCache.current.get(stroke.time);
      if (cachedData) { return true } else { return false; }
    }).lastIndexOf(true);

    const needToRender = strokesToRender.slice(Math.max(lastCacheIndex, 0));
    return needToRender;

  }


  function saveHistory () {

    const myHistory = userStrokesRef.current[activeUserIdRef.current];
    const historyToSave = myHistory.filter(stroke=>!stroke.cancelled && !stroke.hidden);

    saveDrawing({
      side: canvasSideRef.current,
      dimensions: canvasDimensionsRef.current,

      socket: socketRef.current, 
      colors: lastColorsRef.current,
      strokes: historyToSave, 
      gameId, 
      groupId, 
      userId: activeUserIdRef.current,
      moderId: moderatorIdRef.current,
      connectionStatusRef,
    }); 
    
  }


  return {
    undoLastAction,
    redoLastAction,
    confirmClearCanvas,
    clearCanvas,
    saveHistory,
  };
};

