import React, { useRef, useState, useEffect, } from 'react';
import { FaPencilRuler, FaCog } from 'react-icons/fa';
import { HexAlphaColorPicker } from "react-colorful";
import './CustomizeBrush.css';
import { useTranslation } from 'react-i18next';
import BrushSizeControl from '../tools/BrushSizeControl'; 


function CustomizeBrush({ 
  gameContext, 
  brushContext, 
  drawingContext,
 }) {

  const { t } = useTranslation();

  const brushViews = {
    dashed: {
      lineWidth: 5,
    }
  }

  const brushParams = {
    common: [
      { type: 'slider', key: 'smoothing', label: t('customize.prop.smoothing'), min: 0, max: 50, step: 1 },
    ],
    plain: [
    ],
    spray: [
      { type: 'slider', key: 'softness', label: t('customize.prop.softness'), min: 0, max: 0.5, step: 0.01 },
    ],
    feather: [
      { type: 'toggle', key: 'speedDependence', label: t('customize.prop.speed_dependence') },
      { type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperStart', label: t('customize.prop.taper_start'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperEnd', label: t('customize.prop.taper_end'), min: 0, max: 1, step: 0.01 },
    ],
    bristle: [
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.01 },
    ],
    pencil: [
      { type: 'toggle', key: 'outline', label: t('customize.prop.outline') },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
    ],
    watercolor: [
      { type: 'toggle', key: 'spreading', label: t('customize.prop.spreading') },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
    ],
    rembrandt: [
      { type: 'toggle', key: 'cutEdges', label: t('customize.prop.cut_edges') },
      { type: 'slider', key: 'tapering', label: t('customize.prop.tapering'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'density', label: t('customize.prop.density'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'shadowStrength', label: t('customize.prop.shadow_strength'), min: 0, max: 1, step: 0.01 },
    ],
    dashed: [
      { type: 'slider', key: 'dashSize', label: t('customize.prop.dash_size'), min: 0, max: 20, step: 0.1 },
      { type: 'slider', key: 'gapSize', label: t('customize.prop.gap_size'), min: 0, max: 20, step: 0.1 },
    ],
    // Add more brush types and their settings as needed
  };
  
  const { 
    userSetsRef, 
    saveUserSets,

    menuParamRef,
    openMenu,
    closeMenu,

    drawMethodsRef,

    setPopupMessage,
    forceRender,

   } = gameContext;
  const { 
    lastActionTimeRef,
    lineWidth,
    color, setTheColorFun,
    softness,
    setBrushType,
    brushSetsFun,

    getTexture, 
    sources, 
    brushDefaults,

    filterClosePoints,
   } = brushContext;
  const { 
    canvasScaleRef,
   } = drawingContext;

  const canvasRef = useRef(null);
  const isUpdatingPreviewRef = useRef(false);
  
  const [userColorView, setUserColorView] = useState(false);


  const brushKey = menuParamRef.current.brush;
  const defaultBrushLink = userSetsRef.current[brushKey]?.clone || brushKey;

  const currentBrushSettings = Object.assign({}, brushDefaults[defaultBrushLink] || {}, userSetsRef.current[brushKey] || {});
  
  const currentBrushParams = brushParams[defaultBrushLink] || [];
  const currentBrushView = brushViews[defaultBrushLink] || {};

  const brushTextures = sources[defaultBrushLink] || {};
  const textureNames = Object.keys(brushTextures);
  const hasTextures = textureNames.length > 0;

  const brushIsCustomized = Object.keys(userSetsRef.current[brushKey] || {}).length;
  const isUserBrush = userSetsRef.current.savedBrushes?.includes(brushKey);

  const updatePreview = async (settings) => {

    if (isUpdatingPreviewRef.current) { return; }
    const canvas = canvasRef.current;

    if (canvas) {
      isUpdatingPreviewRef.current = true;

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      const sets = currentBrushSettings;
      const commonSets = brushSetsFun('common');
      let points = [[35.2,27.3],[36.7,28.7],[38.1,30.2],[46.3,35.5],[51.2,37.9],[72,45.2],[83.6,47.6],[102.4,49],[108.7,49],[134.4,45.7],[152.3,39.4],[162.9,34]];

      const smooth = (!sets.speedDependence && !sets.spreading);
      if (smooth) {
        points = filterClosePoints?.(points, Math.round(commonSets.smoothing)) || points;
      }

      const currentLineWidth = Math.min(60, Math.max(36, lineWidth))

      const strokeTemplate = {
        type: 'stroke',
        color: color,
        lineWidth: currentBrushView.lineWidth || currentLineWidth,
        softness,
        brush: defaultBrushLink,
        sets,
      }

      const strokes = [
        {
          points:  points.map(point=>[point[0] + 25, point[1] + 30]),
          time: 0,
        },
        {
          points: points.map(point=>[point[0], point[1] + 10]),
          time: 0 + 1,
        },
      ].map(stroke=> Object.assign({}, strokeTemplate, stroke))

      for (let stroke of strokes) {
        await drawMethodsRef.current?.drawStroke(stroke, context)
      }

      isUpdatingPreviewRef.current = false;


    }
  };

  useEffect(() => {
    updatePreview(currentBrushSettings);
  }, [currentBrushSettings, lineWidth]);

  const drawTextureCanvas = (textureCanvas, brushKey, textureName) => {
    if (!textureCanvas) return;
  
    const texture = getTexture(brushKey, textureName, 'stroke');
    if (!texture) {  return; }
  
    const context = textureCanvas.getContext('2d');
  
    // Очистить канвас
    context.clearRect(0, 0, textureCanvas.width, textureCanvas.height);

    // Вычислить пропорции для отображения текстуры в реальном размере
    const drawWidth = texture.width * canvasScaleRef.current;
    const drawHeight = texture.height * canvasScaleRef.current;
  
    // Нарисовать текстуру
    context.drawImage(texture, 0, 0, drawWidth, drawHeight);
  
    // Закрасить в черный цвет
    context.globalCompositeOperation = 'source-in';
    context.fillStyle = 'black';
    context.fillRect(0, 0, textureCanvas.width, textureCanvas.height);
  
    // Сбросить composite operation
    context.globalCompositeOperation = 'source-over';
  };
  

  const handleSettingChange = (key, value) => {

    const defaultValue =  brushDefaults?.[brushKey]?.[key] || 1;
    if (!isNaN(value) && value / defaultValue < 1.05 && value / defaultValue > 0.95) {
      value = defaultValue;
    }

    let newBrushSettings = { ...currentBrushSettings, [key]: value };
    for (let param in newBrushSettings) {
      if (newBrushSettings[param] === brushDefaults?.[defaultBrushLink]?.[param]) {
        delete newBrushSettings[param];
      }
    }
    saveUserSets({ [brushKey]: newBrushSettings });

  };

  const handleOverlayClick = () =>{
    if (lastActionTimeRef.current < Date.now() - 300) {
      closeMenu(); 
    }
  }

  const handleSaveNewBrush = () => {

    setPopupMessage({
      title: t('customize.save_brush_title'),
      message: t('customize.save_brush_text'),
      input: {
        placeholder: t('customize.save_brush_placeholder'),
      },
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'submit', type: 'submit', text: t('customize.button_submit')},
      ],

      callback: (buttonId, inputValue) => {
        if (buttonId === 'submit') {
          
          window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

          const savedBrushes = userSetsRef.current.savedBrushes || [];
          let i = 1;
          let newBrushKey = `my_${defaultBrushLink}_${i}`;
          while (savedBrushes.includes(newBrushKey)) {
            i ++;
            newBrushKey = `my_${defaultBrushLink}_${i}`;
          }

          savedBrushes.push(newBrushKey);

          const newBrushSettings = userSetsRef.current[brushKey]

          saveUserSets({ 
            [brushKey]: null,
            savedBrushes,
            [newBrushKey]: {
              ...newBrushSettings,
              clone: defaultBrushLink,
              label: inputValue,
            }
          })
          openMenu('customizeBrush', {brush: newBrushKey, layer: true});
          setBrushType(newBrushKey);
  
        }
      }
    });

  }
  const handleDeleteUserBrush = () => {

    const label = userSetsRef.current[brushKey]?.label || ''

    setPopupMessage({
      title: t('customize.delete_brush_title', { label }),
      message: t('customize.delete_brush_text', { label }),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'delete', type: 'destructive', text: t('customize.button_delete_confirm')},
      ],

      callback: (buttonId) => {
        if (buttonId === 'delete') {

          let savedBrushes = userSetsRef.current.savedBrushes || [];
          savedBrushes = savedBrushes.filter(currentKey=>currentKey !== brushKey)
          saveUserSets({ 
            savedBrushes,
            [brushKey]: null,
          })
          openMenu('brush');
  
        }
      }
    });

  }

  const handleResetBrush = () => {

    const label = userSetsRef.current[brushKey]?.label || ''

    setPopupMessage({
      title: t('customize.reset_brush_title', { label }),
      message: t('customize.reset_brush_text', { label }),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'reset', type: 'destructive', text: t('customize.button_reset_confirm')},
      ],

      callback: (buttonId) => {
        if (buttonId === 'reset') {
          const resetBrushSettings = userSetsRef.current[brushKey]
          saveUserSets({ 
            [brushKey]: resetBrushSettings?.clone ? {
              label: resetBrushSettings.label,
              clone: resetBrushSettings.clone,
            } : null,
          })
          forceRender();
        }
      }
    });

  }

  const renderSetting = (setting) => {
    
    switch (setting.type) {
      case 'toggle':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <input
                type="checkbox"
                checked={currentBrushSettings[setting.key] || false}
                onChange={(e) => handleSettingChange(setting.key, e.target.checked)}
              />
              <span>{t(setting.label)}</span>
            </label>
          </div>
        );

      case 'slider':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <span>{t(setting.label)}: {currentBrushSettings[setting.key]}</span>
            </label>
            <input
              type="range"
              min={setting.min}
              max={setting.max}
              step={setting.step}
              value={currentBrushSettings[setting.key] || setting.min}
              onChange={(e) => handleSettingChange(setting.key, Number(e.target.value))}
              className="customize-brush-slider touch-active"
            />
          </div>
        );
      default:
        return null;
    }
  };


  const TextureSelector = () => (
    <div className="texture-selection">
            <span className="section-title">{t('customize.prop.texture', { textureName: currentBrushSettings.texture })}</span>
            <div className="texture-options">
              {textureNames.map((textureName) => (
                <div
                  key={textureName}
                  className={`texture-option ${currentBrushSettings.texture === textureName ? 'active' : ''}`}
                  onClick={() => handleSettingChange('texture', textureName)}
                >
                  <canvas
                    ref={(textureCanvas) => drawTextureCanvas(textureCanvas, defaultBrushLink, textureName)}
                    width={50}
                    height={50}
                  />
                </div>
              ))}
            </div>
          </div>
  );


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="customize-brush-menu">
        <span className="customize-brush-title">
          {currentBrushSettings.label ||  t(`brush.${brushKey}.name`)}
        </span>
        <div className = 'customize-brush-preview-row'>
          <div 
            onClick={()=>{setUserColorView(true)}}
            style={{ 
            width: `${250 * canvasScaleRef.current}px`,
            height: `${120 * canvasScaleRef.current}px`,
            overflow: 'hidden',
          }}>
            <canvas 
              ref={canvasRef} 
              width="250" 
              height="120" 
              style={{ 
                transform: `scale(${canvasScaleRef.current})`,
                transformOrigin: 'top left',
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
            />
          </div>
          <BrushSizeControl 
            style = {{
              right: '-50px',
            }}
            gameContext={gameContext}
            brushContext={brushContext} 
            canvasContext={drawingContext}
            icon={<FaPencilRuler />}
            hideButton = {true}
            defaultBrushSize = {36}
          />
        </div>
        {currentBrushParams.map(renderSetting)}

        {hasTextures && ( <TextureSelector/>)}


        <button 
          className="wide-button" 
          onClick={handleResetBrush}
          style={{
            opacity: brushIsCustomized ? 1 : 0.5,
          }}
          >
            {t('customize.button_reset')}
          </button>

        { isUserBrush && (
            <button className="wide-button" onClick={handleDeleteUserBrush}>
              {t('customize.button_delete')}
            </button>
          ) }

        { !isUserBrush && brushKey !== 'common' && (
            <button 
              className="wide-button" 
              onClick={handleSaveNewBrush}
              style={{
                opacity: brushIsCustomized ? 1 : 0.5,
              }}
              >
                {t('customize.button_save')}
            </button>
          ) 
        }

        <div className="button-row">
          <button className="small-gray-button" onClick={()=>{openMenu('brush')}}>
            {t('customize.button_back')}
          </button>
          <button className="small-gray-button" onClick={()=>{closeMenu()}}>
            {t('customize.button_close')}
          </button>
        </div>

        { userColorView && (
          <>
            <div className="overlay-background" style={{zIndex: 1400}} onClick={()=>setUserColorView(false)}></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker 
                color={color} 
                onChange={(newColor)=>{
                  setTheColorFun(newColor);
                }}
              />
              <button className="wide-button" onClick={()=>setUserColorView(false)} style={{marginTop: '10px'}}>
                {t('settings.set_color')}
              </button>
            </div>
          </>
      ) }
      </div>
    </>
  );
}

export default CustomizeBrush;