// SavedColorsPalette.js

import React, { useState, useRef } from 'react';
import chroma from 'chroma-js';
import './SavedColorsPalette.css';  // Импортируем CSS файл

function SavedColorsPalette({ 
  gameContext,
  brushContext,
  onClose,
 }) {

  const {
    userSetsRef,
    saveUserSets,
    forceRender,
    delayPremiumMenu,
    premiumFeaturesRef,
  } = gameContext;
  
  const {
    color, 
    setTheColorFun, 
    premiumGrayscale,
    setPremiumGrayscale,
  } = brushContext;

  const pressTimeout = useRef(null);
  const pressTime = useRef(0);
  const pressColor = useRef('#00000000');

  function getBoxShadow(color) {
    const chromaColor = chroma(color);
    const lightness = chromaColor.luminance();
    return (lightness > 0.9 || chromaColor.alpha() < 0.1) ? '0 0 0 1px rgba(30, 30, 30, 0.3)' : '';
  }

  const convertColorToGrayscale = (savedColor=>{
    const alpha = chroma(savedColor).alpha(); // Сохраняем прозрачность текущего цвета
    let rgb = chroma(savedColor).rgb();
    let average = (rgb[0] + rgb[1] + rgb[2]) / 3;
    savedColor = chroma([average, average, average]).alpha(alpha).hex();
    return savedColor;
  })

  const startPress = ({savedColor, i}) => {

    clearTimeout(pressTimeout.current); 

    let now = Date.now();
    if (pressTime.current > now - 500) {return;}
    pressTime.current = now;
    pressColor.current = savedColor;

    if (premiumGrayscale) {savedColor = convertColorToGrayscale(savedColor)}
    setTheColorFun(savedColor);

    pressTimeout.current = setTimeout(() => removeSavedColor(i), 700); 

  };

  const stopPress = () => {
    clearTimeout(pressTimeout.current); 
    if (Date.now() - pressTime.current < 700) {
      // onClose();
    } 
  };


  const removeSavedColor = (index) => {
    const newColors = [...userSetsRef.current.colors.slice(0, index), ...userSetsRef.current.colors.slice(index + 1)];
    saveUserSets({ colors: newColors });  
    forceRender();  
  
    window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success');
  };


  const addCurrentColor = () => {

    if (!premiumFeaturesRef.current.includes('saveColors') 
    || userSetsRef.current.premiumOff) {
      delayPremiumMenu('menu');
      return;
    }

    const newColors = [color, ...userSetsRef.current.colors];
    saveUserSets({colors: newColors});
    forceRender();
  };

  const rows = [[], [], [], []];
  const showColors = userSetsRef.current.colors.slice(0, 20);
  showColors.forEach((savedColor, i) => {
    const rowIndex = Math.floor(i / 5);
    if (rows[rowIndex]) {
      rows[rowIndex].unshift({savedColor, i});
    }
  });

  return (
    <div className="saved-palette-container">
      <div className="saved-palette-rows-container">
        {rows.map((row) => (
          <div className="saved-palette-row" style = {{
              filter: premiumGrayscale ? 'grayscale(100%)' : null,
            }}
          >
            {row.map(({savedColor, i}) => (
              <div key={i}
                onMouseDown={(nativeEvent) => {
                  if (nativeEvent.button === 2) {
                    removeSavedColor(i);
                  } else {
                    startPress({savedColor, i});
                  }
                }}
                onMouseUp={stopPress}
                onTouchStart={() => startPress({savedColor, i})}
                onTouchEnd={stopPress}
                style={{ backgroundColor: savedColor, boxShadow: getBoxShadow(savedColor) }}
                className="saved-palette-color" />
            ))}
          </div>
        ))}
      </div>
      <div onClick={addCurrentColor} className="saved-add-color">
        <svg className="saved-icon" viewBox="0 0 24 24">
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
        </svg>
      </div>
      {
        premiumFeaturesRef.current.includes('colors') && !userSetsRef.current.premiumOff && (
          <div className = {premiumGrayscale ? "premium-color-switch" : "premium-grayscale-switch"}
            onClick={()=>{ setPremiumGrayscale(isGraySlace=>!isGraySlace)}}
          />
        )
      }
      
    </div>
  );
}

export default SavedColorsPalette;
