// OpacityControl.js
import React, { useState, useRef, useEffect } from 'react';
import chroma from 'chroma-js';
import CircleSwitch from "./CircleSwitch/CircleSwitch.tsx";
import ColorIndicator from './ColorIndicator'; 

const OpacityControl = ({ 
  canvasContext,
  brushContext,
  drawMethods,

  icon,
  available,
 }) => {

    const {
      color, 
      setTheColorFun, 
      activeTool, 
      isChangingOpacity, setIsChangingOpacity,

    } = brushContext;

    const {
      changeTool, 
    } = drawMethods;

    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 
    const hasMoved = useRef(false);

    const startChangingOpacity = (event) => {
        setIsChangingOpacity(true);

        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;
    };

    useEffect(() => {
        const handlePointerMove = (event) => {
            if (!isChangingOpacity) return;
            const x = event.clientX || event.touches[0]?.clientX;
            const y = event.clientY || event.touches[0]?.clientY;

            // const diffY = y - pointerPosition.y;
            const diffY = pointerPosition.y - y;
            setPointerPosition({ x, y }); // Обновление позиции кружка
            
            if (diffY === 0) return;

            // Вычисление и изменение альфа-канала
            let newColor = chroma(color).alpha(Math.max(0, Math.min(1, chroma(color).alpha() - diffY * 0.005))).hex('rgba');
            setTheColorFun(newColor);

            hasMoved.current = true; // Установка при перемещении

        };

        const handlePointerUp = (event) => {
            setIsChangingOpacity(false);
            if (hasMoved.startTouchPoint && Date.now() - hasMoved.startTime < 350) {
              changeTool("effect", { openMenu: event?.button === 2 })
            }
        };

        if (isChangingOpacity) {
            document.addEventListener('mousemove', handlePointerMove);
            document.addEventListener('mouseup', handlePointerUp);
            document.addEventListener('touchmove', handlePointerMove);
            document.addEventListener('touchend', handlePointerUp);
        }

        return () => {
            document.removeEventListener('mousemove', handlePointerMove);
            document.removeEventListener('mouseup', handlePointerUp);
            document.removeEventListener('touchmove', handlePointerMove);
            document.removeEventListener('touchend', handlePointerUp);
        };
    }, [isChangingOpacity, color, setTheColorFun, changeTool]);

    return (
      <div>
        <div
            onContextMenu={()=>{
              changeTool("effect", { openMenu: true })
            }}
            onMouseDown={startChangingOpacity}
            onTouchStart={startChangingOpacity}
            style={{
                cursor: 'pointer', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px',
            }}
        >
            <CircleSwitch
									icon={icon}
                  available={available}
									isActive={activeTool === "effect"}
                  menu={true}
								/>
        </div>
        {isChangingOpacity && (
               <ColorIndicator
               canvasContext={canvasContext}
               brushContext={brushContext}
               
               position={pointerPosition}
               clickPosition={clickPosition}
             />
            )}
      </div>
    );
};

export default OpacityControl;