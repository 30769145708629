// Lobby.js

import React, { useEffect, useState } from 'react';
import chroma from 'chroma-js';

import './Lobby.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import useLobbyTelegram from './useLobbyTelegram'; 
import gatorImage from '../../images/lobby_gator.webp'; // Импортируем изображение


function Lobby() {

  useLobbyTelegram();

  const customTop = Math.min(window.innerHeight * 0.47, window.innerWidth * 0.78)
  document.documentElement.style.setProperty('--custom-top', `${customTop}px`);


  const menu = [
    {
      id: 'about',
    },
    {
      id: 'add', 
      button_url: 'http://t.me/DoodleGatorBot?startgroup=r--miniapp-miniapp-miniapp'
    },
  ]


  function ExternalLink ({ href, children }) {
    const handleClick = (event) => {
      event.preventDefault(); 
      if (window.Telegram?.WebApp?.openLink) {
        window.Telegram.WebApp.openLink(href);
      } else {
        window.open(href, '_blank');
      }
    };
  
    return (
      <a 
        href={href} 
        onClick={handleClick}
        style={{ color: 'var(--tg-theme-link-color)' }}
      >
        {children}
      </a>
    );
  }

  const { t } = useTranslation();

  return (
    <>
      <div className="lobby-menu" style = {{
        backgroundColor: '#97979735',
        // backgroundColor: blendColors('--tg-theme-bg-color', '272727', 0.2),
      }}>
        {/* <span className="lobby-menu-title">{t(`lobby.title`)}</span> */}
        {menu.map((plan) => (
          <div key={plan.id} className="lobby-item" style={plan.info_style}>
            <div className="lobby-info" >
              <div className="lobby-name">{t(`lobby.${plan.id}.name`)}</div>
                <p className="lobby-description" style={plan.description_style}>
                  <Trans 
                    i18nKey={`lobby.${plan.id}.description`} 
                    values={{
                      price: plan.price, 
                      full_price: plan.full_price 
                    }}
                    components={{ 
                      s: <s />,
                      a: <ExternalLink href={t(`lobby.${plan.id}.url`)} />
                     }}
                  />
                </p>
            </div>
            { plan.button_url &&
              (
                <button
                className="lobby-wide-button"
                onClick={() => window.open(plan.button_url, '_self')}
                style={plan.button_style}
              >
                <Trans
                  i18nKey={`lobby.${plan.id}.button_text`}
                  values={{ 
                    price: plan.price, 
                    full_price: plan.full_price 
                  }}
                  components={{ s: <s /> }}
                > 
                </Trans>
              </button>
              )
            }
          </div>
        ))}
      </div>
      <div className="gator-image-container">
        <img src={gatorImage} alt="My Image" className="gator-lobby-image" />
      </div>
    </>
  );
}

export default Lobby;