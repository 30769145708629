

const panelOffset = ({
  canvasContext,
})=>{

  const {
    // Refs
    canvasRef,
    zoomFactor,
    canvasRectRef,
    currentCanvasRectRef,
    controlPanelOffsetRef,
    controlPanelStuckRef,

  } = canvasContext;

  let controlButtonsStyle = { bottom: 0, opacity: 0 };

  if (canvasRef.current && canvasRectRef.current) {
    const rect = canvasRef.current.getBoundingClientRect();
    currentCanvasRectRef.current = rect;

    const windowHeight = window.innerHeight;

    if (
      (controlPanelStuckRef.current && zoomFactor > 1)
      || windowHeight - rect.bottom < 160
      ) {

      controlPanelStuckRef.current = true;
      controlPanelOffsetRef.current = windowHeight - 150;

    } else if (rect.bottom <= canvasRectRef.current.bottom && zoomFactor === 1) {

      controlPanelStuckRef.current = false;
      controlPanelOffsetRef.current = Math.min(rect.bottom + 0, windowHeight - 150);

    } else {

      controlPanelOffsetRef.current = Math.min(rect.bottom + 0, windowHeight - 150);

    }

    controlPanelOffsetRef.current = Math.max(controlPanelOffsetRef.current, canvasRectRef.current.bottom);
    
    controlButtonsStyle = {
      top: `${controlPanelOffsetRef.current}px`,
    }
  
  }

  return { controlButtonsStyle };

} 

export default panelOffset