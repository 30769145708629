// ResultsOverlay.js
import React from 'react';
import './ResultsOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';

function ResultsOverlay({ 
  game,
  canDraw, 
  setContinueDrawing,
 }) {

  const { t } = useTranslation();

  const results = game.results;

  const mainMessage = results.text || (results.moder ? 
  t('results.moder', { moderName: results.moder.first_name }) :
  results.pass ? 
  t('results.passed', { authorName: game.author.first_name }) :
  t('results.winner', { winnerName: results.winner?.first_name }));

  const stayButtonLabel = canDraw ? 
  t('results.continue') : 
  t('results.stay');

  const closeButtonLabel = t('results.close');

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'var(--tg-theme-bg-color)',
          opacity: 0.5,
          zIndex: 1100,
        }}
        onClick={() => setContinueDrawing(true)}
      ></div>
      <div className="results">
        <div className="results-text">{mainMessage}</div>
        {game.word ? (
          <div className="word-highlight">{game.word}</div>
        ) : null}

      <div className="button-container">
        <button 
          className="results-button" 
          onClick={() => setContinueDrawing(true)}>
          {stayButtonLabel}
        </button>
        <button 
          className="results-button lower-button" 
          onClick={window.Telegram.WebApp.close}>
          {closeButtonLabel}
        </button>
      </div>
      </div>
    </>
  );
}

export default ResultsOverlay;
