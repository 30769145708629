// brushLoader.js
let loadingStarted = false;

export const brushDefaults = {
  common: {
    smoothing: 5,
    magnifierButton: false,
    brushMagnifier: false,
    eraserMagnifier: false,
  },
  marker: {
  },
  outlined: {
    outlineSize: 5,
    outlineColor: '#000000FF',
    outlineOpacity: 1,
  },
  spray: {
    softness: 0.3,
  },
  bristle: {
    opacity: 0.5,
    wobble: 0.05,
  },
  feather: {
    speedDependence: false,

    edgeWidening: false,
    centralWidth: 0.4,
    
    minWidthStart: 0.01,
    taperStartPoint: 0.5,
    minWidthEnd: 0.01,
    taperEndPoint: 0.5,

    transparentEdges: false,
    opacityOnStart: 0,
    fadeStartPoint: 0.3,
    opacityOnEnd: 0,
    fadeEndPoint: 0.7,
  },
  watercolor: {
    texture: 'waterAi',
    opacity: 0.7,
    spreading: true,
    textureScale: 1,
  },
  pencil: {
    texture: 'basic',
    opacity: 0.8,
    outline: true,
    textureScale: 1,
  },
  oil: {
    speedDependence: true,
    
    minWidthStart: 0.3,
    taperStartPoint: 0.5,
    minWidthEnd: 0.3,
    taperEndPoint: 0.5,

    texture: 'fabric',
    opacity: 1,
    textureScale: 1,
  },
  rembrandt: {
    cutEdges: true,
    shadowStrength: 0.5,
    density: 0.5,
    tapering: 0.5,
  },
  dashed: {
    dashSize: 3,
    gapSize: 5,
  },
  neon: {
    neonSize: 25,
    opacity: 0.75,
  },
  sparkle: {
    sparkleDensity: 0.5,
    sparkleSize: 1, 
    sparkleDistributionPower: 0.8, 
  },
  filler: {
    tolerance: 5,
  },
  pixelate: {
    size: 20,
    sizeX: 20,
    sizeY: 20,
  },
}


export const sources = {
  backup: {
    diagonal: {
      stroke: '/textures/test/diagonal-striped-brick.png',
    }
  },
  watercolor: {
    waterAi: {
      stroke: '/textures/water-ai-400.png',
      light: '/textures/water-ai-400-l.png',
    },
    naturalPaper: {
      stroke: '/textures/test/natural-paper.png',
      light: '/textures/test/natural-paper-l2.png',
    },
    paper: {
      stroke: '/textures/test/paper.png',
      light: '/textures/test/paper-l.png',
    },
    // paper1: {
    //   stroke: '/textures/test/paper-1.png',
    //   light: '/textures/test/paper-1.png',
    // },
    // paper2: {
    //   stroke: '/textures/test/paper-2.png',
    //   light: '/textures/test/paper-2.png',
    // },
  },
  pencil: {
    basic: {
      stroke: '/textures/pencil-150.png',
    },
    graphite: {
      stroke: '/textures/graphite-250.png',
    },
    // basic200: {
    //   stroke: '/textures/pencil-200.png',
    // },
  },
  oil: {
    fabric: {
      stroke: './textures/fabric.png',
    },
  },
};

const textures = {};

function loadTexture(src) {
  window.logPerformance(`Texture loading STARTED: ${src}`)

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      window.logPerformance(`Texture loading FINISHED: ${src}`)
      resolve(image);
    };
    image.onerror = reject;
    image.src = src;
  });
}

export async function getTexture(brush, base, type = 'stroke') {
  if (textures[brush]?.[base]?.[type]) {
    return textures[brush][base][type];
  }

  const src = sources[brush]?.[base]?.[type];
  if (!src) {
    return textures.backup.diagonal.stroke;
  }

  const texture = await loadTexture(src);
  
  if (!textures[brush]) textures[brush] = {};
  if (!textures[brush][base]) textures[brush][base] = {};
  textures[brush][base][type] = texture;

  return texture;
}


// Неиспользуемая функция загрузки всех сразу текстур
export function loadAllTextures() {

  if (loadingStarted) { return; }
  loadingStarted = true;
  const texturePromises = [];

  for (const [brushName, brushValue] of Object.entries(sources)) {
    textures[brushName] = textures[brushName] || {};
    for (const [textureName, textureValue] of Object.entries(brushValue)) {
      textures[brushName][textureName] = textures[brushName][textureName] || {};
      for (const [type, src] of Object.entries(textureValue)) {
        if (type === 'sets') { continue; }
        const promise = loadTexture(src).then(image => {
          textures[brushName][textureName][type] = image;
        });
        texturePromises.push(promise);
      }
    }
  }
  return Promise.all(texturePromises);

}

