// Файл savePng.js

export async function updatePngCache (game, imageCache) {
  
  game.pngTime = {};
  let pngArray = game.png;


  if (!Array.isArray(pngArray)) { pngArray = [pngArray]; }

  for (let pngCache of pngArray) {
    try {
      const imageData = await PNGToImageData(pngCache.imagePng)
      imageCache.current.set(pngCache.id, imageData)  
      game.pngTime[pngCache.id] = true;
      // imageCache.current.set(+pngCache.id, imageData)   
    } catch (e) {
      console.error(e);
    }
  }

}


export function imageDataToPNG(imageData) {
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');
  ctx.putImageData(imageData, 0, 0);
  
  // Конвертируем канвас в строку base64 в формате PNG
  return canvas.toDataURL('image/png');
}

export function PNGToImageData(pngData) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      resolve(ctx.getImageData(0, 0, img.width, img.height));
    };
    img.onerror = reject;
    img.src = pngData;
  });
}