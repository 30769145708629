// useZoomPC
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrawingContext  } from '../../contexts/DrawingContext';

export const useZoomPc = ({
  getCanvasPoint,
  getCanvasViewPoint,
}) => {

  const canvasContext = useDrawingContext();
  const {
    canvasSideRef,
    canvasDimensionsRef,
    zoomFactor, setZoomFactor,
    panOffset, setPanOffset,

    zoomFactorRef,
    panOffsetRef,

    maxZoomFactorRef,
  
    mousePositionRef,
    mouseIsPressedRef,

    canvasScaleRef,

  } = canvasContext;

  const initialOffsetRef = useRef({x: 0, y: 0});
  const movingStartPointRef = useRef({x: 0, y: 0});


  const changeZoomPc = useCallback((delta, newZoomFactor) => {

    // const newZoomFactor = 2; 

    let coefficient = zoomFactorRef.current * 0.1 + 0.25;
    const changeAmount = (delta > 0 ? -coefficient : coefficient);

    newZoomFactor = newZoomFactor || zoomFactorRef.current + changeAmount; 
    newZoomFactor = Math.max(newZoomFactor, 1); 
    newZoomFactor = Math.min(newZoomFactor, maxZoomFactorRef.current);

    const canvasPoint = getCanvasPoint(mousePositionRef.current) ; // Текущая позиция мыши

    let newZoomOffset = {
      x: (canvasPoint.x * canvasScaleRef.current * (zoomFactorRef.current - newZoomFactor)) + panOffsetRef.current.x,
      y: (canvasPoint.y * canvasScaleRef.current * (zoomFactorRef.current - newZoomFactor)) + panOffsetRef.current.y,
    }

    if (newZoomFactor === 1) {
      newZoomOffset = {x: 0, y: 0}
    }
   
    // Ограничение panOffset
    setZoomFactor(newZoomFactor);

    const max = {
      x: 95,
      y: 95,
    }
    const min = {
      x: -95 -((canvasDimensionsRef.current.width) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
      y: -95 -((canvasDimensionsRef.current.height) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
    }

    newZoomOffset.x = Math.max(min.x, newZoomOffset.x)
    newZoomOffset.x = Math.min(max.x, newZoomOffset.x)
    newZoomOffset.y = Math.max(min.y, newZoomOffset.y)
    newZoomOffset.y = Math.min(max.y, newZoomOffset.y)

    setPanOffset(newZoomOffset);

  }, [setZoomFactor, setPanOffset, mousePositionRef]);


  const changeViewStart = useCallback(({nativeEvent}) => {
    
    movingStartPointRef.current = mousePositionRef.current;
    initialOffsetRef.current = panOffsetRef.current || {x: 0, y: 0};

  }, []);


  const changeViewMove = useCallback(({nativeEvent}) => {

    if (!nativeEvent.buttons) {return;}
    if (!movingStartPointRef.current) {return;}
    if (nativeEvent.target.closest('.allow-context-menu')) { return; }

    const newPointOffset = {
      x: (nativeEvent.clientX - movingStartPointRef.current.x) + initialOffsetRef.current.x,
      y: (nativeEvent.clientY - movingStartPointRef.current.y) + initialOffsetRef.current.y,
    }

    const max = {
      x: 95,
      y: 95,
    }
    const min = {
      x: -95 -((canvasDimensionsRef.current.width) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
      y: -95 -((canvasDimensionsRef.current.height) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
    }

    newPointOffset.x = Math.max(min.x, newPointOffset.x)
    newPointOffset.x = Math.min(max.x, newPointOffset.x)

    newPointOffset.y = Math.max(min.y, newPointOffset.y)
    newPointOffset.y = Math.min(max.y, newPointOffset.y)

    setPanOffset(newPointOffset);

  }, [setPanOffset, zoomFactorRef, mouseIsPressedRef.current]);


  const resetZoom = useCallback(() => {

    setZoomFactor(1);
    setPanOffset({
      x: 0,
      y: 0
    });

  }, [setZoomFactor, setPanOffset]);

  const simpleZoom = useCallback(({ nativeEvent }) => {

    if (!movingStartPointRef.current) {return;}

    if (
      movingStartPointRef.current.x === mousePositionRef.current.x
      && movingStartPointRef.current.y === mousePositionRef.current.y
      ) {

        let newZoomFactor =  zoomFactorRef.current;
        if (nativeEvent.button === 2) {
          newZoomFactor *= 0.5;
        } else if (nativeEvent.button === 1) {
          newZoomFactor = 1;
        } else {
          newZoomFactor *= 2;
        }

        newZoomFactor = Math.max(newZoomFactor, 1);
        changeZoomPc(null, newZoomFactor)
      }

  }, [changeZoomPc, mousePositionRef]);

  return {
    resetZoom,
    simpleZoom,
    changeZoomPc,
    changeViewStart,
    changeViewMove,
  };
};

