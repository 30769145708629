import { brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.marker;

export function drawPlainMarkerStroke({
  color,
  lineWidth,
  points,
  sets = {},
}, context) {

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  context.lineCap = 'round';
  context.lineJoin = 'round';

  // Рисуем сплошной штрих
  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  if (points.length === 1) {
    drawPoint(points, context, lineWidth, color);
  } else if (points.length === 2) {
    drawStraightLine (points, context)
  } else {
    drawStroke(points, context);
  }


}



function drawPoint (points, bufferCtx, lineWidth, color) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  bufferCtx.beginPath();
  bufferCtx.arc(
    point[0], 
    point[1], 
    lineWidth / 2, 
    0, 
    Math.PI * 2
    );
  bufferCtx.fillStyle = color;
  bufferCtx.fill();

}

function drawStraightLine (points, context) {

  let firstPoint = points[0];
  let secondPoint = points[1];

  context.beginPath();
  context.moveTo(firstPoint[0], firstPoint[1]);

  context.lineTo(
    secondPoint[0], 
    secondPoint[1],
  );
  context.stroke();
  
}



function drawStroke (points, bufferCtx) {
  
  bufferCtx.beginPath();
  bufferCtx.moveTo(points[0][0], points[0][1]);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + currentPoint[0]) / 2,
      (nextPoint[1] + currentPoint[1]) / 2
    ];
    bufferCtx.quadraticCurveTo(nextPoint[0], nextPoint[1], midPoint[0], midPoint[1]);
  }
  const lastPoint = points[points.length - 1]
  bufferCtx.lineTo(
    lastPoint[0], 
    lastPoint[1],
    );
  bufferCtx.stroke();
  
}

