
import { MdZoomIn } from 'react-icons/md'; // Иконка зума
import { AiOutlineClose } from 'react-icons/ai'; // Иконка крестика

function ControlButtons({
  canvasContext,
  drawMethods,
}) {

    const { 
      zoomFactor,
      zoomFactorRef,
     } = canvasContext;


    const { 
      resetZoom,
     } = drawMethods;

    return (
    <div>
      {zoomFactor > 1 && (
        <div style={{ 
          position: 'absolute',  // Абсолютное позиционирование для панели зума
          top: '2px', // Смещение вверх относительно кнопок
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          gap: '10px',
          padding: '5px',
          height: '23px',
          background: 'rgba(200, 200, 200, 0.3)',
          borderRadius: '12px',
          zIndex: 1100, // Убедитесь, что панель зума будет находиться над другими элементами
      }}>
          <MdZoomIn style={{ cursor: 'pointer'}} />
          <div style={{ 
              minWidth: '35px', // Минимальная ширина достаточная для большинства значений
              textAlign: 'center', // Центрирование текста внутри блока
              fontSize: '18px',
          }}>
            {Math.round(zoomFactorRef.current * 10) / 10}
          </div>
          <AiOutlineClose 
              style={{ cursor: 'pointer' }} 
              onClick={resetZoom} 
          />
      </div>
      )}
</div>
    );
}

export default ControlButtons;





