// useRedraw.js
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { createFill, drawFill } from './floodFill';
import { showZoomed, freeMemory } from './imageCached';

import { drawPlainMarkerStroke } from './brushes/plain';
import { drawDashedStroke } from './brushes/dashed';
import { drawSprayStroke } from './brushes/spray';
import { drawFeatherStroke } from './brushes/feather';
import { drawPencilStroke } from './brushes/pencil';
import { drawOilStroke } from './brushes/oil';
import { drawWatercolorStroke } from './brushes/watercolor';
import { drawBristleStroke } from './brushes/bristle';
import { drawRembrandtStroke } from './brushes/rembrandt';
import { drawBlurStroke } from './brushes/blur';
import { drawEffectStroke } from './brushes/effect';

export const useRedraw = () => {

  const { t } = useTranslation();

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    imageCache,
    imageDataToPNG,
    gameInfoRef,
    userSetsRef,

    strokesAreLoaded,

    isAppleDevise,
    setPopupMessage,

    forceRender,
    showTemporaryHint,

  } = gameContext;

  const {
    canvasDimensionsRef,

    contextRef,
    canvasRef,
    softCanvasRef,
    softContextRef,

    canvasScaleRef,
  
    zoomFactor, 
    zoomFactorRef,
    maxZoomFactorRef,
    isDrawingRef,

    isRenderingStrokesRef,
    lastStrokeRenderTimeRef,
  
  } = canvasContext;

  const {
    lineWidth,
    setVisibleLineWidth,
  } = brushContext;

  const {
    userStrokesRef,
    redrawer,
  } = HistoryContext;


  function drawBackground({ context, color }) {
    context.save();
    context.fillStyle = color;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.restore();
  }

  async function drawStroke(stroke, context) {

    const {
      points,
      brush,
      effect,
      sets = {},
    } = stroke;

    const softness = sets?.softness || stroke.softness || 0;

    if (brush === 'feather' && points.length >= 2) {
      return drawFeatherStroke(stroke, context);
    } else if (brush === 'pencil') {
      return drawPencilStroke(stroke, context);
    } else if (brush === 'oil') {
      return drawOilStroke(stroke, context);
    } else if (brush === 'watercolor') {
      return await drawWatercolorStroke(stroke, context);
    } else if (brush === 'bristle') {
      return drawBristleStroke(stroke, context);
    } else if (brush === 'rembrandt') {
      return drawRembrandtStroke(stroke, context);
    } else if (effect === 'blur' || brush === 'blur') {
      // return applyTestPixiBlur(context.canvas)
      return await drawBlurStroke(Object.assign({}, stroke, {
        usePixi: isAppleDevise(),
      }) , context);
    } else if (effect === 'noise' || brush === 'noise') {
      return await drawEffectStroke(Object.assign({}, stroke, {
        effect: 'noise',
      }), context);
    } else if (effect) {
      return await drawEffectStroke(stroke, context);
    } else if (brush === 'dashed') {
      return drawDashedStroke(stroke, context);
    }

    if (brush === 'spray' || softness > 0) {
      await drawSprayStroke(stroke, context, softContextRef.current, isAppleDevise())
    } else {
      drawPlainMarkerStroke(stroke, context);
    }
  }
  
  const redrawCanvas = async () => {

    const context = contextRef.current;
    const canvas = canvasRef.current;
    if (!context || !canvas) return;

    if (
      isRenderingStrokesRef.current 
      // || lastStrokeRenderTimeRef.current > Date.now() - 5000
      ) {return;}

    isRenderingStrokesRef.current = true;
    const renderStartTime = Date.now();
    let lastHintTime = 0;
  
    context.save(); // Сохраняем текущее состояние контекста
    context.setTransform(1, 0, 0, 1, 0, 0); // Сбрасываем трансформацию
    context.clearRect(0, 0, canvas.width, canvas.height); // Очищаем канвас
    context.restore(); // Восстанавливаем состояние контекста
    
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
  
    const needToRender = prepareRenderStrokes();
    
    let hasSpecialBrush = false;
    maxZoomFactorRef.current = 20;

    let i = 0;
    for (let stroke of needToRender) {

      lastStrokeRenderTimeRef.current = Date.now();
      console.log(i, stroke.type, stroke.brush);

      if (imageCache.current.get(stroke.time)) {
        showZoomed({
          stroke,
          canvas: canvasRef.current, 
          imageCache,
        })
      } else if (stroke.type === 'background') {
        drawBackground({ context, color: stroke.color })
      } else if (stroke.type === 'fill') {
        makeFill ({
          context: contextRef.current,
          stroke, 
        })
      } else if (stroke.type === 'stroke') {
        await drawStroke(stroke, context);

        if (stroke.softness > 0) { hasSpecialBrush = true; }
        if (
          ['feather', 'oil', 'pencil', 'blur', 'noise', 'watercolor', 'bristle', 'rembrandt', 'test'].includes(stroke.brush)
          || stroke.effect
          ) { hasSpecialBrush = true;}
      }
      i++;

      const currentTime = Date.now();

      if (currentTime - renderStartTime > 1000) {
        if (currentTime - lastHintTime > 1000) {
          lastHintTime = currentTime;
          showTemporaryHint(`⏳ Loading: ${i} / ${needToRender.length}`, {force: true, duration: 1000});
          setTimeout(() => {  forceRender();  }, 0);
          await new Promise(resolve=>setTimeout(resolve, 300));
        }
      }


    }

    const specialBrushCacheAmount = userSetsRef.current.moreCache ? 2 : 5;

    let amountForCache = hasSpecialBrush ? specialBrushCacheAmount : 50;
    if (needToRender.length >= amountForCache) {
      const lastStroke = needToRender[needToRender.length - 1];
      saveCache (context, lastStroke)
    }

    isRenderingStrokesRef.current = false;
    
  }; 


  function prepareRenderStrokes () {

    let actualUserStrokes = userStrokesRef.current;
    const allStrokes = Object.values(actualUserStrokes).flat();
    const combinedStrokes = allStrokes.filter(stroke => !stroke.cancelled).sort((a, b) => a.time - b.time);

    if (gameInfoRef.current?.mode === 'line') {
      const backgrounds = combinedStrokes.filter(stroke=>stroke.type === 'background');
      if (isDrawingRef.current) {return backgrounds}
      else {return combinedStrokes.length ? [...backgrounds, combinedStrokes.pop()] : backgrounds;}
    }

    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');
    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);

    const lastCacheIndex = strokesToRender.map(stroke => {
      const cachedData = imageCache.current.get(stroke.time);
      if (cachedData) { return true } else { return false; }
    }).lastIndexOf(true);

    const needToRender = strokesToRender.slice(Math.max(lastCacheIndex, 0));
    return needToRender;

  }

  function makeFill ({context, stroke}) {

        try {
          createFill({
            stroke,
            canvas: context.canvas, 
            imageCache,
          })
          saveCache(context, stroke)
          showZoomed({
            stroke,
            canvas: context.canvas, 
            zoomFactor,
            imageCache,
          })
        } catch (e) {
          console.error(e);
        }
    
  }

  async function prepareCache (strokes) {

    const fillTempCanvas = document.createElement('canvas');
    fillTempCanvas.width = canvasDimensionsRef.current.width;
    fillTempCanvas.height = canvasDimensionsRef.current.height;
    const fillTempContext = fillTempCanvas.getContext('2d');

    fillTempContext.save();
    fillTempContext.setTransform(1, 0, 0, 1, 0, 0); // Сбрасываем трансформацию
    fillTempContext.clearRect(0, 0, fillTempCanvas.width, fillTempCanvas.height); // Очищаем канвас
    fillTempContext.fillStyle = "white";
    fillTempContext.fillRect(0, 0, fillTempCanvas.width, fillTempCanvas.height);
    fillTempContext.restore();
  
    // Рендерим штрихи с учетом масштаба и смещения

    for (let stroke of strokes) {

      
      if (imageCache.current.get(stroke.time)) {
        showZoomed({
          stroke,
          canvas: fillTempCanvas, 
          startX: stroke.x, 
          startY: stroke.y, 
          zoomFactor: 1,
          panOffset: {x: 0, y: 0},
          imageCache,
        })
      } else if (stroke.type === 'background') {
        drawBackground({ context: fillTempContext, color: stroke.color })
      } else if (stroke.type === 'fill') {
        createFill({
          stroke,
          canvas: fillTempCanvas, 
          imageCache,
        })
      } else if (stroke.type === 'stroke') {
        await drawStroke(stroke, fillTempContext);
      }

    }

    const lastStroke = strokes[strokes.length - 1];
    const { imageData } = saveCache (fillTempContext, lastStroke);

    return {
      lastStroke,
      imageData,
    }

  };


  function saveCache (context, lastStroke) {

    const canvas = context.canvas;
    let imageData = imageCache.current.get(lastStroke.time);
    if (!imageData) {
      imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      imageCache.current.set(lastStroke.time, imageData)
    }
    freeMemory ({imageCache, lastStrokeTime: lastStroke.time});

    return { imageData, lastStroke }
    
  }

  async function render () {

    try {

      const needToRender = prepareRenderStrokes({render: true});
      if (!needToRender[0]) { return { empty: true }; }

      const { lastStroke, imageData } = await prepareCache(needToRender);
      const imagePng = imageDataToPNG(imageData);
      return {
        lastStroke,
        imagePng,
      }
    } catch (error) {
      return { error: {
        message: error.message,
        stack: error.stack,
      },}
    }
  
    
  }

  useEffect(() => {
    redrawCanvas();
  }, [redrawer, strokesAreLoaded]); 


  useEffect(() => {
    setVisibleLineWidth(lineWidth * zoomFactor * canvasScaleRef.current);
  }, [lineWidth, zoomFactor]);


  return {
    drawStroke,
    redrawCanvas,
    render,
    prepareRenderStrokes,
  }
};

