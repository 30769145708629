import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const useGameSettings = ({
  gameId,
  telegramUserId,
  activeUserIdRef,

  socketRef, 
  setGameInfo,
  gameInfoRef, 

  userSetsRef,
  forceRender,
  canDraw,
}) => {

  const { t } = useTranslation();

  function changeWord(direction) {
    return new Promise((resolve, reject) => {
      const socket = socketRef.current;
      
      socket?.emit('word', gameId, direction, (word) => {
        if (word) {
          window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
          setTimeout(() => {
            window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
          }, 100);
          resolve(word);
        } 
      });
  
    });
  }

  function changeWord0(direction) {

    const socket = socketRef.current;
    socket.emit('word', gameId, direction, (word)=>{
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
      setTimeout(() => {
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
      }, 100);
      // window.Telegram.WebApp.showAlert(word);
    });

  }

  function openLetter (num) {

    if (!canDraw) { return; }

    const socket = socketRef.current;
    socket.emit('hint', gameId, num, (result)=>{
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
    });
    
  }

  const activateHints = () => {

    // window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('error')
    const confirm = false;

    const game = gameInfoRef.current;
    const squares = Array.from(game?.word)?.map(() => '◻️').join('');

    // Проверка наличия объекта Telegram Web App
    if (confirm && window.Telegram && window.Telegram.WebApp.initData) {
        // Параметры для попапа
        const popupParams = {
          title: t('hints.title'),
          message: t('hints.text', { squares }),
          buttons: [
            { id: 'clear', type: 'destructive', text: t('hints.button_confirm') },
            { id: 'cancel', type: 'cancel', text: t('hints.button_cancel') },
          ]
        };
        // Отображение попапа и обработка результата
        window.Telegram.WebApp.showPopup(popupParams, (buttonId) => {
            if (buttonId === 'clear') {
              emitHints();
            }
        });
    } else {
      emitHints();
    }
  }

  function emitHints() {

    const socket = socketRef.current;
    if (!socket) {return;}
    socket.emit('hints', gameId, (result)=>{
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
    });

  }



  function saveUserSets (sets, callback) {

    const socket = socketRef.current;
    if (!socket) {return;}

    // Устанавливаем локально
    for (let param in sets) {
      userSetsRef.current[param] = sets[param];
    }
    socket.emit('saveSets', telegramUserId, sets, (result)=>{
      callback?.(result);
    });
    forceRender();

  }

  function saveGameSets (sets, callback) {

    const socket = socketRef.current;
    if (!socket) {return;}

    if (activeUserIdRef.current !== gameInfoRef.current.user_key) { return; }

    const gameSettings = gameInfoRef.current?.settings;
    // Устанавливаем локально
    for (let param in sets) {
      gameSettings[param] = sets[param];
    }
    socket.emit('gameSets', {  gameId, userId: telegramUserId,}, sets, (result)=>{
      callback?.(result);
    });
    forceRender();
  }

  function groupRedraw() {

    const socket = socketRef.current;
    if (!socket) {return;}
    socket.emit('redraw', gameId, (result)=>{
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
    });

  }

  function askGroupPremium() {

    const socket = socketRef.current;
    if (!socket) {return;}
    socket.emit('askGroupPrem', gameId, telegramUserId, (result)=>{
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
    });

  }
  
  function hostAction (action, data, callback) {

    const socket = socketRef.current;
    if (!socket) {return;}

    socket.emit(action, { gameId, userId: telegramUserId }, data, (result)=>{
      callback?.(result);
    });

  }
  

  return {
    changeWord,
    activateHints,
    openLetter,
    saveUserSets,
    saveGameSets,
    groupRedraw,
    askGroupPremium,
    hostAction,
  };

};
