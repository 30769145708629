// GameContext.js
import React, { createContext, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useWebSocket from '../hooks/connection/useWebSocket';
import useTelegram from '../hooks/connection/useTelegram'; 
import useGameLoader from '../hooks/connection/useGameLoader';  // Подключаем новый хук
import { saveDrawing } from '../hooks/connection/saveDrawing'; 
import { imageDataToPNG, updatePngCache } from '../hooks/connection/savePng'; 
import { useUpdateGame } from '../hooks/game/useUpdateGame';  
import { usePremium } from '../hooks/game/usePremium';  
import { useGameSettings } from '../hooks/game/useGameSettings';  

import { showTemporaryHint } from '../utils/showHint';  

const GameContext = createContext();

export const useGameContext = () => useContext(GameContext);

export const GameProvider = ({ children }) => {

  const { t } = useTranslation();

  const imageCache = useRef(new Map());

  const [forcer, setForcer] = useState(0);
  const forceRender = () => { setForcer(i => i + 1);};

  const drawMethodsRef = useRef({})

  const [gameInfo, setGameInfo] = useState(null);
  const [gameIsLoaded, setGameIsLoaded] = useState(null);
  const [premiumIsLoaded, setPremiumIsLoaded] = useState(null);
  const [strokesAreLoaded, setStrokesAreLoaded] = useState(null);
  const gameInfoRef = useRef(null);

  const [gameError, setGameError] = useState();
  const [canDraw, setCanDraw] = useState(true);

  const [continueDrawing, setContinueDrawing] = useState(false);

  const [showMenu, setShowMenu] = useState([]);
  const menuParamRef = useRef({});

  const [showPremiumMenu, setShowPremiumMenu] = useState(false);
  const [showPremiumInfo, setShowPremiumInfo] = useState(null);
  const premiumSubsRef = useRef([]);
  const premiumFeaturesRef = useRef([]);

  const [popupMessage, setPopupMessage] = useState(null);
  const showPopupMessage = (message) =>{
    setPopupMessage(message);
    if (message && !message.mute) { 
      const level = message.haptic?.level || 'warning';
      const reason = message.haptic?.reason || 'notificationOccurred';
      appleHaptic(level, reason); 
    }
  }
  const [showModeHelp, setShowModeHelp] = useState(false);

  const userSetsRef = useRef({ colors: [],});
  const groupSetsRef = useRef({});
  const botSetsRef = useRef({});

  const connectionStatusRef = useRef({
    lastSaveTime: 0,
    lastPreviewTime: 0,
    lastConnectionErrorTime: 0,
  });

  const openMenu = (menuId, more = {}) =>{
    menuParamRef.current = more;
    setShowMenu(showMenu=>{
      if (more.layer) {
        return [menuId, ...(showMenu.filter(currentId => currentId !== menuId))];
      } else {
        return [menuId]
      }
    })
  }

  const delayMenu = (menuId, delay = 50) =>{
    setShowMenu([]);
    setTimeout(() => {
      setShowMenu([menuId]);
    }, delay);
  }
  const closeMenu = (menuId) =>{
    setShowMenu(showMenu=>{
      return menuId ? showMenu.filter(currentId => currentId !== menuId) : [] ;
    })
  }
  const toggleMenu = (menuId) =>{
    setShowMenu(showMenu=>{
      return showMenu?.[0] === menuId ? [] : [menuId];
    })
  }

  const isAppleDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const iosDevices = /iphone|ipad|ipod|macintosh|mac os/;
    return iosDevices.test(userAgent);
  }

  const appleHaptic = (level = 'light', reason = 'impactOccurred') => {
    if (isAppleDevice()) {
      window.Telegram?.WebApp?.HapticFeedback?.[reason]?.(level);
    }
  }
  
  const androidHaptic = (level = 'success', reason = 'notificationOccurred') => {
    if (!isAppleDevice()) {
      window.Telegram?.WebApp?.HapticFeedback?.[reason]?.(level);
    }
  }

  const {
    urlParams,
    startParam, 
    gameId,
    groupId,
    inBrowser,
    socketPortNum,
    telegramUserId,
    languageCode,

    activeUserIdRef,
    moderatorIdRef,
    moderatorModeRef,

    isValid, setIsValid,
    isChecked,
    isHacker,

    hash,
    initData,
    dataCheckString,

   } = useTelegram({
    openMenu,
   });

   
  const { 
    socketRef, 
    socketStatus,
    PORT,
   } = useWebSocket({
    gameId, 
    groupId, 
    socketPortNum,
    
    telegramUserId, 
    initData,
    setIsValid,
   });


  useGameLoader({
    inBrowser,
    socketRef, 
    socketStatus, 
    gameId, 
    groupId, 
    telegramUserId,
    moderatorModeRef,

    activeUserIdRef,
    moderatorIdRef,

    isValid,
    gameInfoRef, 
    gameInfo, 
    setGameInfo, 
    setGameIsLoaded,

    setGameError,
    setCanDraw,
    showPopupMessage,

    userSetsRef,
    groupSetsRef,
    botSetsRef,

    imageCache,
    updatePngCache,
  });

  useUpdateGame({
    socketRef, 
    socketStatus, 
    gameInfoRef, 
    setGameInfo, 
    connectionStatusRef,
  });

  usePremium({
    gameInfo,
    gameIsLoaded,
    setPremiumIsLoaded,

    userSetsRef,
    groupSetsRef,
    
    premiumSubsRef,
    premiumFeaturesRef,
  });

  const {
    changeWord,
    activateHints,
    openLetter,
    saveUserSets,
    saveGameSets,
    groupRedraw,
    askGroupPremium,
    hostAction,
  } = useGameSettings({
    gameId,
    telegramUserId,
    activeUserIdRef,
    
    socketRef, 
    setGameInfo,
    gameInfoRef, 
    
    userSetsRef,
    forceRender,
    canDraw,
  })

  const delayPremiumMenu = (value, delay = 50)=>{
    setTimeout(() => {
      setShowPremiumMenu(value);
    }, delay);
  }

  const gameWordFun = () => {
    const game = gameInfoRef.current;
    if (moderatorModeRef.current) {
      return  'Ⓜ️ ' + (game?.results ? game?.word : t('spectator.guess_the_word'));
    } else if (game?.results || canDraw) {
      return game?.word;
    } else {
      return t('spectator.guess_the_word');
    }
  }


  return (
    <GameContext.Provider value={{ 
      forceRender,
      drawMethodsRef,

      gameInfo, setGameInfo,
      gameInfoRef,
      gameIsLoaded,
      premiumIsLoaded,

      strokesAreLoaded, setStrokesAreLoaded,

      gameError,

      canDraw,
      continueDrawing, setContinueDrawing,
      
      showMenu, setShowMenu,
      menuParamRef,
      openMenu, delayMenu, closeMenu, toggleMenu,

      isAppleDevice,
      appleHaptic,
      androidHaptic,

      showPremiumMenu, setShowPremiumMenu, delayPremiumMenu,
      showPremiumInfo, setShowPremiumInfo,
      premiumSubsRef,
      premiumFeaturesRef,

      popupMessage, showPopupMessage,
      showModeHelp, setShowModeHelp,

      connectionStatusRef,

      urlParams,
      startParam, 
      gameId,
      telegramUserId,
      activeUserIdRef,
      
      groupId,
      moderatorIdRef,
      moderatorModeRef,
      languageCode,

      inBrowser,

      setCanDraw,

      isValid, setIsValid,
      isChecked,
      isHacker,
      hash,
      initData,
      dataCheckString,

      socketRef,
      socketStatus,
      PORT,
      saveDrawing,
      imageDataToPNG,
      imageCache,

      changeWord,
      activateHints,
      openLetter,
      groupRedraw,
      askGroupPremium,
      hostAction,
      
      userSetsRef,
      saveUserSets,
      saveGameSets,

      groupSetsRef,
      botSetsRef,

      gameWordFun,
      showTemporaryHint,
    }}>
      {children}
    </GameContext.Provider>
  );
};
