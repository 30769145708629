// BrushContext.js
import React, { createContext, useContext, useState, useRef, useEffect } from 'react';

const HistoryContext = createContext();

export const HistoryProvider = ({ children }) => {


  const [userStrokes, setUserStrokes] = useState({});
  const userStrokesRef = useRef(userStrokes);

  const [redrawer, setRedrawer] = useState(0);

  return (
    <HistoryContext.Provider value={{ 

      userStrokes, setUserStrokes,
      userStrokesRef,

      redrawer, setRedrawer,

       }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistoryContext = () => useContext(HistoryContext);