import {  filterClosePoints } from './helpers/points';
import {  brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.feather;

// feather.js
export function drawFeatherStroke({
  color,
  lineWidth,
  points,
  softness,

  sets = {},
}, context) {
  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const { 
    speedDependence,

    minWidthStart = 0.01, // Мин. толщина начала, 1 = lineWidth
    taperStart = 0.5, // Длина сужения начала, 0.5 = целая половина штриха
    minWidthEnd = 0.01, // Мин. толщина конца, 1 = lineWidth
    taperEnd = 0.5, // Длина сужения конца
  } = brushSettings;

  if (!speedDependence) {
    points = filterClosePoints(points, Math.min(lineWidth, 20));
  }

  // Создаем отдельный буфер
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  let numInterpolations = Math.max(5, lineWidth/5);
  numInterpolations = Math.min(numInterpolations, 20);
  numInterpolations = Math.ceil(numInterpolations);

  const interpolatedPoints = interpolatePoints(points, numInterpolations);
  const totalPoints = interpolatedPoints.length;

  // Вычисление центральной части без сужения
  let centerPartStart = Math.floor(totalPoints * taperStart);
  let centerPartEnd = totalPoints - 1 - Math.floor(totalPoints * taperEnd);

  centerPartStart = Math.max(0, Math.min(centerPartStart, totalPoints - 1));
  centerPartEnd = Math.max(0, Math.min(centerPartEnd, totalPoints - 1));

  if (centerPartStart > centerPartEnd) {
    const midpoint = Math.floor((centerPartStart + centerPartEnd) / 2);
    centerPartStart = centerPartEnd = midpoint;
  }


  // Рисуем на буфере непрозрачной линией
  bufferCtx.strokeStyle = 'rgba(255, 255, 255, 1)';

  for (let i = 0; i < totalPoints - 1; i++) {
    const startPoint = interpolatedPoints[i];
    const endPoint = interpolatedPoints[i + 1];
    const segmentWidth = computeLineWidth(i, totalPoints, lineWidth, centerPartStart, centerPartEnd, minWidthStart, minWidthEnd);

    bufferCtx.beginPath();
    bufferCtx.moveTo(startPoint[0], startPoint[1]);
    bufferCtx.lineTo(endPoint[0], endPoint[1]);
    bufferCtx.lineWidth = segmentWidth;
    bufferCtx.stroke();
  }

  // Применяем цвет и прозрачность к буферу
  bufferCtx.globalCompositeOperation = 'source-in';
  bufferCtx.fillStyle = color;
  bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  // Рисуем буфер на основном холсте
  context.drawImage(bufferCanvas, 0, 0);

}

export function interpolatePoints(points, numInterpolations) {
  function catmullRomSpline(p0, p1, p2, p3, t) {
    const t2 = t * t;
    const t3 = t2 * t;
    return [
      0.5 * ((2 * p1[0]) + (-p0[0] + p2[0]) * t +
        (2 * p0[0] - 5 * p1[0] + 4 * p2[0] - p3[0]) * t2 +
        (-p0[0] + 3 * p1[0] - 3 * p2[0] + p3[0]) * t3),
      0.5 * ((2 * p1[1]) + (-p0[1] + p2[1]) * t +
        (2 * p0[1] - 5 * p1[1] + 4 * p2[1] - p3[1]) * t2 +
        (-p0[1] + 3 * p1[1] - 3 * p2[1] + p3[1]) * t3)
    ];
  }

  let interpolatedPoints = [];
  for (let i = 0; i < points.length - 1; i++) {
    const p0 = i > 0 ? points[i - 1] : points[i];
    const p1 = points[i];
    const p2 = points[i + 1];
    const p3 = i < points.length - 2 ? points[i + 2] : p2;

    interpolatedPoints.push(p1);
    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);
      interpolatedPoints.push(catmullRomSpline(p0, p1, p2, p3, t));
    }
  }
  interpolatedPoints.push(points[points.length - 1]);
  return interpolatedPoints;
}


export function computeLineWidth(index, totalPoints, lineWidth, centerPartStart, centerPartEnd, minWidthStart = 0.01, minWidthEnd = 0.01, smoothingFactor = 0.5) {
  function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
  }

  function interpolate(start, end, t) {
    const smoothT = easeInOutQuad(t);
    return start + (end - start) * (smoothingFactor * smoothT + (1 - smoothingFactor) * t);
  }

  const minWidthStartPx = minWidthStart * lineWidth;
  const minWidthEndPx = minWidthEnd * lineWidth;

  if (index <= centerPartStart) {
    const t = index / (centerPartStart || 1); // Избегаем деления на ноль
    return interpolate(minWidthStartPx, lineWidth, t);
  } else if (index >= centerPartEnd) {
    const t = (index - centerPartEnd) / ((totalPoints - 1 - centerPartEnd) || 1); // Избегаем деления на ноль
    return interpolate(lineWidth, minWidthEndPx, t);
  } else {
    return lineWidth;
  }
}



