import './ControlButtons.css'; // Подключаем стили
import './Palette/ColorPalette.css'; // Подключаем стили

import React, { useState, useEffect } from 'react';

import { 
  FaBrush, FaSprayCan, FaPencilAlt, FaFeatherAlt, FaPaintBrush, FaStarHalfAlt, 
  FaFillDrip, FaTint, 
  FaSun, FaMoon, 
  FaHotjar, FaToiletPaper, 
  FaEraser, FaPalette, FaUndoAlt, FaRedoAlt, FaTimes, FaPencilRuler,
  FaPaw,
} from 'react-icons/fa';
import { 
  FaMarker,
  FaEyeDropper,
  FaBroomBall,
} from 'react-icons/fa6';
import { 
  TbBrush,
  TbLineDashed,
  TbGrain,
  TbContrast2Filled, TbContrast2Off,
  TbZoomIn, TbZoomOut,
  TbX,
 } from 'react-icons/tb';
 import { MdGridOn } from "react-icons/md";



import ColorPalettePopup from './Palette/ColorPalettePopup.js';
import PremiumGrayscalePalette from './Palette/PremiumGrayscalePalette.js';
import GrayscalePicker from './Palette/GrayscalePicker.js';
import BrushSizeControl from './BrushSizeControl';

import SoftnessControl from './SoftnessControl.js'; 
import BrightnessControl from './BrightnessControl'; 
import OpacityControl from './OpacityControl'; 
import MagnifierIndicator from './Magnifier/MagnifierIndicator.js';

// import SaturationControl from './SaturationControl'; 
// import HueControl from './HueControl';

import CircleSwitch from "./CircleSwitch/CircleSwitch.tsx";


function ControlButtons({
  gameContext,
  canvasContext,
  brushContext,
  drawMethods,
}) {

    const { 
      gameInfo,
      userSetsRef,

      premiumFeaturesRef,
     } = gameContext;

    const { 
      zoomFactor,
     } = canvasContext;

    const { 
      color, setTheColorFun, 
      
      showPalette, setShowPalette,
      premiumGrayscale,
      activeTool,

      isPipettePicking,
      pickingPlaceRef,
      brushIndicatorFrame,

      brushTypeRef,
      effectTypeRef,
      clipboardColor,
     } = brushContext;

    const { 
      changeTool,
      undoLastAction, 
      redoLastAction,
      confirmClearCanvas, 
     } = drawMethods;

     const sets = userSetsRef.current[brushTypeRef.current];
     const defaultBrushLink = sets?.clone || brushTypeRef.current;

    const BrushIcon = {
      plain: FaMarker,
      spray: FaSprayCan,
      bristle: FaPaintBrush,
      rembrandt: FaBroomBall,
      feather: FaFeatherAlt,
      pencil: FaPencilAlt,
      oil: FaBrush,
      watercolor: TbBrush,
      dashed: TbLineDashed,
      test: FaPaw,
    }[defaultBrushLink] || FaPaintBrush;

    const EffectIcon = {
      filler: FaFillDrip,
      blur: FaTint,
      noise: TbGrain,
      pixelate: MdGridOn,
      lighten: FaSun,
      darken: FaMoon,
      saturate: FaHotjar,
      desaturate: FaToiletPaper,
      contrast: TbContrast2Filled,
      decontrast: TbContrast2Off,
      zoomin: TbZoomIn,
      zoomout: TbZoomOut,
    }[effectTypeRef.current] || FaFillDrip;

    return (
      <div className='controls-frame'>

        {/* Верхний ряд с инструментами */}
        <div className='controls-row'>

            {
              gameInfo?.mode === 'sprint' ? (
                <CircleSwitch
                  icon={<BrushIcon />}
                  isActive={activeTool === "brush"}
                  onClick={() => changeTool("brush")}
                />
              ) : 
              (
                <SoftnessControl 
                  canvasContext={canvasContext}
                  gameContext={gameContext}
                  brushContext={brushContext} 
                  drawMethods={drawMethods} 
                  icon={<BrushIcon />}
                />
              )
            }

            <CircleSwitch
              icon={<FaEraser />}
              isActive={activeTool === "eraser"}
              onClick={() => changeTool("eraser")}
            />

            <OpacityControl 
                canvasContext={canvasContext}
                brushContext={brushContext} 
                drawMethods={drawMethods} 

                icon= {<EffectIcon />}
                available={premiumFeaturesRef.current.includes('effect') && !userSetsRef.current.premiumOff}
              />

            <BrightnessControl 
              canvasContext={canvasContext}
              brushContext={brushContext} 
              drawMethods={drawMethods} 

              icon= {<FaEyeDropper />}
              available={premiumFeaturesRef.current.includes('pipette') && !userSetsRef.current.premiumOff}
            />

            <div 
                className='allow-context-menu'
                onContextMenu={clipboardColor}
                  // #000000
              >
              <CircleSwitch
                id="paletteToggleButton" 
                icon={<FaPalette />}
                isActive={showPalette}
                color={color}
                onClick={() => setShowPalette(showPalette=>!showPalette)}
              />    
            </div>

        </div>
        
        {/* Нижний ряд с управляющими кнопками */}
        <div className='controls-row'>

        <CircleSwitch
          icon={<FaUndoAlt />}
          onClick={(e) => {
            undoLastAction();
            setShowPalette(false);
          }}
        />

        <CircleSwitch
          icon={<FaRedoAlt />}
          onClick={(e) => {
            redoLastAction();
            setShowPalette(false);
          }}
        />

        <CircleSwitch
          icon={<FaTimes />}
          onClick={(e) => {
            confirmClearCanvas();
            setShowPalette(false);
          }}
        />
          <BrushSizeControl 
            gameContext={gameContext}
            canvasContext={canvasContext}
            icon={<FaPencilRuler />}
            brushContext={brushContext} 
          />
        </div>

    {/* userSetsRef.current.premiumOff */}

    {showPalette && (
      (!premiumFeaturesRef.current.includes('colors')
      || userSetsRef.current.premiumOff) ? (
      <GrayscalePicker
        gameContext={gameContext}
        brushContext={brushContext}
        canvasContext={canvasContext}
      />
      ) : premiumGrayscale ? (
        <PremiumGrayscalePalette
          gameContext={gameContext}
          brushContext={brushContext}
          canvasContext={canvasContext}
        />
      ) : (
        <ColorPalettePopup
          gameContext={gameContext}
          brushContext={brushContext}
          canvasContext={canvasContext}
        />
      )
      )}
      {isPipettePicking && (
          <MagnifierIndicator
            gameContext={gameContext}
            brushContext={brushContext}
            canvasContext={canvasContext}
            drawMethods={drawMethods}
          />
      )}
</div>
    );
}

export default ControlButtons;
