import chroma from 'chroma-js';
import { useState, useRef } from 'react';

import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useColor = ({
  getEventPos,
}) => {

  const canvasContext = useDrawingContext ();
  const brushContext = useBrushContext();

  const {
    canvasRef, 
    tempCanvasRef,
  } = canvasContext;

  const {

    color, setTheColorFun, 
    prevColorRef,
    activeTool, setActiveTool,
    activeToolRef,
    previousToolRef,
    handleLastColors,

    setIsPipettePicking,
    setPickingPlace,
    pickingPlaceRef,

    altIsDownRef,

    lastActionTimeRef,
  } = brushContext;

  function getColorByEvent ({ nativeEvent }) {

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    let { x, y } = getEventPos(nativeEvent)
    x = Math.max(x, 1);
    y = Math.max(y, 1);
    x = Math.min(x, tempCanvasRef.current.width - 1);
    y = Math.min(y, tempCanvasRef.current.height - 1);

    const pixel = context.getImageData(x, y, 1, 1).data;
    const rgbaColor = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${pixel[3] / 255})`;
    
    // Конвертация из RGBA в HEX
    const hexColor = chroma(rgbaColor).hex();
    return hexColor;
  }


  const startPickingColorFromCanvas = ({ nativeEvent }) => {
    if (activeToolRef.current !== 'pipette') return;
    lastActionTimeRef.current = Date.now();
    setIsPipettePicking(true);
    setTheColorFun(getColorByEvent({ nativeEvent }));

    const place = {
      x: nativeEvent.touches?.[0]?.clientX || nativeEvent.clientX,
      y: nativeEvent.touches?.[0]?.clientY || nativeEvent.clientY,
    };
    prevColorRef.current = color;
    setPickingPlace(place);
    pickingPlaceRef.current = place;
  };

  const searchForColorFromCanvas = ({ nativeEvent }) => {
    if (activeToolRef.current !== 'pipette') return;
    // if (!isPipettePicking) return;
    setTheColorFun(getColorByEvent({ nativeEvent }));

    const place = {
      x: nativeEvent.touches?.[0]?.clientX || nativeEvent.clientX,
      y: nativeEvent.touches?.[0]?.clientY || nativeEvent.clientY,
    };
    setPickingPlace(place);
    pickingPlaceRef.current = place;
  };

  const pickColorFromCanvas = (event) => {
    if (activeToolRef.current !== 'pipette') return;

    if (!altIsDownRef.current) {
      setActiveTool(previousToolRef.current);
      lastActionTimeRef.current = Date.now();
    }

    setIsPipettePicking(false);
    handleLastColors();
    
  };

  const stopPickingColorFromCanvas = (event) => {
    setIsPipettePicking(false);
  };


  return {
    startPickingColorFromCanvas,
    searchForColorFromCanvas,
    pickColorFromCanvas,
    stopPickingColorFromCanvas,
  };
};