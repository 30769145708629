// BoardMain.js
// import 'context-filter-polyfill';
import './BoardMain.css'; // Стили для StartScreen
import React, { useEffect, } from 'react';

import ConvyBoard from './ConvyBoard';
import SpectatorBoard from './SpectatorBoard';
import Spinner from './spinner/Spinner';
import ResultsOverlay from './results/ResultsOverlay';
import GameNotLoaded from './results/GameNotLoaded';
import SettingsOverlay from './menus/SettingsOverlay';
import WordChangeOverlay from './menus/WordChangeOverlay';
import EffectMenu from './menus/EffectMenu';
import BrushMenu from './menus/BrushMenu';
import CustomizeBrush from './menus/CustomizeBrush';
import ModeInfoOverlay from './menus/ModeInfoOverlay';
import PremiumNew from './premium/PremiumNew';
import PremiumInfo from './premium/PremiumInfo';
import PopupMessage from './indicators/PopupMessage';

import { useGameContext } from '../../contexts/GameContext';
import { useBrushContext } from '../../contexts/BrushContext';
import { useDrawingContext } from '../../contexts/DrawingContext';
import RicRoll from './rickroll/RicRoll';

function BoardMain() {

  const gameContext = useGameContext();
  const brushContext = useBrushContext();
  const drawingContext = useDrawingContext();
  
  const { 
    gameInfo,
    strokesAreLoaded,
    gameInfoRef,
    gameError,
    canDraw,
    continueDrawing, setContinueDrawing,

    showMenu,
    showPremiumMenu, 
    showModeHelp,

    popupMessage,

    reloadGame,
    isChecked,
    isHacker,

    userSetsRef,
   } = gameContext;

  const showResultsOverlay = gameInfoRef.current?.results && !continueDrawing;
  const menuId = showMenu?.[0];

  return (
    <>
      {!strokesAreLoaded && !isHacker && (
        <Spinner />
      )}
      {isChecked && isHacker && (
        <RicRoll />
      )}

      {
        menuId === 'settings' ? (
          <SettingsOverlay 
            gameContext={gameContext}
            brushContext={brushContext}
          />
        ) : 
        menuId === 'brush' ? (
          <BrushMenu 
            gameContext={gameContext}
            brushContext={brushContext}
            drawingContext={drawingContext}
          />
        ) : 
        menuId === 'effect' ? (
          <EffectMenu 
            gameContext={gameContext}
            brushContext={brushContext}
            drawingContext={drawingContext}
          />
        ) : 
        menuId === 'word' ? (
          <WordChangeOverlay 
            gameContext={gameContext}
          />
        ) : 
        menuId === 'customizeBrush' ? (
          <CustomizeBrush 
            gameContext={gameContext}
            brushContext={brushContext}
            drawingContext={drawingContext}
          />
        ) : 
        null
      }

      {showPremiumMenu && (
        <PremiumNew 
          gameContext={gameContext}
          brushContext={brushContext}
        />
      )}

      {gameInfoRef.current?.modeHelp && showModeHelp && !gameInfoRef.current?.results && (
        <ModeInfoOverlay 
          gameContext={gameContext}
        />
      )}
      {popupMessage && (
        <PopupMessage 
          gameContext={gameContext}
        />
      )}
      {gameInfo?.results && !continueDrawing && (
        <ResultsOverlay 
          game={gameInfoRef.current}
          canDraw={canDraw}
          setContinueDrawing={setContinueDrawing}
        />
      )}
   
      {gameError?.error && (
        <GameNotLoaded
          gameError={gameError}
          reloadGame={reloadGame}
        />
      )}

      <div
        style = {{
          opacity: (strokesAreLoaded || gameError) ? 1 : 0,
        }}
      >
        { 
          canDraw ? 
          (
            // <DrawingBoard />
            <ConvyBoard />
          ): (
          <SpectatorBoard />
          )
        }
      </div>
      
    </>
  );
}

export default BoardMain;