// BrightnessControl.js
import React, { useState, useRef, useEffect } from 'react';
import chroma from 'chroma-js';
import CircleSwitch from "./CircleSwitch/CircleSwitch.tsx";
import ColorIndicator from './ColorIndicator'; 

const BrightnessControl = ({ 
  canvasContext,
  brushContext,
  drawMethods,

  icon,
  available,
 }) => {

  const {

    color, 
    setTheColorFun, 
    activeTool, 
    isChangingBrightness, setIsChangingBrightness,

  } = brushContext;

  const {
    changeTool, 
  } = drawMethods;

    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 
    const hasMoved = useRef(false);

    const startChangingBrightness = (event) => {
        setIsChangingBrightness(true);

        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;
        hasMoved.initialSaturation = chroma(color).get('hsv.s');
        hasMoved.initialHue = chroma(color).get('hsv.h');
        hasMoved.initialAlpha = chroma(color).alpha();

    };

    useEffect(() => {
        const handlePointerMove = (event) => {
            if (!isChangingBrightness) return;

            const x = event.clientX || event.touches[0]?.clientX;
            const y = event.clientY || event.touches[0]?.clientY;

            const diffY = Math.round(pointerPosition.y - y);
            setPointerPosition({ x, y }); // Обновление позиции кружка
            
            if (diffY === 0) return;

            // Изменение яркости
            const currentColor = chroma(color);

            const currentBrightness = chroma(color).get('hsv.v');
            let newBrightness = Math.max(0, Math.min(1, currentBrightness + (diffY * 0.005)));

            if (newBrightness < 0.01) {return;}
    
            // Попытка сохранить насыщенность при изменении яркости
            let newColor = currentColor;
            newColor = newColor.set('hsv.v', newBrightness)
            newColor = newColor.set('hsv.s', hasMoved.initialSaturation)
            newColor = newColor.set('hsv.h', hasMoved.initialHue)
            newColor = newColor.alpha(hasMoved.initialAlpha);

            let finalBrightness = chroma(newColor).get('hsv.v');
            if (!finalBrightness) {return;}
            
            setTheColorFun(chroma(newColor).hex());
            hasMoved.current = true;
        };

        const handlePointerUp = () => {
            setIsChangingBrightness(false);
            if (hasMoved.startTouchPoint && Date.now() - hasMoved.startTime < 350) {
              changeTool("pipette")
            }
        };

        if (isChangingBrightness) {
            document.addEventListener('mousemove', handlePointerMove);
            document.addEventListener('mouseup', handlePointerUp);
            document.addEventListener('touchmove', handlePointerMove);
            document.addEventListener('touchend', handlePointerUp);
        }

        return () => {
            document.removeEventListener('mousemove', handlePointerMove);
            document.removeEventListener('mouseup', handlePointerUp);
            document.removeEventListener('touchmove', handlePointerMove);
            document.removeEventListener('touchend', handlePointerUp);
        };
    }, [isChangingBrightness, color, setTheColorFun, changeTool]);

    return (
      <div>
        <div
            onMouseDown={startChangingBrightness}
            onTouchStart={startChangingBrightness}
            style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px',
            }}
        >
          <CircleSwitch
            icon={icon}
            available={available}
            isActive={activeTool === "pipette"}
          />
          </div>
          {isChangingBrightness && (
                <ColorIndicator
                  canvasContext={canvasContext}
                  brushContext={brushContext}
                  
                  position={pointerPosition}
                  clickPosition={clickPosition}
                  />
            )}
        </div>
    );
};

export default BrightnessControl;
