// useThrottle.js
import { useRef } from 'react';

export function useThrottle(callback, delay) {
  const lastCallRef = useRef(0);

  const throttledFunction = (...args) => {
    const now = new Date().getTime();
    if (now - lastCallRef.current > delay) {
      callback(...args);
      lastCallRef.current = now;
    }
  };

  return throttledFunction;
}
