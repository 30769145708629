import { useCallback, useEffect, useRef, useState } from 'react';

import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useMouse = ({

  wipeTempCanvas,

  startDrawing,
  draw,
  finishDrawing,

  startPickingColorFromCanvas,
  searchForColorFromCanvas,
  pickColorFromCanvas,

  fill,

  changeViewStart,
  changeViewMove,
  simpleZoom,

  // Touch
  
  startDrawingTouch,
  drawTouch,
  finishDrawingTouch,

  touchZoomStart,
  touchZoomMove,
  resetZoomStart,
  resetZoomEnd,

  // SHAPES
  handleShapeStart,
  handleShapeMove,
  handleShapeEnd,

  // HotMouse
  startRightClickMove,
  moveRightClickMove,

  // TEST
  getEventPos,

  straightLine,
  applyBlurAtTouch,

}, {
  socket,
}) => {

  const mouseListenersAreSet = useRef(false);
  const canvasContext = useDrawingContext ();
  const brushContext = useBrushContext();

  const {
    tempCanvasRef,
    mousePositionRef,
    mouseIsPressedRef,

    setMouseDetected,
    touchStartLastTimeRef,
    touchMoveLastTimeRef,
    touchEndLastTimeRef,

    isZooming, setIsZooming,
  } = canvasContext;

  const {
  
    showPaletteRef,
    activeToolRef,
    effectTypeRef,
    
    controlIsDownRef,
    altIsDownRef,
    spaceIsDownRef,
    tabIsDownRef,
    shiftIsDownRef,

    drawingShapeRef,

    hotMouseActive, setHotMouseActive,

  
  } = brushContext;


  const isClickInsideCanvas = (nativeEvent, canvasRef) => {
    if (!canvasRef.current) {
      console.error('Canvas reference is not defined.');
      return false;
    }
  
    const canvasRect = canvasRef.current.getBoundingClientRect();
    return (
      nativeEvent.clientX >= canvasRect.left &&
      nativeEvent.clientX <= canvasRect.right &&
      nativeEvent.clientY >= canvasRect.top &&
      nativeEvent.clientY <= canvasRect.bottom
    );
  };

  const handleClick = (nativeEvent) => {
  };


  const handleMouseDown = (nativeEvent) => {

    if (nativeEvent.button === 1) {
      nativeEvent.preventDefault();
      return;
    }

    mouseIsPressedRef.current = true;

    if (altIsDownRef.current && nativeEvent.button === 2) {
      startRightClickMove({nativeEvent});
    } else if (tabIsDownRef.current) {
      straightLine({nativeEvent})
    } else if (
      !tabIsDownRef.current &&
      (controlIsDownRef.current || spaceIsDownRef.current || nativeEvent.button === 2)
      ) {
      if (nativeEvent.target.closest('.temp-canvas')) { 
        changeViewStart({ nativeEvent })
      }
    } else if (activeToolRef.current === 'pipette') {
      startPickingColorFromCanvas({ nativeEvent });
    } else if (activeToolRef.current === 'effect' && effectTypeRef.current === 'filler') {
      fill({ nativeEvent });
    } else {
      startDrawing({ nativeEvent });
    }
  };


  const handleMouseMove = (nativeEvent) => {

    if (touchStartLastTimeRef.current < Date.now() - 200) {
      setMouseDetected(true)
    }

    mousePositionRef.current = { x: nativeEvent.clientX, y: nativeEvent.clientY };

    if (showPaletteRef.current) {return;}
    if (!nativeEvent.buttons) {return;}

    if (altIsDownRef.current && nativeEvent.buttons === 2) {
      moveRightClickMove({nativeEvent});
      setHotMouseActive(true);
    } else if (
      !tabIsDownRef.current &&
      (controlIsDownRef.current || spaceIsDownRef.current || nativeEvent.buttons === 2)
    ) {
      if (nativeEvent.target.closest('.temp-canvas')) { 
        changeViewMove({ nativeEvent });
      }

    } else if (activeToolRef.current === 'pipette') {
      searchForColorFromCanvas({ nativeEvent });
    } else {
      draw({ nativeEvent });
    }

  };


  const handleMouseUp = (nativeEvent) => {

    mouseIsPressedRef.current = false;
    nativeEvent.preventDefault();

    if (
      !tabIsDownRef.current &&
      nativeEvent.button === 2 && 
      (controlIsDownRef.current || spaceIsDownRef.current)
    ) {
      // resetZoomEnd('force')
      simpleZoom({ nativeEvent })
    } else if (controlIsDownRef.current && nativeEvent.button === 0) {
      simpleZoom({ nativeEvent })
    } else if (
      activeToolRef.current === 'pipette' 
      && nativeEvent.target === tempCanvasRef.current
      ) {
      pickColorFromCanvas({ nativeEvent });
    } else {
      finishDrawing({ nativeEvent });
    }

    setHotMouseActive(false);

  };



  const handleTouchStart = (nativeEvent) => {

    touchStartLastTimeRef.current = Date.now();
    setMouseDetected(false);

    if (nativeEvent.touches.length > 3) {
      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      resetZoomStart();
    } else if (nativeEvent.touches.length === 2) {
      touchZoomStart(nativeEvent)
    } else  if (activeToolRef.current === 'effect' && effectTypeRef.current === 'filler') {
      // Do nothing
    }  else  if (activeToolRef.current === 'pipette') {
      startPickingColorFromCanvas({ nativeEvent })
    } else {
      handleShapeStart(nativeEvent)
      startDrawingTouch(nativeEvent)
    }

  };

  const handleOutsideTouchStart = (nativeEvent) => {

    touchStartLastTimeRef.current = Date.now();
    setMouseDetected(false);

  };

  const handleTouchMove = (nativeEvent) => {

    // setMouseDetected(false)
    if (nativeEvent.target.closest('.touch-active')) { return; }
    if (nativeEvent.target.closest('.premium-menu')) { return; }
    if (nativeEvent.target.closest('.settings-menu')) { return; }

    touchMoveLastTimeRef.current = Date.now();

    if (nativeEvent.touches.length > 3) {
      drawingShapeRef.current = false;
      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      drawingShapeRef.current = false;
      wipeTempCanvas();

    } else if (nativeEvent.touches.length === 2) {

      drawingShapeRef.current = false;
      wipeTempCanvas();
      touchZoomMove(nativeEvent);

    } else if (activeToolRef.current === 'pipette') {

      drawingShapeRef.current = false;
      searchForColorFromCanvas({ nativeEvent })

    }  else {

      if (drawingShapeRef.current) {
        handleShapeMove(nativeEvent);
      } else {
        drawingShapeRef.current = false;
        drawTouch(nativeEvent)
      }

    }


  };

  const handleTouchEnd = (nativeEvent) => {

    touchEndLastTimeRef.current = Date.now();

    resetZoomEnd();
    setIsZooming(false);

    if (drawingShapeRef.current) {
      
      handleShapeEnd(nativeEvent);
      drawingShapeRef.current = false;

    } else if (
      activeToolRef.current === 'pipette'
      && nativeEvent.target === tempCanvasRef.current
      ) {

        pickColorFromCanvas({ nativeEvent })

    } else {
      finishDrawingTouch()
    }

  };

  const handleContextMenu = (nativeEvent) => {

    setMouseDetected(false)

    if (nativeEvent.target.closest('.input-field')) { return; }
    nativeEvent.preventDefault();
    if (nativeEvent.target.closest('.allow-context-menu')) { return; }
    nativeEvent.stopPropagation();

  };

  useEffect(() => {

    setTimeout(() => {
      if (!mouseListenersAreSet.current) {
        mouseListenersAreSet.current = true;
        
        // window.addEventListener('click', handleClick);

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchstart', handleOutsideTouchStart);
        window.addEventListener('contextmenu', handleContextMenu);

        // Удаление слушателей событий при размонтировании компонента
        return () => {
            // window.removeEventListener('click', handleClick);

            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchstart', handleOutsideTouchStart);
            window.removeEventListener('contextmenu', handleContextMenu);


        };
      }
    }, 500);
   
  }, []); 


  return {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleContextMenu,
  };
};

