// DrawingBoard.js
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext } from '../../contexts/GameContext'; 
import useConvy from '../../hooks/useConvy'; 
import { useSpecatorMouse } from '../../hooks/spectate/useSpecatorMouse'; 
import ZoomFactor from './drawing/ZoomFactor';
import Spectator from './spectator/Spectator';
import ChatOverlay from './indicators/ChatOverlay'; 

function SpectatorBoard() {

    const { t } = useTranslation();

    const gameContext = useGameContext();
    const { 
      openMenu,
      gameInfoRef, 
      gameInfo,
     } = gameContext;
    const game = gameInfoRef.current;

    const guessTheWordText = 
    game?.hint?.active ? 
    game?.hint.hints.map(hint=>hint.opened ? ` ${hint.letter} ` : '⬜️').join('') : 
    t('spectator.guess_the_word')

 
    const {
      canvasContext, 
      brushContext,
      drawMethods,
    } = useConvy();

    const {
      spectatorMouseDown,
      spectatorMouseUp,
      spectatorMouseMove,
      spectatorTouchStart,
      spectatorTouchMove,
      spectatorTouchEnd,
    } = useSpecatorMouse(drawMethods);

    const {
      canvasStyleDimensionsRef,
      canvasRef,
      tempCanvasRef,
      visibleSizeRef,
      zoomFactor,
      panOffset,
      currentCanvasRectRef,
    } = canvasContext;


    let spectatorPanelStyle = { bottom: 0 };

    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      currentCanvasRectRef.current = rect;
      const windowHeight = window.innerHeight;

      if (windowHeight - rect.bottom < 130
        ) {
        spectatorPanelStyle = {
          top: 'auto',
          bottom: `20px`,
        }
      }  else {
        spectatorPanelStyle = {
          top: `${rect.bottom - 5}px`,
        }
      }
    }

    return (
      <div className="drawing-area"
        style={{
          width: `${visibleSizeRef.current}px`,
          height: `${Math.max(visibleSizeRef.current * 1.35, window.innerHeight)}px`,
          margin: '0 auto', // Центрирование по горизонтали
          top: '0', // Максимально вверху, но с небольшим отступом
          left: 0,
          right: 0,
        }}
        >
          {zoomFactor === 1 && (
            <div className="word" onClick={()=>{openMenu('settings')}}>
              {guessTheWordText}
            </div>
          )}

          <ZoomFactor
            canvasContext={canvasContext}
            brushContext={brushContext}
            drawMethods={drawMethods}
          />

          <canvas className='canvas'
            ref={canvasRef}
            style={{
              transformOrigin: '0 0',
              transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px) scale(${zoomFactor})`
            }}
          />


          <ChatOverlay /> 
          <canvas className='temp-canvas' 
            ref={tempCanvasRef}

            onMouseDown={  gameInfo ? spectatorMouseDown : null }
            onMouseUp={  spectatorMouseUp }
            // onMouseMove={ spectatorMouseMove }
            onTouchStart={ gameInfo ? spectatorTouchStart : null }
            onTouchMove={ spectatorTouchMove }
            onTouchEnd={ spectatorTouchEnd }

            style={{
              transformOrigin: '0 0',
              transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px) scale(${zoomFactor})`,
            }}
          />
          <div className='spectator-controls' style={spectatorPanelStyle}>
            <Spectator
              authorName={game?.author.first_name || ''}
            />
          </div>
      </div>
  );
}

export default SpectatorBoard;
