import * as PIXI from 'pixi.js';
import * as filters from 'pixi-filters';


export async function applyPixiBlur(canvas, blurAmount, quality = 30) {
  // blurAmount *= 4;
  blurAmount *= quality / 7.25;

  // Создаем приложение Pixi.js с размерами, соответствующими входному canvas
  const app = new PIXI.Application();
  await app.init({
    width: canvas.width,
    height: canvas.height,
    backgroundAlpha: 0,
    preserveDrawingBuffer: true,
  });

  // Явно задаем размеры рендерера
  app.renderer.resize(canvas.width, canvas.height);

  // Создаем текстуру из содержимого оригинального canvas
  const texture = PIXI.Texture.from(canvas);
  
  // Создаем спрайт с точными размерами canvas
  const sprite = new PIXI.Sprite(texture);
  sprite.width = canvas.width;
  sprite.height = canvas.height;
  app.stage.addChild(sprite);

  // Применяем фильтр размытия
  const blurFilter = new PIXI.BlurFilter(blurAmount);
  // blurFilter.repeatEdgePixels = true;
  blurFilter.quality = quality;
  blurFilter.padding = 100;
  sprite.filters = [blurFilter];

  // Рендерим сцену
  app.render();

  // Проверяем, что у нас есть данные для отрисовки
  if (app.view.width === 0 || app.view.height === 0) {
    console.error('Error: Rendered view has zero dimensions');
    return false;
  }

  // Копируем результат обратно на оригинальный canvas
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  
  try {
    ctx.drawImage(app.view, 0, 0, canvas.width, canvas.height);
  } catch (error) {
    console.error('Error when drawing image:', error);
    return false;
  }

  // Очищаем ресурсы
  app.destroy(true);
  return true;
}