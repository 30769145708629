import { MdCleaningServices, MdOutlineCleaningServices } from "react-icons/md";

import React from 'react';
import { 
  FaFillDrip, FaTint, 
  FaSun, FaMoon,
  FaHotjar, FaToiletPaper, 
 } from 'react-icons/fa';

import {
  TbGrain, 
  TbContrast2Filled, TbContrast2Off,
  TbZoomIn, TbZoomOut,
  TbLineScan,

 } from 'react-icons/tb';
 import { MdGridOn } from "react-icons/md";


import './BrushMenu.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';


function EffectMenu({
  gameContext,
  brushContext,
}) {

  const { t } = useTranslation();

  const {
    gameInfoRef,
    openMenu, closeMenu,

    premiumFeaturesRef,
    userSetsRef,

    delayPremiumMenu,
    saveUserSets,
  } = gameContext;

  const { 
    setEffectType,
    effectTypeRef,

    effectHistoryRef,
    setTheColorFun,

    colorRef,
    setSoftness,

    lastActionTimeRef,
   } = brushContext;


  const effects = [
    {
      key: 'filler',
      label: t('effect.filler.name'),
      icon: FaFillDrip,
    },
    {
      key: 'blur',
      label: t('effect.blur.name'),
      icon: FaTint,
    },
    {
      key: 'noise',
      label: t('effect.noise.name'),
      icon: TbGrain,
    },
    {
      key: 'pixelate',
      label: t('effect.pixelate.name'),
      icon: MdGridOn,
    },
    {
      key: 'lighten',
      label: t('effect.lighten.name'),
      icon: FaSun,
    },
    {
      key: 'darken',
      label: t('effect.darken.name'),
      icon: FaMoon,
    },
    {
      key: 'saturate',
      label: t('effect.saturate.name'),
      icon: FaHotjar,
    },
    {
      key: 'desaturate',
      label: t('effect.desaturate.name'),
      icon: FaToiletPaper,
    },
    {
      key: 'contrast',
      label: t('effect.contrast.name'),
      icon: TbContrast2Filled,
    },
    {
      key: 'decontrast',
      label: t('effect.decontrast.name'),
      icon: TbContrast2Off,
    },
    {
      key: 'zoomin',
      label: t('effect.zoomin.name'),
      icon: TbZoomIn,
    },
    {
      key: 'zoomout',
      label: t('effect.zoomout.name'),
      icon: TbZoomOut,
    },
    // {
    //   key: 'outline',
    //   label: t('effect.outline.name'),
    //   icon: TbLineScan,
    // },
  ]

  const handleOverlayClick = () =>{
    if (lastActionTimeRef.current < Date.now() - 300) {
      closeMenu(); 
    }
  }

  const handleSwitchEffect = (newEffectType) => {

    if (lastActionTimeRef.current > Date.now() - 300) { return; }

    if (newEffectType !== 'filler' && 
      (!premiumFeaturesRef.current.includes('brushes') 
      || userSetsRef.current.premiumOff)
      ) {
      closeMenu(); 
      delayPremiumMenu('menu');
    } else {

      const previousEffect = effectTypeRef.current;
      
      if (previousEffect !== newEffectType) {
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
      }
      effectTypeRef.current = newEffectType;
      setEffectType(newEffectType);

      effectHistoryRef.current.push(newEffectType);
      closeMenu(); 
      saveUserSets({ effectType: newEffectType })

    }

  };


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="effect-menu">

        <span className="brush-menu-title">{t('effect.title')}</span>

          {effects.map((effect) => (
            <div key={effect.key} 
            className={`brush-option-row ${effectTypeRef.current === effect.key ? 'active' : ''}`}
            
            onClick={()=>handleSwitchEffect(effect.key)}>
              <label className="brush-label">
                <effect.icon className='brush-icon'/>
                <span> {effect.label}</span>
              </label>
            </div>
        ))}
        
      </div>
    </>
  );
}

export default EffectMenu;
