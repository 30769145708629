import { useState, useRef } from 'react';

import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useHotMouse = () => {

  const canvasContext = useDrawingContext ();
  const brushContext = useBrushContext();

  const {
    zoomFactorRef,
  } = canvasContext;

  const {
    hotMouseStartPositionRef,

    lineWidth, setTheSizeFun,
    softness, setSoftness,
  } = brushContext;


  const startLineWidth = useRef();
  const startSoftness = useRef();


  function startRightClickMove ({ nativeEvent }) {

    hotMouseStartPositionRef.current = {
      x: nativeEvent.clientX,
      y: nativeEvent.clientY,
    }

    startLineWidth.current = lineWidth;
    startSoftness.current = softness;
    
  }

  function moveRightClickMove ({ nativeEvent }) {

    const x = nativeEvent.clientX;
    const y = nativeEvent.clientY;

    const diffY = hotMouseStartPositionRef.current.y - y;
    const diffX = x - hotMouseStartPositionRef.current.x;

    let softnessDifY = 0;
    let lineWidthDifx = 0;

    if (Math.abs(diffY) - Math.abs(diffX) > 0) {
      softnessDifY = diffY > 0 ?
      (Math.abs(diffY) - Math.abs(diffX)) :
      - (Math.abs(diffY) - Math.abs(diffX)) ;
    } else {
      lineWidthDifx = diffX > 0 ?
      (Math.abs(diffX) - Math.abs(diffY)) :
      - (Math.abs(diffX) - Math.abs(diffY)) ;
    }

    let newLineWidth = startLineWidth.current + lineWidthDifx * 1.4 / zoomFactorRef.current;
    newLineWidth = Math.max(1, newLineWidth);
    setTheSizeFun(newLineWidth);


    let newSoftness = startSoftness.current + softnessDifY * 0.003;
    // newSoftness = Math.min(Math.max(0, newSoftness), 0);
    newSoftness = Math.min(Math.max(0, newSoftness), 0.3);
    setSoftness(newSoftness);
    
  }

  return {
    startRightClickMove,
    moveRightClickMove,
  };
};