import { useState } from 'react';
import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useTool = () => {

  const gameContext = useGameContext();
  const brushContext = useBrushContext();

  const {
    gameInfoRef,
    userSetsRef,
    delayPremiumMenu,

    premiumFeaturesRef,
    toggleMenu,
  } = gameContext;

  const {
  
    activeTool, setActiveTool,
    activeToolRef,
    previousToolRef,
    setShowPalette,

    lastActionTimeRef,
  } = brushContext;

  function changeTool(newTool, more = {}) {

    const { event, fixed, openMenu } = more;

    if (Date.now() - lastActionTimeRef.current < 300) {return;}

    if (newTool === 'brush' && (activeTool === 'brush' || openMenu)) {
      lastActionTimeRef.current = Date.now();
      setActiveTool(newTool);
      return toggleMenu('brush');
    }
    if (newTool === 'effect' && (activeTool === 'effect' || openMenu)) {
      lastActionTimeRef.current = Date.now();
      setActiveTool(newTool);
      return toggleMenu('effect');
    }
    
    lastActionTimeRef.current = Date.now();

    setShowPalette(false);
    // const game = gameInfoRef.current;
    // if ((game.shared || !game.word) && newTool === 'filler') { return }
  
    if (['effect', 'pipette'].includes(newTool)) {
      if (!premiumFeaturesRef.current.includes(newTool) || userSetsRef.current.premiumOff) {
        delayPremiumMenu('menu');
        return;
      }
    }

    
    if (newTool === activeTool && !fixed) {
      newTool = 'brush';
    }
  
    previousToolRef.current = activeTool;
    if (newTool !== activeTool) {
      activeToolRef.current  = newTool;
      setActiveTool(newTool);
    }
  }

  return {
    changeTool,
  };
};