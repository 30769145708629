// pixiFilters.js
import * as PIXI from 'pixi.js';
import * as filters from 'pixi-filters';


export async function applyPixiFilter(canvas, filterType, options = {}) {
  // Создаем приложение Pixi.js с размерами, соответствующими входному canvas
  const app = new PIXI.Application();
  await app.init({
    width: canvas.width,
    height: canvas.height,
    backgroundAlpha: 0,
    preserveDrawingBuffer: true,
  });

  // Создаем текстуру из содержимого оригинального canvas
  const texture = PIXI.Texture.from(canvas);
  
  // Создаем спрайт с точными размерами canvas
  const sprite = new PIXI.Sprite(texture);
  sprite.width = canvas.width;
  sprite.height = canvas.height;
  app.stage.addChild(sprite);

  // Выбираем и применяем фильтр
  let filter;
  switch (filterType) {
    case 'outline':
      filter = new filters.OutlineFilter(options.thickness, 0x99ff99, options.quality);
      filter.alpha = options.alpha;
      filter.knockout = options.knockout;
      break;
    case 'bulgePinch':
      filter = new filters.BulgePinchFilter(options);
      break;
    case 'crt':
      filter = new filters.CRTFilter(options);
      break;
    case 'noise':
      filter = new PIXI.NoiseFilter(options.noise, options.seed);
      break;
    case 'adjustment':
      filter = new filters.AdjustmentFilter(options);
      break;
    case 'ascii':
      filter = new filters.AsciiFilter(options.size);
      break;
    case 'pixelate':
      filter = new filters.PixelateFilter(options.size);
      break;
    case 'crossHatch':
      filter = new filters.CrossHatchFilter();
      break;
    case 'dot':
      filter = new filters.DotFilter(options.scale, options.angle);
      break;
    case 'emboss':
      filter = new filters.EmbossFilter(options.strength);
      break;
    case 'glitch':
      filter = new filters.GlitchFilter();
      break;
    case 'oldFilm':
      filter = new filters.OldFilmFilter(options);
      break;
    case 'rgb':
      filter = new filters.RGBSplitFilter(options.red, options.green, options.blue);
      break;
    default:
      console.error('Unknown filter type:', filterType);
      return false;
  }

  sprite.filters = [filter];

  // Рендерим сцену
  app.render();

  // Проверяем, что у нас есть данные для отрисовки
  if (app.view.width === 0 || app.view.height === 0) {
    console.error('Error: Rendered view has zero dimensions');
    return false;
  }

  // Копируем результат обратно на оригинальный canvas
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  
  try {
    ctx.drawImage(app.view, 0, 0, canvas.width, canvas.height);
  } catch (error) {
    console.error('Error when drawing image:', error);
    return false;
  }

  // Очищаем ресурсы
  app.destroy(true);
  return true;
}

// Функция для получения списка всех доступных фильтров
export function getAvailableFilters() {
  return [
    'noise', 'ascii', 'pixelate', 'crossHatch', 'dot', 
    'emboss', 'glitch', 'oldFilm', 'rgb', 'sketch'
  ];
}