// MagnifierIndicator.js
import React, { useEffect, useRef } from 'react';

const MagnifierIndicator = ({
  gameContext,
  brushContext,
  canvasContext,
  drawMethods,
}) => {
  const { 
    canvasRef, 
    zoomFactor,

    isTouchDevice,
    mouseDetected,
   } = canvasContext;
  const { color, prevColorRef, pickingPlaceRef } = brushContext;
  const { getCanvasPoint } = drawMethods;

  const position = pickingPlaceRef.current;
  const canvasPosition = getCanvasPoint(position);
  const prevColor = prevColorRef.current;

  const params = (isTouchDevice && !mouseDetected) ?
  {
    magnifierOn: true,
    offsetY: -120,
    magnificationFactor: Math.max(
      1, 
      zoomFactor < 4 ? zoomFactor : zoomFactor / 2
    ),

    magnifierDiameter: 125,
    colorIndicatorDiameter: 160,
    containerDiameter: 160,
  } :
  {
    granFrameOn: true,
    magnifierOn: false,
    offsetY: 0,

    colorIndicatorDiameter: 170,
    colorIndicatorBorder: 18,
    containerDiameter: 200,

  } ;

  const magnifierCanvasRef = useRef(null);

  // Dimensions
  const magnifierDiameter = params.magnifierDiameter; 
  const magnifierRadius = magnifierDiameter / 2;

  const colorIndicatorDiameter = params.colorIndicatorDiameter; 
  const colorIndicatorBorder = params.colorIndicatorBorder; 
  const containerDiameter = params.containerDiameter; // Diameter of the outer gray circle
  const containerRadius = containerDiameter / 2;

  const grayCircleBorderWidth = 16; 


  useEffect(() => {
    if (!canvasRef.current || !magnifierCanvasRef.current) return;

    const mainCanvas = canvasRef.current;
    const magnifierCanvas = magnifierCanvasRef.current;
    const ctx = magnifierCanvas.getContext('2d');

    const sWidth = magnifierDiameter / params.magnificationFactor;
    const sHeight = magnifierDiameter / params.magnificationFactor;

    let sx = canvasPosition.x - sWidth / 2;
    let sy = canvasPosition.y - sHeight / 2;

    // Adjust if the source rectangle goes outside the main canvas
    // if (sx < 0) sx = 0;
    // if (sy < 0) sy = 0;
    // if (sx + sWidth > mainCanvas.width) sx = mainCanvas.width - sWidth;
    // if (sy + sHeight > mainCanvas.height) sy = mainCanvas.height - sHeight;

    ctx.clearRect(0, 0, magnifierDiameter, magnifierDiameter);

    // Draw the magnified area
    ctx.drawImage(
      mainCanvas,
      sx,
      sy,
      sWidth,
      sHeight,
      0,
      0,
      magnifierDiameter,
      magnifierDiameter
    );

    // Create a circular clipping path to ensure the magnifier is circular
    ctx.globalCompositeOperation = 'destination-in';
    ctx.beginPath();
    ctx.arc(magnifierRadius, magnifierRadius, magnifierRadius, 0, Math.PI * 2, false);
    ctx.fill();
    ctx.globalCompositeOperation = 'source-over';

    // Draw crosshair in the center
    ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
    ctx.lineWidth = 0.5;

    ctx.beginPath();
    // Vertical line
    ctx.moveTo(magnifierRadius, magnifierRadius - 10);
    ctx.lineTo(magnifierRadius, magnifierRadius + 10);
    // Horizontal line
    ctx.moveTo(magnifierRadius - 10, magnifierRadius);
    ctx.lineTo(magnifierRadius + 10, magnifierRadius);
    ctx.stroke();
  }, [canvasRef, canvasPosition]);


  // Style for the container div (shifted up by 100 pixels)
  const containerStyle = {
    position: 'fixed',
    left: `${position.x - containerRadius}px`,
    top: `${position.y - containerRadius + params.offsetY}px`, // Shift up 
    width: `${containerDiameter}px`,
    height: `${containerDiameter}px`,
    pointerEvents: 'none',
    zIndex: 200,
  };

  // Style for the outer gray circle
  const grayCircleStyle = {
    position: 'absolute',
    left: '0',
    top: '0',
    width: `${containerDiameter}px`,
    height: `${containerDiameter}px`,
    borderRadius: '50%',
    border: `${grayCircleBorderWidth}px solid`,
    borderColor: 'rgba(128, 128, 128, 1)', // 50% gray
    boxSizing: 'border-box',
  };

  // Centering calculations
  const colorIndicatorLeft = (containerDiameter - colorIndicatorDiameter) / 2 - grayCircleBorderWidth;
  const colorIndicatorTop = (containerDiameter - colorIndicatorDiameter) / 2 - grayCircleBorderWidth;

  // Style for the color indicator
  const colorIndicatorStyle = (isTouchDevice && !mouseDetected) ? {
    position: 'absolute',
    left: `${colorIndicatorLeft}px`,
    top: `${colorIndicatorTop}px`,
    width: `${colorIndicatorDiameter}px`,
    height: `${colorIndicatorDiameter}px`,
    borderRadius: '50%',
    overflow: 'hidden',
    background: `linear-gradient(to bottom, ${color} 70%, ${prevColor} 30%)`,
  } : {
    position: 'absolute',
    left: `${colorIndicatorLeft}px`,
    top: `${colorIndicatorTop}px`,
    width: `${colorIndicatorDiameter}px`,
    height: `${colorIndicatorDiameter}px`,
    borderRadius: '50%',
    border: `${colorIndicatorBorder}px solid`,
    borderColor: `${color} ${color} ${prevColor} ${prevColor}`,
    background: 'transparent',
    transform: 'rotate(-45deg)',
    boxSizing: 'border-box',
  };

  // Positioning the magnifier canvas
  const magnifierCanvasLeft = (colorIndicatorDiameter - magnifierDiameter) / 2;
  const magnifierCanvasTop = (colorIndicatorDiameter - magnifierDiameter) / 2;

  const magnifierCanvasStyle = {
    position: 'absolute',
    left: `${magnifierCanvasLeft}px`,
    top: `${magnifierCanvasTop}px`,
    width: `${magnifierDiameter}px`,
    height: `${magnifierDiameter}px`,
    borderRadius: '50%',
    overflow: 'hidden',
  };

  return (
    <div style={containerStyle}>
      <div style={grayCircleStyle}>
        <div style={colorIndicatorStyle}>
          { params.magnifierOn ? (
            <canvas
            ref={magnifierCanvasRef}
            width={magnifierDiameter}
            height={magnifierDiameter}
            style={magnifierCanvasStyle}
          />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MagnifierIndicator;
