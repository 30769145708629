import { useCallback, useEffect, } from 'react';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

export const useHelpers = () => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    imageCache,
  } = gameContext;

  const {
    canvasRef,
    tempCanvasRef,
    canvasScaleRef,
    zoomFactor, 
    zoomFactorRef,
    panOffset, 
  
  } = canvasContext;


  const wipeTempCanvas = () => {
    const canvas = tempCanvasRef.current;
    const context = canvas.getContext('2d');

    // Очищаем весь канвас
    context.clearRect(0, 0, canvas.width, canvas.height);
  };


  const getEventPos = (event) => {

    const canvasDom = canvasRef.current;

    event = event?.touches?.[0] || event;
    const rect = canvasDom.getBoundingClientRect();

    let x = ((event.clientX - rect.left) / canvasScaleRef.current) / zoomFactorRef.current;
    let y = ((event.clientY - rect.top) / canvasScaleRef.current) / zoomFactorRef.current;
    
    x = Math.round(x * 10) / 10;
    y = Math.round(y * 10) / 10;

    return { x, y, };
  };

  const getCanvasPoint = ((
    {x, y}, 
    rect = tempCanvasRef.current.getBoundingClientRect(),
    ) =>{

    const zoom = zoomFactorRef.current;

    let pointX = x;
    pointX -= rect.left;
    pointX = pointX / canvasScaleRef.current;
    pointX = pointX / zoom;

    let pointY = y;
    pointY -= rect.top;
    pointY = pointY / canvasScaleRef.current;
    pointY = pointY / zoom;

    // let pointY = (((y - realOffset.y) / canvasScaleRef.current / zoom)).toFixed(2);
    pointX = Math.min(Math.max(pointX, 0), tempCanvasRef.current.width).toFixed(2) -1;
    pointY = Math.min(Math.max(pointY, 0), tempCanvasRef.current.height).toFixed(2) -1;

    return {
      x: pointX,
      y: pointY,
    };
  } )

  const getCanvasViewPoint = (({x, y}) =>{
    const canvasDom = tempCanvasRef.current;
    const size = canvasDom.width;

    let pointX = x;
    let pointY = y;
    // let pointX = (x - canvasRectRef.current.left) / canvasScaleRef.current;
    // let pointY = (y - canvasRectRef.current.top) / canvasScaleRef.current;

    // pointX = Math.min(Math.max(pointX, 0), size);
    // pointY = Math.min(Math.max(pointY, 0), size);

    return {
      x: pointX,
      y: pointY,
    };
  } )


  return {
    wipeTempCanvas,
    getEventPos,
    getCanvasPoint,
    getCanvasViewPoint,
  }
};

