import { useEffect, useRef, useState, useCallback } from 'react';
import { useGameContext } from '../../contexts/GameContext';

export const useCanvasSetup = () => {

  const gameContext = useGameContext();
  const { 
    activeUserIdRef,
    gameInfoRef, 
    userSetsRef, 
    gameIsLoaded, 
    isValid,
   } = gameContext;

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const tempCanvasRef = useRef(null);
  const tempContextRef = useRef(null);
  const softCanvasRef = useRef(document.createElement('canvas'));
  const softContextRef = softCanvasRef.current.getContext('2d');

  const basicSizeRef = useRef(Math.min(window.innerWidth, window.innerHeight * 0.7));
  const visibleSizeRef = useRef(basicSizeRef.current);
  const canvasStyleSizeRef = useRef(basicSizeRef.current * 0.98);
  const canvasSideRef = useRef(800);

  const canvasStyleDimensionsRef = useRef({
    width: basicSizeRef.current * 0.98, 
    height: basicSizeRef.current * 0.98,
  });
  const canvasDimensionsRef = useRef({
    width: 800, 
    height: 800,
  });
  const canvasScaleRef = useRef(canvasStyleDimensionsRef.current.width / canvasDimensionsRef.current.width);
  

  const canvasRectRef = useRef(null);

  const initializeCanvasSize = (thisCanvasRef, thisContexRef, more = {}) => {

    if (gameInfoRef.current && gameInfoRef.current.mode !== 'sprint') {

      const gameSettings = gameInfoRef.current?.settings || {};
      gameSettings.orientation = gameSettings.orientation || 'square';

      if (gameSettings.orientation === 'vertical') {
        if (gameSettings.highRes) {
          canvasDimensionsRef.current = gameInfoRef.current.dimensions?.height === 1920 ? {
            width: 1280,
            height: 1920,
          } : {
            width: 800,
            height: 1200
          };
        } else {
          canvasDimensionsRef.current = {
            width: 800,
            height: 1200
          };
        }
      } else if (gameSettings.orientation === 'horizontal') {
        if (gameSettings.highRes) {
          canvasDimensionsRef.current = gameInfoRef.current.dimensions?.width === 1920 ? {
            width: 1920,
            height: 1280,
          } : {
            width: 1200,
            height: 800
          };
        } else {
          canvasDimensionsRef.current = {
            width: 1200,
            height: 800
          };
          }
      } else {
        if (gameSettings.highRes) {
          canvasDimensionsRef.current = {
            width: 1280,
            height: 1280
          };
        } else {
          canvasDimensionsRef.current = {
            width: 800,
            height: 800
          };
        }
      }

      if (gameSettings.orientation === 'vertical') {

        basicSizeRef.current = Math.min(window.innerWidth * 3/2, window.innerHeight * 0.71);

        canvasStyleDimensionsRef.current = {
          width: basicSizeRef.current * 0.98 * 2/3,
          height: basicSizeRef.current * 0.98
        };

      } else if (gameSettings.orientation === 'horizontal') {

        basicSizeRef.current = Math.min(window.innerWidth, window.innerHeight);

        canvasStyleDimensionsRef.current = {
          width: basicSizeRef.current * 0.98,
          height: basicSizeRef.current * 0.98 * 2/3
        };
      } 

      canvasScaleRef.current = Math.min(canvasStyleDimensionsRef.current.width / canvasDimensionsRef.current.width, canvasStyleDimensionsRef.current.height / canvasDimensionsRef.current.height);
    }

    const { fill } = more;
    const canvas = thisCanvasRef.current;

    canvas.width = canvasDimensionsRef.current.width;
    canvas.height = canvasDimensionsRef.current.height;
    // canvas.width = canvasSideRef.current;
    // canvas.height = canvasSideRef.current;


    canvas.style.width = `${canvasStyleDimensionsRef.current.width}px`;
    canvas.style.height = `${canvasStyleDimensionsRef.current.height}px`;

    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.fillStyle = "white";
    if (fill) {
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
    thisContexRef.current = context;
  };
  
  const initializeCanvasSize0 = (thisCanvasRef, thisContexRef, more = {}) => {

    if (gameInfoRef.current && gameInfoRef.current.mode !== 'sprint') {

      const gameSettings = gameInfoRef.current?.settings;
      gameSettings.orientation = gameSettings.orientation || 'square';

      if (
        activeUserIdRef.current === gameInfoRef.current.user_key
        ) {

        if (gameSettings.orientation === 'vertical') {

          if (gameSettings.highRes) {
            canvasDimensionsRef.current = {
              width: 1280,
              height: 1920,
            };
          } else {
            canvasDimensionsRef.current = {
              width: 800,
              height: 1200
            };
           }
  
          } else if (gameSettings.orientation === 'horizontal') {
            
            if (gameSettings.highRes) {
              canvasDimensionsRef.current = {
                width: 1920,
                height: 1280,
              };
            } else {
              canvasDimensionsRef.current = {
                width: 1200,
                height: 800
              };
             }
  
          } else {

            if (gameSettings.highRes) {
              canvasDimensionsRef.current = {
                width: 1280,
                height: 1280
              };
            } else {
              canvasDimensionsRef.current = {
                width: 800,
                height: 800
              };
             }

          }

      } else {

        canvasDimensionsRef.current = gameInfoRef.current.dimensions || canvasDimensionsRef.current;

      }


      if (gameSettings.orientation === 'vertical') {

        basicSizeRef.current = Math.min(window.innerWidth * 3/2, window.innerHeight * 0.71);
        canvasStyleDimensionsRef.current = {
          width: basicSizeRef.current * 0.98 * 2/3,
          height: basicSizeRef.current * 0.98
        };

      } else if (gameSettings.orientation === 'horizontal') {
        canvasStyleDimensionsRef.current = {
          width: basicSizeRef.current * 0.98,
          height: basicSizeRef.current * 0.98 * 2/3
        };
      } 

      canvasScaleRef.current = Math.min(canvasStyleDimensionsRef.current.width / canvasDimensionsRef.current.width, canvasStyleDimensionsRef.current.height / canvasDimensionsRef.current.height);
    }

    const { fill } = more;
    const canvas = thisCanvasRef.current;

    canvas.width = canvasDimensionsRef.current.width;
    canvas.height = canvasDimensionsRef.current.height;
    // canvas.width = canvasSideRef.current;
    // canvas.height = canvasSideRef.current;


    canvas.style.width = `${canvasStyleDimensionsRef.current.width}px`;
    canvas.style.height = `${canvasStyleDimensionsRef.current.height}px`;

    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.fillStyle = "white";
    if (fill) {
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
    thisContexRef.current = context;
  };
  
  const updateCanvases = () => {

    initializeCanvasSize(canvasRef,  contextRef, { fill: true });
    canvasRectRef.current = canvasRef.current.getBoundingClientRect();
    initializeCanvasSize(tempCanvasRef, tempContextRef,);
    initializeCanvasSize(softCanvasRef, softContextRef,);

  }

  useEffect(() => {
    if (gameIsLoaded) {
      updateCanvases();
    }
  }, [gameIsLoaded]);

  return {
    visibleSizeRef,
    canvasStyleSizeRef,
    canvasSideRef,

    canvasStyleDimensionsRef,
    canvasDimensionsRef,

    canvasScaleRef,
    canvasRectRef,

    canvasRef,
    contextRef,
    tempCanvasRef,
    tempContextRef,
    softCanvasRef,
    softContextRef,

    updateCanvases,
  };
};

