
// ScanFill
export function createFill({
  canvas, 
  stroke,
  imageCache,
}) {

  let startX = stroke.x;
  let startY = stroke.y;
  let fillColor = stroke.color;

  startX = Math.round(startX);
  startY = Math.round(startY);

  const canvasCtx = canvas.getContext('2d');
  const imageData = canvasCtx.getImageData(0, 0, canvas.width, canvas.height);
  const width = imageData.width;
  const height = imageData.height;

  const fillColorRgb = hexToRGBA(fillColor);
  const edgeFillColor = makeTransparentColor(fillColorRgb, 0.95);

  const targetColor = blendWithWhite(getColorAtPixel(imageData, startX, startY));
  const newColor = blendColors(targetColor, fillColorRgb);

  if (colorsMatch(targetColor, newColor)) {
    return;
  }

  const fillHistory = new Set();
  const edgeHistory = new Set();

  function scanlineFill(x, y) {
    let leftX = x;
    let rightX = x;
    const currentY = y;

    // Сканируем влево
    while (leftX >= 0 && colorsMatch(blendWithWhite(getColorAtPixel(imageData, leftX, currentY)), targetColor)) {
      leftX--;
    }
    leftX++;

    // Сканируем вправо
    while (rightX < width && colorsMatch(blendWithWhite(getColorAtPixel(imageData, rightX, currentY)), targetColor)) {
      rightX++;
    }
    rightX--;

    // Заполняем линию
    for (let i = leftX; i <= rightX; i++) {
      const currentPos = (currentY * width + i) * 4;
      if (!fillHistory.has(currentPos)) {
        colorPixel(imageData, currentPos, newColor);
        fillHistory.add(currentPos);
      }
    }

    // Проверяем и обрабатываем границы
    function checkAndFillEdge(x, y) {
      if (x < 0 || x >= width || y < 0 || y >= height) return;
      const pos = (y * width + x) * 4;
      if (!fillHistory.has(pos) && !edgeHistory.has(pos)) {
        const pixelColor = blendWithWhite(getColorAtPixel(imageData, x, y));
        if (!colorsMatch(pixelColor, targetColor)) {
          const newEdgeColor = blendColors(pixelColor, edgeFillColor);
          colorPixel(imageData, pos, newEdgeColor);
          edgeHistory.add(pos);
        }
      }
    }

    // Проверяем границы текущей линии
    checkAndFillEdge(leftX - 1, currentY);
    checkAndFillEdge(rightX + 1, currentY);

    // Проверяем строки выше и ниже
    function checkAdjacentLine(adjacentY) {
      if (adjacentY < 0 || adjacentY >= height) return;

      for (let i = leftX; i <= rightX; i++) {
        const adjacentPos = (adjacentY * width + i) * 4;
        if (!fillHistory.has(adjacentPos) && !edgeHistory.has(adjacentPos)) {
          const pixelColor = blendWithWhite(getColorAtPixel(imageData, i, adjacentY));
          if (colorsMatch(pixelColor, targetColor)) {
            scanlineFill(i, adjacentY);
          } else {
            checkAndFillEdge(i, adjacentY);
          }
        }
      }
    }

    checkAdjacentLine(currentY - 1);
    checkAdjacentLine(currentY + 1);
  }

  scanlineFill(startX, startY);

  imageCache.current.set(stroke.time, imageData);
}

export function createFill0({
  stroke,
  canvas, 
  imageCache,
}) {

  let startX = stroke.x;
  let startY = stroke.y;
  let fillColor = stroke.color;

  startX = Math.round(startX);
  startY = Math.round(startY);

  const canvasCtx = canvas.getContext('2d');
  const imageData = canvasCtx.getImageData(0, 0, canvas.width, canvas.height);
  const width = imageData.width;

  const fillColorRgb = hexToRGBA(fillColor);
  const edgeFillColor = makeTransparentColor(fillColorRgb, 0.95);

  const targetColor = blendWithWhite(getColorAtPixel(imageData, startX, startY));
  const newColor = blendColors(targetColor, fillColorRgb);

  if (colorsMatch(targetColor, newColor)) {
    // imageCache.current.set(stroke.time, {ignore: true});
    return;
  }

  const pixelStack = [[startX, startY]];
  const fillHistory = {};

  while (pixelStack.length) {
      const [x, y] = pixelStack.pop();
      const currentPos = (y * width + x) * 4;

      if (fillHistory[currentPos]) {continue}
      fillHistory[currentPos] = true;

      let pixelColor = blendWithWhite(getColorAtPixel(imageData, x, y));

      if (!colorsMatch(pixelColor, targetColor) || x < 0 || y < 0 || x >= width || y >= imageData.height) {

        const newEdgeColor = blendColors(pixelColor, edgeFillColor);
        colorPixel(imageData, currentPos, newEdgeColor);
        continue;
        
      }

      // Заливаем текущий пиксель
      colorPixel(imageData, currentPos, newColor);

      // Добавляем соседние пиксели в стек
      pixelStack.push([x + 1, y], [x - 1, y], [x, y + 1], [x, y - 1]);
  }

  imageCache.current.set(stroke.time, imageData);

}

function getColorAtPixel(imageData, x, y) {
  const offset = (y * imageData.width + x) * 4;
  return {
    r: imageData.data[offset],
    g: imageData.data[offset + 1],
    b: imageData.data[offset + 2],
    a: imageData.data[offset + 3]
  };
}

function colorsMatch(a, b) {
  return a.r === b.r 
  && a.g === b.g 
  && a.b === b.b 
  && a.a === b.a;
}

function hexToRGBA(hex) {
  let r = 0, g = 0, b = 0, a = 255;
  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  } else if (hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
    a = parseInt(hex.slice(7, 9), 16);
  }
  return { r, g, b, a };
}


function colorPixel(imageData, offset, color) {
  imageData.data[offset] = color.r;
  imageData.data[offset + 1] = color.g;
  imageData.data[offset + 2] = color.b;
  imageData.data[offset + 3] = color.a !== undefined ? color.a : 255;
  // imageData.data[offset + 3] = 255;
}

function blendWithWhite(color) {
  const r = color.r;
  const g = color.g;
  const b = color.b;
  const alpha = color.a / 255; 

  // Вычисляем, как цвет смешается с белым фоном
  const blendedR = (1 - alpha) * 255 + alpha * r;
  const blendedG = (1 - alpha) * 255 + alpha * g;
  const blendedB = (1 - alpha) * 255 + alpha * b;

  return {
    r: Math.round(blendedR),
    g: Math.round(blendedG),
    b: Math.round(blendedB),
    a: 255
  };
}


function blendColors(baseColor, overlayColor) {
  // Извлекаем компоненты базового и наложенного цветов
  const baseR = baseColor.r;
  const baseG = baseColor.g;
  const baseB = baseColor.b;
  const overlayR = overlayColor.r;
  const overlayG = overlayColor.g;
  const overlayB = overlayColor.b;
  const overlayA = overlayColor.a / 255;  // Преобразуем альфа-канал из диапазона 0-255 в 0-1

  // Вычисляем новые значения RGB
  const newR = (1 - overlayA) * baseR + overlayA * overlayR;
  const newG = (1 - overlayA) * baseG + overlayA * overlayG;
  const newB = (1 - overlayA) * baseB + overlayA * overlayB;

  // Возвращаем итоговый цвет, непрозрачный
  return {
    r: Math.round(newR),
    g: Math.round(newG),
    b: Math.round(newB),
    a: 255  // Устанавливаем альфа-канал в максимальное значение, т.е. без прозрачности
  };
}

function makeTransparentColor(baseColor, opacity = 0.5) {
  return {
    r: baseColor.r,
    g: baseColor.g,
    b: baseColor.b,
    a: Math.round(baseColor.a * opacity)  
  };
}
