// watercolor.js

import chroma from 'chroma-js';
import { interpolatePoints, computeLineWidth } from './feather';
import { applyPixiBlur } from './pixi/pixiBlur'; // Reusing the seededRandom function
import { 
  duplicateCanvasContext,
  createStrokeMask, 
  applyTextureByStrokeMask,
  drawTexturedStroke, 
} from './helpers/texture';
import { getTexture, brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.watercolor;

export async function drawWatercolorStroke({
  color,
  lineWidth,
  points,
  softness,
  time,
  sets = {},
}, context) {

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  if (brushSettings.spreading) { lineWidth = lineWidth * 0.85; }
  const textureName = brushSettings.texture || 'waterAi';
  const textureScale = brushSettings.textureScale || 1;
  
  let watercolorTexture = getTexture('watercolor', textureName);
  let watercolorTextureWhite = getTexture('watercolor', textureName, 'light');

  const delays = [['start', Date.now()]];

  const cloneContext = duplicateCanvasContext(context);
  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();

  // const alphaDivisor = 1 + luminance;
  // const newAlpha = originalAlpha / alphaDivisor;
  const newAlpha = originalAlpha * brushSettings.opacity;

  color = chromaColor.alpha(1).hex();
  const shadowColor = chromaColor.darken(5).hex();

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = cloneContext.canvas.width;
  bufferCanvas.height = cloneContext.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';


  // let shadow = {
  //   offset: { x: 0.3, y: 0.3 },
  //   plusWidth: Math.max(1.9, Math.min(3, lineWidth / 15)),
  // }
  // Рисуем тень (черный цвет со смещением 2px)
  // bufferCtx.globalAlpha = 0.02;

  // drawStroke({
  //   points,
  //   lineWidth,
  //   color: shadowColor,
  //   offset: shadow.offset,
  //   plusWidth: shadow.plusWidth,
  //   time,
  // }, bufferCtx)

  // // Стираем область под основным мазком
  // bufferCtx.globalAlpha = 1;
  // bufferCtx.globalCompositeOperation = 'destination-out';

  // drawStroke({
  //   points,
  //   lineWidth,
  //   color: 'rgba(255, 255, 255, 1)',
  //   time,
  // }, bufferCtx)

  // delays.push(['shadows', Date.now()])
  // console.log(delays[delays.length - 1][0], delays[delays.length - 1][1] - delays[delays.length - 2][1])

  // Возвращаем режим наложения в нормальное состояние
  bufferCtx.globalCompositeOperation = 'source-over';

  // Применяем текстуру к основному мазку
  if (watercolorTexture) {

    const strokeMaskForBlurCtx = createStrokeMask({
      points,
      lineWidth,
      color,
      time,
      brushSettings,
    }, cloneContext, drawStroke);

    await applyBlurAlongPath(cloneContext, strokeMaskForBlurCtx.canvas, lineWidth, originalAlpha, false);

    delays.push(['blur', Date.now()])
    console.log(delays[delays.length - 1][0], delays[delays.length - 1][1] - delays[delays.length - 2][1])

    const strokeMaskCtx = createStrokeMask({
      points,
      lineWidth,
      color,
      time,
      brushSettings,
    }, cloneContext, drawStroke);

    // shadow (bufferCtx, strokeMaskCtx, shadowColor)

    drawTexturedStroke({
      bufferCtx,
      strokeMaskCtx,
      texture: watercolorTexture,
      color,
      textureScale,
    })

    delays.push(['textured stroke', Date.now()])
    console.log(delays[delays.length - 1][0], delays[delays.length - 1][1] - delays[delays.length - 2][1])

    applyTextureByStrokeMask({
      context: cloneContext,
      bufferCtx,
      strokeMaskCtx: strokeMaskForBlurCtx,
      texture: watercolorTextureWhite,
      color: 'white',
      opacity: 0.2,
      textureScale,
    })

    delays.push(['textured mask', Date.now()])
    console.log(delays[delays.length - 1][0], delays[delays.length - 1][1] - delays[delays.length - 2][1])

  }

  // Рисуем буфер на основном холсте
  cloneContext.globalAlpha = newAlpha;
  cloneContext.drawImage(bufferCanvas, 0, 0);
  cloneContext.globalAlpha = 1; // Возвращаем значение по умолчанию

  context.drawImage(cloneContext.canvas, 0, 0)

  delays.push(['final draw', Date.now()])
  console.log(delays[delays.length - 1][0], delays[delays.length - 1][1] - delays[delays.length - 2][1])
  console.log('total', delays[delays.length - 1][1] - delays[0][1])

}


function shadow (context, maskContext, shadowColor) {

  // Создаем второй буферный холст для теней
  const shadowCanvas = document.createElement('canvas');
  shadowCanvas.width = context.canvas.width;
  shadowCanvas.height = context.canvas.height;
  const shadowCtx = shadowCanvas.getContext('2d');

  // Рисуем черную тень
  shadowCtx.save();
  shadowCtx.globalAlpha = 0.2;
  shadowCtx.globalCompositeOperation = 'source-over';
  shadowCtx.shadowColor = shadowColor;
  shadowCtx.shadowBlur = 1;
  shadowCtx.shadowOffsetX = 0.2;
  shadowCtx.shadowOffsetY = 0.2;
  shadowCtx.drawImage(maskContext.canvas, 0, 0);
  shadowCtx.restore();

  // Стираем область под основным мазком
  shadowCtx.globalAlpha = 1;
  shadowCtx.globalCompositeOperation = 'destination-out';
  shadowCtx.drawImage(maskContext.canvas, 0, 0);

  context.globalAlpha = 1;
  context.drawImage(shadowCanvas, 0, 0);
  
}


// Функция для рисования сужающегося штриха
function drawStroke({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  plusWidth = 0,
  time,
  brushSettings,
}, context) {

  drawStrokeLine({
    points, 
    lineWidth, 
    color, 
    offset, 
    plusWidth,
    time,
    tapering: false,
    jitterAmount: brushSettings.spreading ? lineWidth / 10 : 0,
  }, context)

  if (brushSettings.spreading) {

     let radius = lineWidth / 2.2;
    let lines = points.length === 1 ? 30 : 30;
    let offsets = createCircleOffsets(radius, offset, lines, time);
    let adjustedPoints = addEdgePoints(points, 2)

    offsets.forEach((offset, i)=>{

      drawStrokeLine({
        points: adjustedPoints, 
        lineWidth: lineWidth / 5, 
        color, 
        offset, 
        plusWidth,
        jitterAmount: lineWidth / 6,
        time: time + i,
      }, context)
    })

  }


}



function createCircleOffsets(radius, baseOffset = { x: 0, y: 0 }, numPoints, seed) {
  const offsets = [];
  
  // Вспомогательная функция для округления до 3 знаков после запятой
  const round = (num) => Math.round(num * 1000) / 1000;

  for (let i = 0; i < numPoints; i++) {
    // Базовый угол
    const baseAngle = (i * 2 * Math.PI) / numPoints;
    
    // Добавляем небольшое случайное отклонение к углу
    const maxDeviation = Math.PI / numPoints / 4; // Максимальное отклонение - 1/8 расстояния между точками
    const angle = baseAngle + (seededRandom(seed + i) * 2 - 1) * maxDeviation;
    
    // Добавляем небольшую вариацию к радиусу
    const radiusVariation = 0.05; // 5% вариации
    const adjustedRadius = radius * (1 + (seededRandom(seed + i + 1) * 2 - 1) * radiusVariation);
    
    // Вычисляем координаты, учитывая baseOffset
    const x = round(baseOffset.x + adjustedRadius * Math.cos(angle));
    const y = round(baseOffset.y + adjustedRadius * Math.sin(angle));
    
    offsets.push({ x, y });
  }

  return offsets;
}


function addEdgePoints(points, amount) {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  // Создаем массивы с дублированными краевыми точками
  const startPoints = Array(amount).fill(firstPoint);
  const endPoints = Array(amount).fill(lastPoint);
  // Объединяем все в один массив
  const adjustedPoints = [...startPoints, ...points, ...endPoints];
  return adjustedPoints;
}


// Основная функция корректировки точек штриха
function adjustStrokePoints(points, offset) {

  // Функция для вычисления наклона между двумя точками
  function calculateSlope(p1, p2) {
    const dx = p2[0] - p1[0];
    const dy = p2[1] - p1[1];
    return Math.atan2(dy, dx);
  }

  // Функция для вычисления разницы наклонов в диапазоне от 0 до 1
  function calculateSlopeDifference(slope1, slope2) {
    let diff = Math.abs(slope1 - slope2);
    diff = diff > Math.PI ? 2 * Math.PI - diff : diff;
    return (diff / (Math.PI / 2));
  }

  // Вспомогательная функция для вычисления длины вектора
  function vectorLength(v) {
    return Math.sqrt(v[0] * v[0] + v[1] * v[1]);
  }


  if (points.length < 4) return points;

  const offsetSlope = calculateSlope([0, 0], [offset.x, offset.y]);

  const startStrokeSlope = calculateSlope(points[2], points[0]);
  const startSlopeDifference = calculateSlopeDifference(offsetSlope, startStrokeSlope);

  const endStrokeSlope = calculateSlope(points[points.length-3], points[points.length-1]);
  const endSlopeDifference = calculateSlopeDifference(offsetSlope, endStrokeSlope);
  
  const startEffectiveDistance = offset.distance * startSlopeDifference;
  const endEffectiveDistance = offset.distance * endSlopeDifference;

  let adjustedPoints = [...points];

  // Функция для обработки конца штриха (начало или конец)
  function processEnd({
    startIndex, 
    endIndex, 
    step,
    spliceStep,
    effectiveDistance,
  }) {
    let currentIndex = startIndex;
    let remainingDistance = effectiveDistance * 2;

    while (currentIndex !== endIndex && remainingDistance > 0) {
      const p1 = adjustedPoints[currentIndex];
      const p2 = adjustedPoints[currentIndex + step];

      if (!p1 || !p2) break;

      const v = [p2[0] - p1[0], p2[1] - p1[1]];
      const vLength = vectorLength(v);

      if (vLength <= remainingDistance) {
        // Если расстояние между точками меньше оставшегося расстояния для укорочения,
        // удаляем точку и продолжаем обработку
        adjustedPoints.splice(currentIndex, 1);
        currentIndex += spliceStep;
        remainingDistance -= vLength;
      } else {

        // Иначе смещаем точку
        const ratio = remainingDistance / vLength;
        adjustedPoints[currentIndex] = [
          Math.round((p1[0] + v[0] * ratio) * 10)/10,
          Math.round((p1[1] + v[1] * ratio) * 10)/10
        ];
        break;
      }
    }
  }

  // // Обрабатываем начало штриха
  // processEnd(0, adjustedPoints.length - 1, 1);
  // // Обрабатываем конец штриха
  // processEnd(adjustedPoints.length - 1, 0, -1);

  processEnd({
    startIndex: 0,
    endIndex: adjustedPoints.length - 1,
    step: 1,
    spliceStep: 0,
    effectiveDistance: startEffectiveDistance,
  });
  processEnd({
    startIndex: adjustedPoints.length - 1,
    endIndex: 0,
    step: -1,
    spliceStep: -1,
    effectiveDistance: endEffectiveDistance,
  });
  
  adjustedPoints = adjustedPoints.length > 1 ? adjustedPoints : [];
  return adjustedPoints;
}



function shortenLine(points, lineWidth) {
  if (points.length < 2) return points;

  const targetLength = lineWidth / 2;

  // Функция для вычисления длины между двумя точками с проверкой на undefined
  function distance(p1, p2) {
    if (!p1 || !p2 || !Array.isArray(p1) || !Array.isArray(p2) || p1.length < 2 || p2.length < 2) {
      console.warn("Invalid points in distance calculation:", p1, p2);
      return 0;
    }
    return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));
  }

  // Функция для обработки одного конца линии
  function processEnd(points, isStart) {
    let currentLength = 0;
    let processedPoints = [...points];
    let i = isStart ? 0 : points.length - 1;
    const step = isStart ? 1 : -1;

    while ((isStart ? i < points.length - 1 : i > 0) && currentLength < targetLength) {
      const p1 = processedPoints[i];
      const p2 = processedPoints[i + step];
      
      if (!p1 || !p2) {
        console.warn("Invalid point encountered:", p1, p2);
        break;
      }

      const segmentLength = distance(p1, p2);

      if (currentLength + segmentLength <= targetLength) {
        processedPoints.splice(isStart ? i : i + 1, 1);
        currentLength += segmentLength;
        if (!isStart) i--;
      } else {
        const remainingLength = targetLength - currentLength;
        const ratio = remainingLength / segmentLength;
        const newPoint = [
          p1[0] + (p2[0] - p1[0]) * ratio,
          p1[1] + (p2[1] - p1[1]) * ratio
        ];
        if (isStart) {
          processedPoints[i] = newPoint;
        } else {
          processedPoints[i + 1] = newPoint;
        }
        break;
      }
    }

    return processedPoints;
  }

  // Обрабатываем начало линии
  const startProcessed = processEnd(points, true);
  
  // Обрабатываем конец линии
  const endProcessed = processEnd(startProcessed, false);

  return endProcessed;
}


function drawStrokeLine({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  plusWidth = 0,
  time,
  tapering,
  jitterAmount,
}, context) {

  if (points.length === 1) {
    points = [points[0], points[0], points[0], points[0], points[0]]
  }
  // const watercolorPoints = points;
  if (jitterAmount) {
    points = addWatercolorEffectToPoints(points, lineWidth, jitterAmount, time);
  }

  const stroke = {
    points, 
    lineWidth, 
    color, 
    offset, 
    plusWidth,
  }
 
  if (points.length === 1) {
    return drawPoint(stroke, context);
  } else if (tapering && lineWidth < 200 && points.length < 200) {
    return drawTaperingStroke(stroke, context, time);
  } else {
    return drawPlainStroke(stroke, context);
  }

}


function drawTaperingStroke({
  points, lineWidth, color, offset, plusWidth,
}, context, time) {

  let numInterpolations = Math.max(5, lineWidth/5);
  numInterpolations = Math.min(numInterpolations, 20);
  numInterpolations = Math.ceil(numInterpolations);

  const interpolatedPoints = interpolatePoints(points, numInterpolations);
  const totalPoints = interpolatedPoints.length;
  const centerPartStart = Math.min(numInterpolations * numInterpolations, Math.floor(totalPoints / 2.2));
  const centerPartEnd = Math.max(totalPoints - numInterpolations * numInterpolations, Math.ceil(totalPoints / 2.2));


  context.strokeStyle = color;
  for (let i = 0; i < totalPoints - 1; i++) {
    const startPoint = interpolatedPoints[i];
    const endPoint = interpolatedPoints[i + 1];

    // const brushSizeVariation = 0;
    // const brushSizeVariation = (rng() - 0.5) * (lineWidth / 5); // От -1 до 1

    let segmentWidth = computeLineWidth(i, totalPoints, lineWidth, centerPartStart, centerPartEnd) + plusWidth;

    segmentWidth = lineWidth * 0.85 + segmentWidth * 0.2 + plusWidth;
    // segmentWidth += brushSizeVariation;

    context.beginPath();
    context.moveTo(startPoint[0] + offset.x, startPoint[1] + offset.y);
    context.lineTo(endPoint[0] + offset.x, endPoint[1] + offset.y);
    context.lineWidth = segmentWidth;
    context.stroke();
  }

}


function drawPlainStroke ({
  points, lineWidth, color, offset, plusWidth,
}, context) {

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  context.beginPath();
  context.moveTo(points[0][0] + offset.x, points[0][1] + offset.y);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + currentPoint[0]) / 2,
      (nextPoint[1] + currentPoint[1]) / 2
      // (nextPoint[0] + offset.x + currentPoint[0] + offset.x) / 2,
      // (nextPoint[1] + offset.y + currentPoint[1] + offset.y) / 2
    ];
    context.quadraticCurveTo(nextPoint[0] + offset.x, nextPoint[1] + offset.y, midPoint[0] + offset.x, midPoint[1] + offset.y);
  }
  const lastPoint = points[points.length - 1]
  context.lineTo(
    lastPoint[0] + offset.x, 
    lastPoint[1] + offset.y,
    );
  context.stroke();
  
}

function drawPoint ({
  points, lineWidth, color, offset, plusWidth,
}, context) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  context.beginPath();
  context.arc(
    point[0] + offset.x, 
    point[1] + offset.y, 
    (lineWidth / 2) + plusWidth, 
    0, 
    Math.PI * 2
    );
  context.fillStyle = color;
  context.fill();
}


function addWatercolorEffectToPoints(points, lineWidth, jitterAmount, time) {
  if ( points.length <= 2 ) { return points; }

  const watercolorPoints = [];
  jitterAmount = jitterAmount || Math.max(2, lineWidth / 10); // Максимальное отклонение в пикселях

  points.forEach((point, i) => {
    // Генерируем случайный угол
    const angle = seededRandom(time + i) * 2 * Math.PI;
    
    // Генерируем случайное расстояние от 0 до jitterAmount
    const distance = seededRandom(time + i) * jitterAmount;
    
    // Вычисляем смещение в полярных координатах
    const jitterX = distance * Math.cos(angle);
    const jitterY = distance * Math.sin(angle);
    
    watercolorPoints.push([
      point[0] + jitterX,
      point[1] + jitterY
    ]);
  });

  return watercolorPoints;
}


function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}



async function applyBlurAlongPath(
  context, maskCanvas, lineWidth, originalAlpha, usePixi
) {
  const blurSize = 15;
  // const blurSize = Math.min(10, lineWidth / 3);

  // Создаем канвас для вырезанной области
  const maskedCanvas = document.createElement('canvas');
  maskedCanvas.width = context.canvas.width;
  maskedCanvas.height = context.canvas.height;
  const maskedCtx = maskedCanvas.getContext('2d');

  // Вырезаем область по маске
  maskedCtx.drawImage(context.canvas, 0, 0);
  maskedCtx.globalCompositeOperation = 'destination-in';
  maskedCtx.drawImage(maskCanvas, 0, 0);

  // Создаем канвас для блюра
  const blurredCanvas = document.createElement('canvas');
  blurredCanvas.width = context.canvas.width;
  blurredCanvas.height = context.canvas.height;
  const blurredCtx = blurredCanvas.getContext('2d');

  // Применяем блюр к вырезанной области
  if (usePixi) {
    blurredCtx.drawImage(maskedCanvas, 0, 0);
    await applyPixiBlur(blurredCanvas, blurSize);
  } else {
    blurredCtx.filter = `blur(${blurSize}px)`;
    blurredCtx.drawImage(maskedCanvas, 0, 0);
  }

  // Обрезаем размытое изображение по маске
  blurredCtx.globalCompositeOperation = 'destination-in';
  blurredCtx.drawImage(maskCanvas, 0, 0);

  // Накладываем заблюренную область
  context.globalAlpha = 0.8;
  context.globalCompositeOperation = 'source-over';
  context.drawImage(blurredCanvas, 0, 0);
  context.globalAlpha = 1;

  // Восстанавливаем настройки контекста
  context.globalCompositeOperation = 'source-over';
}


async function applyBlurAlongPath0(
  context, maskCanvas, lineWidth, originalAlpha, usePixi
) {
  const blurSize = 15;
  // const blurSize = Math.min(10, lineWidth / 3);

  // Создаем канвас для хранения оригинального изображения
  const originalCanvas = document.createElement('canvas');
  originalCanvas.width = context.canvas.width;
  originalCanvas.height = context.canvas.height;
  const originalCtx = originalCanvas.getContext('2d');
  originalCtx.drawImage(context.canvas, 0, 0);

  // Создаем канвас для вырезанной области
  const maskedCanvas = document.createElement('canvas');
  maskedCanvas.width = context.canvas.width;
  maskedCanvas.height = context.canvas.height;
  const maskedCtx = maskedCanvas.getContext('2d');

  // Вырезаем область по маске
  maskedCtx.drawImage(context.canvas, 0, 0);
  maskedCtx.globalCompositeOperation = 'destination-in';
  maskedCtx.drawImage(maskCanvas, 0, 0);

  // Создаем канвас для блюра
  const blurredCanvas = document.createElement('canvas');
  blurredCanvas.width = context.canvas.width;
  blurredCanvas.height = context.canvas.height;
  const blurredCtx = blurredCanvas.getContext('2d');

  // Применяем блюр к вырезанной области
  if (usePixi) {
    blurredCtx.drawImage(maskedCanvas, 0, 0);
    await applyPixiBlur(blurredCanvas, blurSize);
  } else {
    blurredCtx.filter = `blur(${blurSize}px)`;
    blurredCtx.drawImage(maskedCanvas, 0, 0);
  }

  // Обрезаем размытое изображение по маске
  blurredCtx.globalCompositeOperation = 'destination-in';
  blurredCtx.drawImage(maskCanvas, 0, 0);

  // Очищаем основной контекст
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);

  // Рисуем оригинальное изображение
  context.drawImage(originalCanvas, 0, 0);

  // Накладываем заблюренную область
  context.globalAlpha = 0.8;
  context.globalCompositeOperation = 'source-over';
  context.drawImage(blurredCanvas, 0, 0);
  context.globalAlpha = 1;

  // Восстанавливаем настройки контекста
  context.globalCompositeOperation = 'source-over';
}