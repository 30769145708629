// ChatOverlay.js
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext } from '../../../contexts/GameContext';

function ChatOverlay() {
    const { t } = useTranslation();

    const { gameInfoRef, gameIsLoaded, userSetsRef } = useGameContext();
    const [recentMessages, setRecentMessages] = useState([]);
    const [isVisible, setIsVisible] = useState(true);  // Состояние для контроля видимости чата

    const truncateAuthor = (author = '') => {
      return author.length > 10 ? `${author.slice(0, 12)}...` : author;
    };

    const truncateMessage = (message = 'empty') => {
      return message.length > 100 ? `${message.slice(0, 100)}...` : message;
    };

    // useEffect(() => {
    //   setIsVisible(userSetsRef.current.showChat)
    // }, [gameIsLoaded]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            let game = gameInfoRef.current;
            const showTimeDuration = Date.now() - 30000;

            let chat = game?.chat?.slice(-5) || [];
            // chat = chat.filter(message =>
            //     message.time > showTimeDuration
            // );

            setRecentMessages(chat);
        }, 300);

        return () => clearInterval(intervalId);
    }, []);

    // Функция для переключения видимости чата
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
      <>
        {recentMessages.length > 0 && (<button onClick={toggleVisibility} style={{
                position: 'absolute',
                top: '5px',
                right: '10px',
                backgroundColor: 'var(--tg-theme-button-color)',
                color: 'var(--tg-theme-button-text-color)',
                border: 'none',
                borderRadius: '12px',
                padding: '5px 8px',
                cursor: 'pointer',
                fontSize: '14px',
                opacity: 0.7,
                zIndex: 1500,
            }}>
                {isVisible ? t('chat.hide') : t('chat.show')}
            </button>)}

          {recentMessages.length > 0 && isVisible && (
              <div style={{
                  position: 'absolute',
                  top: '33px',
                  right: '0px',
                  padding: '10px',
                  color: 'rgba(0, 0, 0, 1)',
                  maxWidth: '220px',
                  zIndex: 1005,
                  fontSize: '12px',
                  overflowY: 'auto',
                  maxHeight: '300px',
                  border: 'none',
                  pointerEvents: 'none',
              }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {recentMessages.map((message, index) => (
                        <div key={index} style={{
                            backgroundColor: 'rgba(215, 215, 215, 0.4)',
                            margin: '2px 0',
                            padding: '2px 6px 3px',
                            borderRadius: '10px',
                            fontSize: '1.1em',
                            opacity: 0.85,
                            display: 'inline-block', // Элемент адаптируется по размеру контента
                        }}>
                            <strong>{truncateAuthor(message.author)}</strong>: {truncateMessage(message.text)}
                        </div>
                    ))}
                  </div>
              </div>
          )}
      </>
  );
}

export default ChatOverlay;
