import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useSpecatorMouse = ({

  startRightClickMove,

  changeViewStart,
  changeViewMove,
  simpleZoom,

  // Touch
  touchZoomStart,
  touchZoomMove,
  resetZoomStart,
  resetZoomEnd,

}) => {

  const canvasContext = useDrawingContext ();
  const brushContext = useBrushContext();

  const {
    mousePositionRef,
    mouseIsPressedRef,

    setIsZooming,
  } = canvasContext;

  const {
  
    controlIsDownRef,
    altIsDownRef,
    spaceIsDownRef,
    tabIsDownRef,
  
  } = brushContext;


  const spectatorMouseDown = (nativeEvent) => {

    if (nativeEvent.button === 1) {
      nativeEvent.preventDefault();
      return;
    }

    mouseIsPressedRef.current = true;

    if (altIsDownRef.current && nativeEvent.button === 2) {
      startRightClickMove({nativeEvent});
    } else if (
      !tabIsDownRef.current &&
      nativeEvent.button === 2 && 
      (controlIsDownRef.current || spaceIsDownRef.current)
    ) {
      resetZoomStart();
    } else if (
      !tabIsDownRef.current &&
      (controlIsDownRef.current || spaceIsDownRef.current || nativeEvent.button === 2)
      ) {
      changeViewStart({ nativeEvent })
    } 
  };


  const spectatorMouseMove = (nativeEvent) => {

    mousePositionRef.current = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    if (!nativeEvent.buttons) {return;}

    if (
      !tabIsDownRef.current &&
      nativeEvent.buttons === 2 && 
      (controlIsDownRef.current || spaceIsDownRef.current)
    ) {

    }  else if (
      !tabIsDownRef.current &&
      (controlIsDownRef.current || spaceIsDownRef.current || nativeEvent.buttons === 2)
    ) {
      changeViewMove({ nativeEvent });
    } 

  };


  const spectatorMouseUp = (nativeEvent) => {

    mouseIsPressedRef.current = false;
    nativeEvent.preventDefault();

    if (
      !tabIsDownRef.current &&
      nativeEvent.button === 2 && 
      (controlIsDownRef.current || spaceIsDownRef.current)
    ) {
      resetZoomEnd('force')
    } else if (controlIsDownRef.current && nativeEvent.button === 0) {
      simpleZoom({ nativeEvent })
    } 

  };

  const spectatorTouchStart = (nativeEvent) => {

    if (nativeEvent.touches.length > 3) {
      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      resetZoomStart();
    } else if (nativeEvent.touches.length === 2) {
      touchZoomStart(nativeEvent)
    } 

  };

  const spectatorTouchMove = (nativeEvent) => {

    if (nativeEvent.touches.length === 2) {
      touchZoomMove(nativeEvent);
    } 

  };

  const spectatorTouchEnd = (nativeEvent) => {

    resetZoomEnd();
    setIsZooming(false);

  };

  const spectatorContextMenu = (nativeEvent) => {

    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();

  };

  return {
    spectatorMouseDown,
    spectatorMouseUp,
    spectatorMouseMove,
    spectatorTouchStart,
    spectatorTouchMove,
    spectatorTouchEnd,
    spectatorContextMenu,
  };
};

